import { Dispatch } from "redux";

import { Form } from "../../components/operator/widgets/FormsWidget/FormsWidget.types";
import { getCustomerAnswers } from "../../services/forms/forms";

export const SET_FORMS = "SET_FORMS";
export const SET_FORM = "SET_FORM";
export const FETCH_INITIAL_VALUES = "FETCH_INITIAL_VALUES";

export const setForms: any = (forms: any) => (dispatch: Dispatch<any>) => {
  return { type: SET_FORMS, payload: forms };
};

export const toggleForm = (formId: string, open?: boolean) => (
  dispatch: Dispatch<any>,
  getState: Function
) => {
  const form = getState().Forms.forms.find((form: Form) => {
    return form.id === formId;
  });
  let newState = !form.opened;
  if (typeof open === "boolean") {
    newState = open;
  }
  dispatch({
    type: SET_FORM,
    payload: { ...form, opened: newState }
  });
};

export const fetchInitialValues = (value: string) => async (dispatch: any) => {
  try {
    const response = await getCustomerAnswers(value);
    dispatch({ type: FETCH_INITIAL_VALUES, payload: response?.data?.data[0] });
  } catch (e) {
    console.log(e.message);
  }
};
