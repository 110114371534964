import { Dispatch } from "redux";

export const TOGGLE_CALL_ERROR = "TOGGLE_CALL_ERROR";
export const TOGGLE_TASK_ROUTER_ERROR = "TOGGLE_TASK_ROUTER_ERROR";
export const TOGGLE_CHAT_ERROR = "TOGGLE_CHAT_ERROR";
export const TOGGLE_NO_MIC_ERROR = "TOGGLE_NO_MIC_ERROR";
export const TOGGLE_NO_MIC_WARNING = "TOGGLE_NO_MIC_WARNING";
export const TOGGLE_NO_MIC_PERMISSION_ERROR = "TOGGLE_NO_MIC_PERMISSION_ERROR";
export const TOGGLE_CALL_WARNING = "TOGGLE_CALL_WARNING";

export const toggleCallError: any = (force?: boolean) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({ type: TOGGLE_CALL_ERROR, payload: force });
};

export const toggleTaskRouterError: any = (force?: boolean) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({ type: TOGGLE_TASK_ROUTER_ERROR, payload: force });
};

export const toggleChatError: any = (force?: boolean) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({ type: TOGGLE_CHAT_ERROR, payload: force });
};

export const toggleNoMicError: any = (force?: boolean) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({ type: TOGGLE_NO_MIC_ERROR, payload: force });
};

export const toggleNoMicWarning: any = (force?: boolean) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({ type: TOGGLE_NO_MIC_WARNING, payload: force });
};

export const toggleNoMicPermissionError: any = (force?: boolean) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({ type: TOGGLE_NO_MIC_PERMISSION_ERROR, payload: force });
};

export const toggleCallWarning: any = (force?: boolean) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({ type: TOGGLE_CALL_WARNING, payload: force });
};
