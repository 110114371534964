import CONSTANTS from "../../config/constants";
import { store } from "../store";
import { Reservation } from "types/reservation.types";

export const sortReservations = (reservations: any[]) => {
  const accepted = CONSTANTS.RESERVATION_STATUS.ACCEPTED;
  const acceptedReservations = reservations.filter(
    (reservation: Reservation) => {
      return reservation.reservationStatus === accepted;
    }
  );
  const reservedReservations = reservations.filter(
    (reservation: Reservation) => {
      return reservation.reservationStatus !== accepted;
    }
  );
  return [...reservedReservations, ...acceptedReservations];
};

// Get active reservation
export const getReservationById = (reservationSid: string | null) => {
  if (!reservationSid) {
    return null;
  }
  const { Track } = store.getState();
  const { reservations } = Track;
  return reservations.find((reservation: Reservation) => {
    return reservation.sid === reservationSid;
  });
};

export const getActiveReservation = () => {
  const { Track } = store.getState();
  const { activeReservationSid } = Track;
  return getReservationById(activeReservationSid);
};
