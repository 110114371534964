// Comment Actions
import { Comment } from "components/dashboard/widgets/comments/CommentsWidget/CommentsWidget.types";

// Set current comment
export const SET_COMMENT = "SET_COMMENT";
// Toogle Full Comment Drawer opened
export const TOGGLE_DRAWER_COMMENT = "TOGGLE_DRAWER_COMMENT";

export const ADD_LOCAL_COMMENT = "ADD_LOCAL_COMMENT";

export const setComment = (payload: Comment) => (dispatch: Function) => {
  dispatch({ type: SET_COMMENT, payload });
};

export const addLocalComment = (payload: Comment) => (dispatch: Function) => {
  dispatch({ type: ADD_LOCAL_COMMENT, payload });
};

export const toggleDrawerFullComment = (payload?: boolean) => (
  dispatch: any
) => {
  dispatch({ type: TOGGLE_DRAWER_COMMENT, payload });
};
