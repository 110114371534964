// Handle all xmart http api errors
import { message } from "antd";

import { Warning } from "./errors.types";
import { Alert } from "types/sales/shoppingCart.types";

export const firebaseError = (warnings: Warning[]) => {
  warnings.forEach(warning => {
    const { field, value } = warning;
    message.warning(`${field}: ${value}`, 5);
  });
};

export const shoppingCartErrors = (alerts: Alert[]) => {
  alerts.forEach(alert => {
    message.error(`${alert.message}`, 5);
  });
};

export const notAvailable = () => {
  message.warn("No disponible");
};
