import CONSTANTS from "config/constants";
import { CitiesByCountryRes, CountriesRes } from "./location.service.types";
import { AreasByCityRes } from "./location.service.types";
import { LivingPlacesRes } from "./location.service.types";
import { Country, Areas } from "types/location.types";
import { LivingPlace, City } from "types/location.types";
import { Objectify } from "types/common.types";
import { message } from "antd";
import { getAuthorization } from "redux/auth/auth.actions";
import { axiosXmart } from "util/axios";

const { SALES_ISSUES } = CONSTANTS;
const { LIVING_PLACES } = SALES_ISSUES;
const { PLATFORM, CLIENT_VERSION, CONTENT_TYPE } = SALES_ISSUES;
const { SECTOR, CITIES, COUNTRIES } = SALES_ISSUES;

const headers = {
  Platform: PLATFORM,
  "client-version": CLIENT_VERSION,
  "Content-Type": CONTENT_TYPE
};

export const fetchCountries = async (): Promise<
  Objectify<Country> | undefined
> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${COUNTRIES}`;
    const result: CountriesRes = await axiosXmart.get(url, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    const { data } = result.data;
    const countries = data.reduce((acc, next) => {
      return { ...acc, [next.name]: next };
    }, {});
    return countries;
  } catch (e) {
    message.error("Error al consultar los países");
    return;
  }
};

export const fetchCitiesByCountry = async (
  countryId: number
): Promise<City[] | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${CITIES}${countryId}/cities`;
    const result: CitiesByCountryRes = await axiosXmart.get(url, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    message.error("Error al consultar las ciudades del país seleccionado");
    return;
  }
};

export const fetchAreasByCity = async (
  cityId: number
): Promise<Areas[] | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${SECTOR}${cityId}/areas`;
    const result: AreasByCityRes = await axiosXmart.get(url, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    if (e.response?.data?.error[0]?.value) {
      message.error(`${e.response?.data?.error[0]?.value}`);
      return;
    } else {
      message.error("Error al consultar áreas de la ciudad seleccionada");
      return;
    }
  }
};

export const fetchLivingPlaces = async (
  countryId: number
): Promise<LivingPlace[] | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${LIVING_PLACES}?countryId=${countryId}`;
    const result: LivingPlacesRes = await axiosXmart.get(url, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    const { data } = result;
    return data;
  } catch (e) {
    message.error("Error al consultar tipo de vivienda del país seleccionado");
    return;
  }
};
