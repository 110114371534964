import CONSTANTS from "../config/constants";
import { store } from "../redux/store";
import { Reservation } from "types/reservation.types";
import { QueuedTaskTypes } from "types/taskQueue.types";

export const isReservationSelected = (reservation: Reservation) => {
  const activeReservation = store.getState().Track.activeReservation();
  if (!reservation || !activeReservation) {
    return false;
  }
  return reservation.sid === activeReservation.sid;
};

export const isReservationSidSelected = (reservationSid: string) => {
  const activeReservation = store.getState().Track.activeReservation();
  if (!activeReservation) {
    return false;
  }
  return reservationSid === activeReservation.sid;
};

export const isReservationAccepted = (reservation: Reservation) => {
  if (!reservation) {
    return false;
  }
  return (
    reservation.reservationStatus === CONSTANTS.RESERVATION_STATUS.ACCEPTED
  );
};

export const isReservationWrapping = (reservation: Reservation) => {
  if (!reservation) {
    return false;
  }
  return (
    reservation.task.assignmentStatus === CONSTANTS.RESERVATION_STATUS.WRAPPING
  );
};

export const isReservationPending = (reservation: Reservation) => {
  if (!reservation) {
    return false;
  }
  return reservation.reservationStatus === CONSTANTS.RESERVATION_STATUS.CREATED;
};

export const isReservationInCRM = (reservation: Reservation) => {
  if (!reservation) {
    return false;
  }
  return !!reservation.contact;
};

// Whether it is a custom task e.g. rescheduled, lead
export const isReservationTask = (reservation: Reservation) => {
  if (!reservation || !reservation.task) {
    return false;
  }
  return !!reservation.task?.taskSource;
};

// Whether it is a custom task e.g. rescheduled, lead
export const isReservationScheduledTask = (
  reservation: Reservation | undefined
) => {
  if (!reservation || !reservation.task) {
    return false;
  }
  return reservation.task?.taskSource === QueuedTaskTypes.RESCHEDULED;
};

export const isCallReservation = (reservation: Reservation) => {
  if (!reservation) {
    return false;
  }
  const sip = CONSTANTS.MEDIA_CHANNELS.SIP;
  return reservation?.channel?.mediaChannel === sip;
};

export const inCall = () => {
  const call = store.getState().Track.call;
  return (
    call &&
    Object.keys(call).some(
      (field: string) => call[field] && typeof call[field] === "boolean"
    )
  );
};

export const isActiveReservation = (reservationSid: string) => {
  const activeReservationSid = store.getState().Track.activeReservationSid;
  return activeReservationSid === reservationSid;
};
