// Contacts widget redux helpers
import { widgetInitialData } from "../widgets.helpers";
import { ContactsDrawers } from "./widgets.contacts.types";

const drawers: ContactsDrawers = {
  info: {
    visible: false
  },
  search: {
    visible: false
  },
  new: {
    visible: false
  },
  edit: {
    visible: false
  }
};

export const contactsInitialData = { ...widgetInitialData, drawers };
