import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App/App";
import { ThemeProvider } from "styled-components";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import * as serviceWorker from "./serviceWorker";
// import "antd/dist/antd.css";
import "font-awesome/css/font-awesome.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// pure-react-carousel
import "pure-react-carousel/dist/react-carousel.es.css";

import GlobalStyles from "./styles/base";
import themes from "./styles/theme";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <GlobalStyles />
    <ThemeProvider theme={themes.defaultTheme}>
      <App />
    </ThemeProvider>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
