import {
  CustomerState as State,
  SetExternalCustomersAction
} from "./customer.types";
import { SetBillingInformationByCustomerAction } from "./customer.types";
import { SetAddressByCustomer, SetNewAddressAction } from "./customer.types";
import { SetUpdateCustomerAction } from "./customer.types";
import { SetUpdateAddressAction } from "./customer.types";
import { SetNewCustomerAction } from "./customer.types";
import { SetNewBillingInformationAction } from "./customer.types";
import { SetUpdateBillingInformationAction } from "./customer.types";
import { CustomerActions as Actions } from "./customer.types";
import { SetCustomersAction } from "./customer.types";
import {
  SET_LOCATION,
  SET_MAP_VISIBLE,
  SET_EXTERNAL_CUSTOMERS,
  RESET_EXTERNAL_CUSTOMER,
  SET_EXTERNAL_CUSTOMER,
  SET_EXTERNAL_ADDRESSES,
  SET_EXTERNAL_BILLINGINFOS,
  SET_EXTERNAL_BILLINGINFO,
  SET_EXTERNAL_ADDRESS
} from "./customer.actions";
import { SET_ADDRESS } from "./customer.actions";
import { SET_NEW_CUSTOMER, SET_UPDATE_CUSTOMER } from "./customer.actions";
import { SET_UPDATE_ADDRESS, SET_NEW_ADDRESS } from "./customer.actions";
import { SET_UPDATE_BILLING_INFORMATION } from "./customer.actions";
import { SET_NEW_BILLING_INFORMATION } from "./customer.actions";
import { SET_BILLING_INFORMATION } from "./customer.actions";
import { RESET_CUSTOMER, SET_CUSTOMERS } from "./customer.actions";
import { SET_SELECTED_CUSTOMER_ID } from "./customer.actions";
import { SET_SELECTED_ADDRESS_ID } from "./customer.actions";
import { SET_SELECTED_BILLING_INFORMATION_ID } from "./customer.actions";
import { setPage } from "util/redux.utils";
import { findCustomer } from "./customer.reducer.helpers";
import { findAddressById, findBillingById } from "./customer.reducer.helpers";
import { MapData } from "redux/chat/chat.types";
import { getInitialMapData } from "util/customer";
import { getInitialPaginationData, getPageOfItem } from "util/pagination";
import { Customer } from "types/customer.types";
import { ExternalCustomer } from "../../types/customer.types";

const initialState: State = {
  // Selected customer
  customerId: undefined,
  customers: getInitialPaginationData(),
  customer: findCustomer,
  addressByCustomer: getInitialPaginationData(),
  selectedAddress: findAddressById,
  selectedAddressId: undefined,
  billingInformationByCustomer: getInitialPaginationData(),
  selectedBillingInformation: findBillingById,
  selectedBillingInformationId: undefined,
  externalCustomers: getInitialPaginationData(),
  externalCustomer: undefined,
  externalAddressByCustomer: [],
  selectedExternalAddress: undefined,
  externalBillingInformationByCustomer: [],
  selectedExternalBillingInformation: undefined,
  map: getInitialMapData()
};

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_CUSTOMERS:
      return setCustomers(state, action.payload);
    case SET_SELECTED_CUSTOMER_ID:
      return { ...state, customerId: action.payload };
    case SET_EXTERNAL_CUSTOMERS:
      return setExternalCustomers(state, action.payload);
    case SET_EXTERNAL_CUSTOMER:
      return { ...state, externalCustomer: action.payload };
    case SET_EXTERNAL_ADDRESSES:
      return { ...state, externalAddressByCustomer: action.payload };
    case SET_EXTERNAL_BILLINGINFOS:
      return { ...state, externalBillingInformationByCustomer: action.payload };
    case SET_EXTERNAL_ADDRESS:
      return { ...state, selectedExternalAddress: action.payload };
    case SET_EXTERNAL_BILLINGINFO:
      return { ...state, selectedExternalBillingInformation: action.payload };
    case SET_NEW_CUSTOMER:
      return setNewCustomer(state, action.payload);
    case SET_UPDATE_CUSTOMER:
      return setUpdateCustomer(state, action.payload);
    case SET_SELECTED_ADDRESS_ID:
      return { ...state, selectedAddressId: action.payload };
    case SET_ADDRESS:
      return setAddress(state, action.payload);
    case SET_NEW_ADDRESS:
      return setNewAddress(state, action.payload);
    case SET_UPDATE_ADDRESS:
      return setUpdateAddress(state, action.payload);
    case SET_BILLING_INFORMATION:
      return setBillingInformation(state, action.payload);
    case SET_NEW_BILLING_INFORMATION:
      return setNewBillingInformation(state, action.payload);
    case SET_UPDATE_BILLING_INFORMATION:
      return setUpdateBillingInformation(state, action.payload);
    case SET_SELECTED_BILLING_INFORMATION_ID:
      return { ...state, selectedBillingInformationId: action.payload };
    case SET_LOCATION:
      return setMap(state, action.payload);
    case SET_MAP_VISIBLE:
      return toggleMap(state, action.payload);
    case RESET_CUSTOMER:
      return resetCustomer(state);
    case RESET_EXTERNAL_CUSTOMER:
      return resetExternalCustomer(state);
    default:
      return state;
  }
};

const resetCustomer = (state: State): State => {
  return {
    ...state,
    customerId: undefined,
    addressByCustomer: getInitialPaginationData(),
    selectedAddressId: undefined,
    billingInformationByCustomer: getInitialPaginationData(),
    selectedBillingInformationId: undefined,
    map: getInitialMapData()
  };
};

const resetExternalCustomer = (state: State): State => {
  return {
    ...state,
    externalCustomer: undefined,
    externalAddressByCustomer: [],
    selectedExternalAddress: undefined,
    externalBillingInformationByCustomer: [],
    selectedExternalBillingInformation: undefined
  };
};

const setNewCustomer = (
  state: State,
  payload: SetNewCustomerAction["payload"]
): State => {
  let customersPage = state.customers.items["0"];
  if (!customersPage) {
    customersPage = [];
  }
  const newCustomerPage = [payload, ...customersPage];
  const newItems = { ...state.customers.items, "0": newCustomerPage };
  const newCustomers = { ...state.customers, items: newItems };
  return { ...state, customers: newCustomers };
};

const setUpdateCustomer = (
  state: State,
  payload: SetUpdateCustomerAction["payload"]
): State => {
  const { uid } = payload;
  const page = getPageOfItem(state.customers.items, uid, "uid");
  if (!page) {
    throw new Error("When updating customer, customer page must be defined");
  }
  const customersPage = state.customers.items[page];
  if (!customersPage) {
    throw new Error("When updating customer, customer page must be defined");
  }
  const newCustomerPage = customersPage.map(customer => {
    if (customer.uid === uid) {
      return { ...customer, ...payload };
    }
    return customer;
  });
  const newItems = { ...state.customers.items, [page]: newCustomerPage };
  const newCustomers = { ...state.customers, items: newItems };
  return { ...state, customers: newCustomers };
};

const setNewAddress = (
  state: State,
  payload: SetNewAddressAction["payload"]
): State => {
  let addressesPage = state.addressByCustomer.items["0"];
  if (!addressesPage) {
    addressesPage = [];
  }
  const newAddressesPage = [payload, ...addressesPage];
  const newItems = { ...state.addressByCustomer.items, "0": newAddressesPage };
  const newAddresses = { ...state.addressByCustomer, items: newItems };
  return { ...state, addressByCustomer: newAddresses };
};

const setUpdateAddress = (
  state: State,
  payload: SetUpdateAddressAction["payload"]
): State => {
  const { id } = payload;
  const page = getPageOfItem(state.addressByCustomer.items, id, "id");
  if (!page) {
    throw new Error("When updating address, customer must be defined");
  }
  const addressesPage = state.addressByCustomer.items[page];
  if (!addressesPage) {
    throw new Error("When updating address, customer page must be defined");
  }
  const newAddressesPage = addressesPage.map(address => {
    if (address.id === id) {
      return { ...address, ...payload };
    }
    return address;
  });
  const newItems = {
    ...state.addressByCustomer.items,
    [page]: newAddressesPage
  };
  const newAddresses = { ...state.addressByCustomer, items: newItems };
  return { ...state, addressByCustomer: newAddresses };
};

const setNewBillingInformation = (
  state: State,
  payload: SetNewBillingInformationAction["payload"]
): State => {
  let billingsPage = state.billingInformationByCustomer.items["0"];
  if (!billingsPage) {
    billingsPage = [];
  }
  const newBillingsPage = [payload, ...billingsPage];
  const newItems = {
    ...state.billingInformationByCustomer.items,
    "0": newBillingsPage
  };
  const newBillings = {
    ...state.billingInformationByCustomer,
    items: newItems
  };
  return { ...state, billingInformationByCustomer: newBillings };
};

const setUpdateBillingInformation = (
  state: State,
  payload: SetUpdateBillingInformationAction["payload"]
): State => {
  const { id } = payload;
  const page = getPageOfItem(
    state.billingInformationByCustomer.items,
    id,
    "id"
  );
  if (!page) {
    throw new Error("When updating billings, customer must be defined");
  }
  const billingsPage = state.billingInformationByCustomer.items[page];
  if (!billingsPage) {
    throw new Error("When updating billings, customer page must be defined");
  }
  const newBillingsPage = billingsPage.map(billing => {
    if (billing.id === id) {
      return { ...billing, ...payload };
    }
    return billing;
  });
  const newItems = {
    ...state.billingInformationByCustomer.items,
    [page]: newBillingsPage
  };
  const newBillings = {
    ...state.billingInformationByCustomer,
    items: newItems
  };
  return { ...state, billingInformationByCustomer: newBillings };
};

const setCustomers = (
  state: State,
  payload: SetCustomersAction["payload"]
): State => {
  const oldCustomers = { ...state.customers };
  const { totalPages, items } = payload;
  let newCustomers = {
    ...oldCustomers,
    items: { ["0" as string]: [] as Customer[] | undefined }
  };
  // for (let i = 0; i < 1; i++) {
  const newPage = {
    totalPages,
    page: 0,
    items
  };
  newCustomers = setPage<Customer>(newCustomers, newPage);
  // }
  return { ...state, customers: newCustomers };
};

const setExternalCustomers = (
  state: State,
  payload: SetExternalCustomersAction["payload"]
): State => {
  const oldCustomers = { ...state.externalCustomers };
  const { totalPages, items } = payload;
  let newCustomers = {
    ...oldCustomers,
    items: { ["0" as string]: [] as ExternalCustomer[] | undefined }
  };
  // for (let i = 0; i < totalPages; i++) {
  const newPage = {
    totalPages,
    page: 0,
    items
  };
  newCustomers = setPage<ExternalCustomer>(newCustomers, newPage);
  // }
  return { ...state, externalCustomers: newCustomers };
};

const setBillingInformation = (
  state: State,
  payload: SetBillingInformationByCustomerAction["payload"]
): State => {
  const oldBillings = { ...state.billingInformationByCustomer };
  const newBillings = setPage(oldBillings, payload);
  return { ...state, billingInformationByCustomer: newBillings };
};

const setAddress = (
  state: State,
  payload: SetAddressByCustomer["payload"]
): State => {
  const oldAddress = { ...state.addressByCustomer };
  const newAddress = setPage(oldAddress, payload);
  return { ...state, addressByCustomer: newAddress };
};

/*const setSelectedAddress = (
  state: State,
  payload: SetSelectedAdressAction["payload"]
): State => {
  const { customerId, addressId } = payload;
  const selectedCustomer = findCustomer(state, customerId);
  if (!selectedCustomer) {
    throw new Error(
      `Customer of customerId ${customerId} was not found in setSelectedAddress action`
    );
  }

  const { page, customers } = findCustomerPage(state, customerId) ?? {};
  if (typeof page === "undefined") {
    throw new Error(
      `Customer was found, but some how page ${page} was not found in setSelectedAddress action`
    );
  }
 selectedCustomer.selectedAddressId = addressId;
  const items = { ...state.customers.items };
  const updatedCustomers = customers?.map(customer => {
    if (customer.uid === customerId) {
      return { ...selectedCustomer };
    }
    return customer;
  });
  items[page] = updatedCustomers!;
  const newCustomers = { ...state.customers, items };
  return { ...state, customers: newCustomers };
};*/

/*const setSelectedBilling = (
  state: State,
  payload: SetSelectedBillingInformationAction["payload"]
): State => {
  const { customerId } = payload;
  const selectedCustomer = findCustomer(state, customerId);
  if (!selectedCustomer) {
    throw new Error(
      `Customer of customerId ${customerId} was not found in setSelectedBillingInformation action`
    );
  }
  const { page, customers } = findCustomerPage(state, customerId) ?? {};
  if (typeof page === "undefined") {
    throw new Error(
      `Customer was found, but some how page ${page} was not found in setSelectedBillingInformation action`
    );
  }
  //selectedCustomer.selectedBillingId = billingId;
  const items = { ...state.customers.items };
  const updatedCustomers = customers?.map(customer => {
    if (customer.uid === customerId) {
      return { ...selectedCustomer };
    }
    return customer;
  });
  items[page] = updatedCustomers!;
  const newCustomers = { ...state.customers, items };
  return { ...state, customers: newCustomers };
};*/

const toggleMap = (state: State, opened: boolean | undefined) => {
  const lastState = state.map.opened;
  return updateMapProp(state, "opened", opened ?? !lastState);
};

const setMap = (state: State, options: Partial<MapData>) => {
  const entries = Object.entries(options);
  let newState = state;
  for (const [k, v] of entries) {
    newState = updateMapProp(newState, k, v);
  }
  return newState;
};

const updateMapProp = (state: State, field: string, value: any) => {
  const stateClone = { ...state };
  const map = { ...stateClone.map };
  map[field] = value;
  stateClone.map = map;
  return stateClone;
};

export default reducer;
