import { Dispatch } from "redux";

import { MapData } from "redux/chat/chat.types";

export const TOGGLE_MAP = "TOGGLE_MAP";
export const SET_MAP = "SET_MAP";

export const toggleMap: any = (opened?: boolean) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({ type: TOGGLE_MAP, payload: opened });
};

export const setLocation = (map: Partial<MapData>) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({ type: SET_MAP, payload: map });
};
