// Store types and interfaces
import { Vendor } from "./vendor.types";

export interface Store {
  storeId: number;
  storeName: string;
  address: string;
  vendor: Vendor;
  additionalInfo: AdditionalStoreInformation;
  avatarUrl: string;
  coverage: boolean;
  coverUrl: string;
  delivery: Delivery;
  description: string | null;
  isOpen: boolean;
  maxOrderAmount: number;
  minOrderAmount: number;
  orderSymbol: string;
  phone: string;
  sponsored: boolean;
  latitude: number;
  longitude: number;
  schedules: Schedule[];
  timezone: string;
  cookTime: number;
  services: Service[];
  totalItems: number;
  totalPages: number;
  page: number;
  size: number;
  channelId: string;
}

export interface AdditionalStoreInformation {
  externalId: string;
  externalCode: string;
  idDeliveryKfc: string;
  isDrinksStore: boolean;
  showDrinksStore: boolean;
}

export interface Delivery {
  deliveryTimeUnit: DeliveryTimeUnit;
  deliveryTimeValue: number;
  express: boolean;
  freeDelivery: boolean;
}

export interface Schedule {
  day: Days;
  from: number;
  to: number;
}

export interface Service {
  name: string;
  status: ServiceStatus;
  url: string;
}

export enum DeliveryTimeUnit {
  MIN = "min"
}

export enum Days {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  SPECIAL = "SPECIAL"
}

export enum ServiceStatus {
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE"
}
