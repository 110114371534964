// Channel services
import { Channel } from "types/sales/channel.types";
import { buildChannel } from "util/testUtils/builders/sales/channel.builder";
import { Objectify } from "types/common.types";
import { getAuthorization } from "redux/auth/auth.actions";
import CONSTANTS from "config/constants";
import { axiosXmart } from "util/axios";
import { message } from "antd";

const { CHANNELS, PLATFORM, CLIENT_VERSION } = CONSTANTS.SALES_ISSUES;

const headers = {
  Platform: PLATFORM,
  "client-version": CLIENT_VERSION
};

export const fetchChannelsMocked = (
  countryId: number
): Promise<Objectify<Channel>> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const channels = [
        buildChannel({ id: 100, friendlyName: "Pick up" }),
        buildChannel({ id: 4, friendlyName: "A domicilio" }),
        buildChannel({ id: 200, friendlyName: "Web" })
      ];
      const channelsObject = channels.reduce((acc, next) => {
        return { ...acc, [next.name]: next };
      }, {});
      resolve(channelsObject);
    }, 1000);
  });
};

export const fetchChannelByVendor = async (
  vendorId: number
): Promise<Channel[] | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${CHANNELS}`;
    const result = await axiosXmart.get(url, {
      params: {
        vendorId
      },
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    message.error("No se pudo obtener la lista de canales");
  }
};
