// Order interfaces
import { ViewTypes } from "./issues.types";
import { Pagination } from "./common.types";
import { Address } from "./address.types";
import { DocumentType, Phone, NewCustomer } from "./customer.types";
import { Vendor } from "./sales/vendor.types";
import { BillingInformationReq } from "./billing.types";

export interface Order {
  id: number;
  seqVal: string;
  shippingAddress: AddressOrder;
  shoppingCart: OrderShoppingCart;
  orderDate: string;
  orderTime: string;
}

export interface OrderInProgress {
  id: number;
  seqVal: string;
  uid: string;
  status_id: number;
  status_name: string;
  status_code: string;
  status_category: string;
  issue_id: string | undefined;
  payment_status: string;
  createdAt: string;
}

export interface DetailedOrder extends Order {
  user: NewCustomer; //OrderUser before
  seq_id: string;
  hash: string;
  transactionId?: number | null;
  issueId?: number | null;
  pickupDate?: string | null;
  pickupTime?: string | null;
  uid: string;
  stepId: number;
  stepName: string;
  stepCode: string;
  stepCategory: string;
  step: Step;
  billingData: BillingInformationReq; //BillingData
  vendorId: number;
  channelId: number;
  shippingCost: ShippingCost;
  shoppingCart: DetailedShoppingCart;
  comment: string;
  additionalInfo: any;
}

export interface OrderUser {
  uid: string;
  document: string;
  documentType: DocumentType;
  name: string;
  lastname: string;
  phone: Phone;
  email: string;
}

export interface BillingData {
  address: string;
  country: Country;
  default: boolean;
  document: string;
  documentType: DocumentType;
  email: string;
  idInt: number;
  name: string;
  nickname: string;
  phone: string;
  uid: string;
  id: string;
}

export interface ShippingCost {
  product_id: number;
  name: string;
  amount: number;
  symbol: string;
  gross_price: number;
  net_price: number;
  subtotal_before_taxes: number;
  taxes: Tax[];
  tax_total: number;
  discounts: Discount[];
  discount_total: number;
  total: number;
}

export interface Tax {
  code: number;
  name: string;
  vat_rate_code?: string | null;
  vat_rate?: number | null;
  percentage: number;
  tax_base: number;
  value: number;
}

export interface Discount {
  discount_base: number;
  price_category: string;
  benefit_id: number;
  percentage: number;
  value: number;
  benefit_wallet_id: number;
}

export interface Country {
  id: number;
  name: string;
}

export interface AddressOrder extends Address {
  mainStreet: string;
  secondaryStreet: string;
  number: string;
}

export interface OrderShoppingCart {
  billTotal: Price;
  paidPaymentMethods: PaymentMethodsObject[];
  rejectedPaymentMethods: PaymentMethodsObject[];
}

export interface DetailedShoppingCart extends OrderShoppingCart {
  shoppingCartId: string;
  statusId: number;
  statusName: string;
  statusCode: string;
  issueId: number;
  paymentStatus: string;
  stores: ShoppingCartStore[];
  allRetries?: any[];
}

export interface ShoppingCartStore {
  id: string;
  issueId: number;
  alerts: string;
  orderId: string;
  storeId: number;
  storeName: string;
  digitalCommandSent: string;
  additionalInfo: any;
  statusId: string;
  statusName: string;
  statusCode: string;
  vendor: Vendor;
  billStoreTotal: BillingStoreTotal;
  products: ShoppingCartProduct[];
}

export interface ShoppingCartAnswer {
  billAnswer: BillProduct;
  productId: number;
  productName: string;
  questionId: number;
}

export interface ShoppingCartQuestion {
  question_id: number;
  answers: ShoppingCartAnswer[];
}

export interface ShoppingCartProduct {
  productId: string;
  productName: string;
  comment: string;
  additionalInfo: any;
  billProduct: BillProduct;
  billProductAndAnswers: BillingStoreTotal;
  questionsAndAnswers: ShoppingCartQuestion[];
}

export interface BillProduct {
  amount: number;
  subtotalBeforeTaxes: number;
  discountTotal: number;
  taxTotal: number;
  total: number;
}

export interface BillProductAndAnswers {
  subtotalBeforeTaxes: number;
  discountTotal: number;
  taxTotal: number;
  total: number;
}

export interface ShoppingCartProductQuestion {
  id: number;
  product_id?: number | null;
  name: string;
  parent_id: number;
  suborder_id?: number | null;
  order_id?: number | null;
  question_id: number;
  amount?: number | null;
  additional_info: any;
  answers: Vendor;
}

export interface ShoppingCartProductAnswer {
  productId: number;
  productName: string;
  question_id: number;
  billAnswer: BillProduct;
}

export interface BillingStoreTotal {
  subtotalBeforeTaxes: number;
  discountTotal: number;
  taxTotal: number;
  total: number;
}

export interface Price {
  discountTotal: number;
  subtotalBeforeTaxes: number;
  taxTotal: number;
  total: number;
  subtotalTaxedWithoutTaxes: number;
  subtotalZero: number;
}

export interface PaymentMethodsObject {
  payment_method_id: number;
  payment_method_name: AllowedPaymentMethods;
  step?: Step;
}

export interface BasePaymentMethod {
  id: number;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
}

export interface PaymentMethod extends BasePaymentMethod {
  name: AllowedPaymentMethods;
  image_url: string;
  default: number;
  external_id: string | null;
  external_code: number;
  parent_id: string | null;
  methods: CreditCardBrands[];
}

export interface CreditCardBrands extends BasePaymentMethod {
  name: string;
  image_url: string;
  cc_brands_mapping: CreditCardMappings[];
}

export interface CreditCardMappings
  extends Omit<BasePaymentMethod, "deleted_at"> {
  id: number;
  external_id: string;
  external_code: number;
  credit_card_brand_id: number;
  vendor_by_channel_id: number;
}

export enum PaymentMethods {
  CASH = "CASH",
  CREDIT_CARD = "CC"
}

export enum AllowedPaymentMethods {
  CASH = "CASH",
  CREDIT_CARD = "CREDIT_CARD",
  DATAPHONE = "DATAPHONE",
  Kushki = "Kushki",
  TRANSBANK_CL = "TRANSBANK_CL"
}

export interface OrderPages {
  [key: number]: Order[] | undefined;
}

export enum ORDER_TYPES {
  ORDERS = "pedidos",
  ISSUE = "issues",
  TICKET = "tickets",
  INCIDENT = "incidentes",
  ACTIVITIES = "actividades",
  LEADS = "leads",
  DATABASES = "bases",
  BOOKING = "agendamientos"
}

export interface Module {
  id: string;
  // Title of the module
  title: string;
  // Attribute used to order the modules
  weight: number;
  // Issue boxes in a module
  boxes?: Box[];
  // Steps that is used to render boxes
  steps?: Step[];
  // Type of Issue
  type: ORDER_TYPES;
}

export interface Box {
  id: string;
  // Box title
  name: string;
  // total number of orders
  count: number;
  // pages of orders currently available
  issuePages: Pagination<Order>;
  // box description
  description?: string;
  category?: string;
  code?: string;
  color?: string;
  transitions?: any;
}

export type IssueType = Order;

export interface IssuesBaseView {
  type: ViewTypes;
  issues: (IssueType | undefined)[] | undefined;
}

export interface IssuesBaseViewContainer extends IssuesBaseView {}

export interface PaymentInscription {
  id: number;
  uid: string;
  paymentMethodId: number;
  number: number;
  type: string;
  paymentToken: string;
  createdAt: string;
}

export interface Step {
  additional_info: any;
  category: string;
  code: string;
  color: string;
  description: string;
  description_frontend: string;
  id: number;
  name: string;
  subtitle_frontend: string;
  title_frontend: string;
}
