import ApolloClient from "apollo-boost";
import gql from "graphql-tag";

export const COLLECTIONS_LIST = "COLLECTIONS_LIST";
export const CURRENT_CLIENT_TAGS = "CURRENT_CLIENT_TAGS";

const crmCollections = new ApolloClient({
  uri: `${process.env.REACT_APP_CRM_GRAPHQL}-tag`
});

/**
 * Set graphql clients
 *
 */
const CRMContact = new ApolloClient({
  uri: process.env.REACT_APP_CRM_GRAPHQL
});

/**
 * get all collections
 * @param {*}
 */
export const collectionsList = () => {
  const USERS_QUERY = gql`
    {
      collections {
        id
        name
        description
        tags {
          id
          name
          abbreviation
          color
        }
      }
    }
  `;

  return (dispatch: any) => {
    crmCollections
      .query({
        query: USERS_QUERY
      })
      .then(res => {
        if (res.data.collections) {
          dispatch({ type: COLLECTIONS_LIST, payload: res.data.collections });
        } else {
          dispatch({ type: COLLECTIONS_LIST, payload: [] });
        }
      })
      .catch(e => console.log(e.message));
  };
};

/**
 * get current client tags
 * @param {*} clientId
 */
export const getCrmTags = (contactId: string) => async (dispatch: any) => {
  const USERS_QUERY = gql`{
        getTagsByClient(id: "${contactId}"){
          id,
          name,
          nameCollection,
          collectionId,
          tagId,
          abbreviation,
          color
        }
      }`;

  try {
    const res = await CRMContact.query({
      query: USERS_QUERY
    });

    if (res.data.getTagsByClient) {
      return res.data.getTagsByClient;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e.message);
  }
};

/**
 * Update client's tags
 * @param {*} data
 */
export const associateTagToContact = async (data: any) => {
  const USERS_MUTATION = gql`mutation {
        associateTagToClient(id:"${data.clientId}",collectionId:"${data.collectionId}", tagId: "${data.tagId}")
      {
        id
      }
    }`;

  const GLOBAL_USER_TAGS = refreshUserTags(data.clientId);
  try {
    const res = await CRMContact.mutate({
      mutation: USERS_MUTATION,
      refetchQueries: [{ query: GLOBAL_USER_TAGS }],
      variables: { id: data.clientId }
    });

    if (res.data) {
      return true;
    }

    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
};

/**
 * Update client's tags
 * @param {*} data
 */
export const updateTagContact = (data: any, callBack: any) => {
  const TAGS_MUTATION = gql`mutation {
        updateTagClient(id:"${data.id}",objectId:"${data.objectId}",collectionId:"${data.collectionId}",tagId: "${data.tagId}")
      {
        id
      }
    }`;

  const GLOBAL_USER_TAGS = refreshUserTags(data.id);
  return (dispatch: any) => {
    CRMContact.mutate({
      mutation: TAGS_MUTATION,
      refetchQueries: [{ query: GLOBAL_USER_TAGS }],
      variables: { id: data.id }
    })
      .then(res => {
        if (res.data && res.data.updateTagClient) {
          setTimeout(() => {
            dispatch(getCrmTags(data.id));
          }, 1000);
          callBack(true);
        } else {
          callBack(false);
        }
      })
      .catch(e => console.log(e.message));
  };
};

/**
 * refresh graphql clients tags
 *
 */
const refreshUserTags = (clientId: any) => {
  return gql`{
      getTagsByClient(id: "${clientId}"){
        id,
        name,
        nameCollection,
        collectionId,
        tagId,
        abbreviation,
        color
      }
    }`;
};
