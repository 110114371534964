// Tickets builder functions
import faker from "faker";

import { ISSUE_FLOW_STATUS, ISSUE_TYPES } from "types/issues.types";
import { buildIssue, genIssues, buildBaseIssue } from "./issues.builder";
import {
  genNumber,
  chooseRandomEnum,
  chooseRandom,
  fillNumber
} from "../common.builder";
import { TICKET_STATUS, Ticket, Scores, BaseTicket } from "types/ticket.types";

const genTicketId = (max: number) => {
  const num = String(genNumber(max));
  return fillNumber(num, max);
};

const genCategoryName = () => {
  return faker.random.word();
};

const genSubcategory = () => {
  return faker.random.words(genNumber({ min: 1, max: 3 }));
};

const getTicketStatus = (): TICKET_STATUS => {
  return chooseRandomEnum(TICKET_STATUS);
};

export const buildScore = (): Scores => {
  return {
    service: genNumber({ min: 1, max: 10 }),
    treatment: genNumber({ min: 1, max: 10 })
  };
};

export const buildTicket = (overrides: Partial<Ticket> = {}): Ticket => {
  const issue = buildIssue();
  const flowStatus = issue.flowStatus;
  const isClosed =
    flowStatus === ISSUE_FLOW_STATUS.CLOSED ||
    overrides.flowStatus === ISSUE_FLOW_STATUS.CLOSED;
  return {
    ...issue,
    ticketId: genTicketId(300),
    category: genCategoryName(),
    subcategory: genSubcategory(),
    status: getTicketStatus(),
    scores: isClosed ? buildScore() : undefined,
    priority: chooseRandom(["Alta", "Media", "Baja"]),
    ...overrides
  };
};

export const genTickets = (
  quantity?: number,
  overrides: Partial<Ticket> = {}
): Ticket[] => {
  return genIssues(quantity, ISSUE_TYPES.TICKET, overrides);
};

export const buildBaseTicket = (
  overrides: Partial<BaseTicket> = {}
): BaseTicket => {
  return {
    ...buildBaseIssue(),
    status: TICKET_STATUS.PENDING,
    category: genCategoryName(),
    subcategory: genSubcategory(),
    ...overrides
  };
};
