const { Device } = require("twilio-client");

// allowed keys to dial
const allowedKeyCodes = [
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  45,
  35,
  40,
  8,
  96,
  97,
  98,
  99,
  100,
  101,
  102,
  103,
  104,
  105,
  106,
  107
];
// arrows key code
const arrows = [37, 38, 39, 40];
// key codes that combined with shift are allowed
const allowedShiftKeyCodes = [51, 56, 187, ...arrows];
// key codes that combined with control or command are allowed
const allowedCtrlKeyCodes = [67, 86, 65, ...arrows];
// key codes that combined with shift or control or command are allowed
const allowedCtrlShiftCodes = [...arrows];

export const restrictCharacter = (e: any) => {
  const ctrl = e.metaKey || e.ctrlKey;
  const ctrlShiftCombinations =
    ctrl && e.shiftKey && allowedCtrlShiftCodes.includes(e.keyCode);
  const ctrlCombination =
    ctrl && !e.shiftKey && allowedCtrlKeyCodes.includes(e.keyCode);
  const shiftCombination =
    !ctrl && e.shiftKey && allowedShiftKeyCodes.includes(e.keyCode);
  const allowedKeys =
    !ctrl && !e.shiftKey && allowedKeyCodes.includes(e.keyCode);
  const isArrow = !ctrl && !e.shiftKey && arrows.includes(e.keyCode);
  if (
    !ctrlShiftCombinations &&
    !ctrlCombination &&
    !shiftCombination &&
    !allowedKeys &&
    !isArrow
  ) {
    e.preventDefault();
  }
};

export const decorate = (text: string | undefined) => {
  if (!text) {
    return "";
  }
  let newText = text.split(" ").join("");
  const arrText = newText.split("");
  for (let i = newText.length - 1; i >= 0; i--) {
    if (i % 3 === 0 && newText.length - i > 1) {
      arrText.splice(i, 0, " ");
      newText = arrText.join("");
    }
  }
  return newText.trim();
};

export const sanitize = (text: string) => {
  let newText = text.replace(/\s/g, "");
  newText = newText.replace(/\)0/g, ")");
  newText = newText.replace(/[()]/g, "");
  // newText = newText.replace(/[+]/, "");
  return newText;
};

export const checkCall = () => {
  const connection = Device.activeConnection();
  return !!connection;
};

export const setupDevice = (tokenCall: string) => {
  try {
    Device.status();
    return;
  } catch (error) {
    const deviceConf = {
      codePreferences: ["opus", "pcmu"],
      fakeLocalDTMF: true,
      enableRingingState: true,
      debug: false,
      edge: "sao-paulo",
      audioConstraints: true,
      backoffMaxMs: 2000
      // disableAudioContextSounds: true
    };
    Device.setup(tokenCall, deviceConf);
  }
};
