// Widgets reducer helper functions
import { WidgetsState as State, InitWidgetAction } from "./widgets.types";
import { ToggleDrawerAction, WidgetNames } from "./widgets.types";
import { SetViewVisibleAction, WidgetsState } from "./widgets.types";
import { commentsInitialData } from "./comments/widgets.comments.helpers";
import { FindDrawer, Drawer, PathNode } from "./widgets.types.common";
import { View, Widget, FindDrawerStrict } from "./widgets.types.common";
import { Drawers } from "./widgets.types.common";
import { contactsInitialData } from "./contacts/widgets.contacts.helpers";
import { ToggleDrawerConfig } from "components/dashboard/widgets/UseWidget/UseWidget.types";
import { customersInitialData } from "./customers/widgets.customers.helpers";
import { formsInitialData } from "./forms/widgets.forms.helpers";
import { leadsInitialData } from "./leads/widgets.leads.helpers";
import { salesInitialData } from "./sales/widgets.sales.helpers";

export const initWidget = (
  state: State,
  payload: InitWidgetAction["payload"]
) => {
  const { widgetName, initialValues } = payload;
  checkWidgetRegistered(state, widgetName);
  const initialValuesName = Object.keys(initialValues);
  const includesView = initialValuesName.includes("view");
  const includesDrawers = initialValuesName.includes("drawers");
  if (includesView || includesDrawers) {
    throw new Error("Initials value cannot initialize the View or any Drawer");
  }
  const widget = {
    ...(state as any)[widgetName],
    ...initialValues,
    initialized: true
  };
  return { ...state, [widgetName]: widget };
};

export const setViewVisible = (
  state: State,
  payload: SetViewVisibleAction["payload"]
) => {
  const { widgetName, visible } = payload;
  checkWidgetRegistered(state, widgetName);
  const view = { ...(state as any)[widgetName].view, visible };
  const widget = {
    ...(state as any)[widgetName],
    view
  };
  return { ...state, [widgetName]: widget };
};

export const toggleDrawer = (
  state: State,
  payload: ToggleDrawerAction["payload"]
) => {
  const { style = "ALL" } = payload;
  switch (style) {
    case "SINGLE":
      return toggleSingleDrawer(state, payload);
    case "WIDGET":
      return toggleAllWidgetDrawer(state, payload);
    case "ALL":
      return toggleAllDrawers(state, payload);
    default:
      throw new Error(`Toggle drawer with style ${style} is not supported`);
  }
};

export const resetWidgets = (state: State) => {
  const newState = { ...state };
  newState["path"] = [];
  newState["contacts"] = { ...contactsInitialData };
  newState["comments"] = { ...commentsInitialData };
  newState["customers"] = { ...customersInitialData };
  newState["forms"] = { ...formsInitialData };
  newState["leads"] = { ...leadsInitialData };
  newState["sales"] = { ...salesInitialData };
  return newState;
};

const checkWidgetRegistered = <W extends WidgetNames>(
  state: State,
  widgetName: W
) => {
  const widgets = Object.keys(state);
  if (!widgets.includes(widgetName)) {
    throw new Error(`Widget ${widgetName} is not registered`);
  }
};

const checkDrawerRegistered = <W extends WidgetNames>(
  state: State,
  widgetName: W,
  drawerName: keyof WidgetsState[W]["drawers"]
) => {
  checkWidgetRegistered(state, widgetName);
  const drawers = Object.keys(state[widgetName].drawers);
  if (!drawers.includes(drawerName as string)) {
    throw new Error(
      `Drawer ${drawerName} of widget ${widgetName} is not registered`
    );
  }
};

const updatePath = <W extends WidgetNames>(
  state: State,
  config: ToggleDrawerConfig<W>
): PathNode<any>[] => {
  const { widgetName, drawerName, visible, style } = config;
  if (typeof visible !== "undefined") {
    //@ts-ignore
    const prevVisible = state[widgetName].drawers[drawerName].visible;
    if (prevVisible === visible) {
      return state.path;
    }
  }
  const pathNode: PathNode<W> = {
    widgetName,
    drawerName,
    //@ts-ignore
    visible: visible ?? !state[widgetName].drawers[drawerName].visible,
    style: style ?? "WIDGET"
  };
  const path: PathNode<any>[] = [...state.path, pathNode];
  return path;
};

const toggleSingleDrawerHelper = <W extends WidgetNames>(
  state: State,
  config: ToggleDrawerConfig<W>
): Drawer<any> => {
  const { visible, widgetName, drawerName } = config;
  //@ts-ignore
  const lastVisible = state[widgetName].drawers[drawerName].visible;
  const nextVisible = visible ?? !lastVisible;
  //@ts-ignore
  return { ...state[widgetName].drawers[drawerName], visible: nextVisible };
};

const toggleSingleDrawer = <W extends WidgetNames>(
  state: State,
  config: ToggleDrawerConfig<W>
): State => {
  const { widgetName, drawerName } = config;
  checkDrawerRegistered(state, widgetName, drawerName);
  const drawer = toggleSingleDrawerHelper(state, config);
  const drawers = {
    ...state[widgetName].drawers,
    [drawerName]: drawer
  };
  const widget = { ...state[widgetName], drawers };
  const path = updatePath(state, config);
  return { ...state, [widgetName]: widget, path };
};

const toggleAllWidgetDrawerHelper = <W extends WidgetNames>(
  state: State,
  config: ToggleDrawerConfig<W>
): Widget<any, any> => {
  const { widgetName, drawerName } = config;
  const drawersKeys = Object.keys(state[widgetName].drawers);
  const drawers = drawersKeys.reduce((acc, localDrawerName: any) => {
    const queryDrawer = localDrawerName === drawerName;
    const drawer = toggleSingleDrawerHelper(state, {
      ...config,
      drawerName: localDrawerName,
      visible: queryDrawer ? config.visible : false
    });
    return { ...acc, [localDrawerName]: drawer };
  }, {});
  return {
    ...state[widgetName],
    drawers
  };
};

const toggleAllWidgetDrawer = <W extends WidgetNames>(
  state: State,
  config: ToggleDrawerConfig<W>
): State => {
  const { widgetName } = config;
  checkWidgetRegistered(state, widgetName);
  const widget = toggleAllWidgetDrawerHelper(state, config);
  const path = updatePath(state, config);
  return { ...state, [widgetName]: widget, path };
};

const toggleAllDrawers = <W extends WidgetNames>(
  state: State,
  config: ToggleDrawerConfig<W>
): State => {
  const { widgetName } = config;
  const widgetKeys = Object.keys(state).filter(widgetKey => {
    return widgetKey !== "path";
  });
  const widgets = widgetKeys.reduce((acc, widgetName: any) => {
    const widget = toggleAllWidgetDrawerHelper(state, {
      ...config,
      widgetName,
      visible: false
    });
    return { ...acc, [widgetName]: widget };
  }, {});
  const widget = toggleAllWidgetDrawerHelper(state, config);
  const path = updatePath(state, config);
  return { ...state, ...widgets, [widgetName]: widget, path };
};

export const findWidgetView = (
  state: State,
  widgetName: WidgetNames
): View<any> => {
  return state[widgetName].view;
};

export const findWidgetDrawer = <W extends WidgetNames>(
  state: State,
  options: FindDrawer<W>
): Drawer<any> | undefined => {
  const { widgetName, drawerName } = options;
  if (!drawerName) {
    return undefined;
  }
  const drawers: Drawers<any> = state[widgetName].drawers;
  return drawers[drawerName];
};

export const isViewVisible = (state: State, widgetName: WidgetNames) => {
  checkWidgetRegistered(state, widgetName);
  const view = findWidgetView(state, widgetName);
  return view.visible;
};

export const isDrawerVisible = <W extends WidgetNames>(
  state: State,
  options: FindDrawerStrict<W>
) => {
  const { widgetName, drawerName } = options;
  checkDrawerRegistered(state, widgetName, drawerName);
  const drawer = findWidgetDrawer(state, options);
  return drawer!.visible;
};
