export default {
  apiUrl: "http://yoursite.com/api/"
};

const siteConfig = {
  siteName: "XONE",
  siteIcon: "ion-flash",
  siteLogo: "/assets/images/logo.png",
  footerText: "©2019 Created by Trade"
};
const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault"
};
const language = "spanish";

const jwtConfig = {
  fetchUrl: "/api/",
  secretKey: "secretKey"
};

export { siteConfig, language, themeConfig, jwtConfig };
