// X-ONE Workspace types and interfaces
import { Account } from "./sales/Account.types";
import { Channel } from "./sales/channel.types";
import { Vendor } from "./sales/vendor.types";

// A workspace is the widest data structure of the x-one data types, it represents a commerce/client and its configurations
export interface Workspace {
  // workspace id
  id: string;
  // Commerce name
  friendlyName: string;
  // X-ONE Apps config
  config: Config;
}

export interface Config {
  // X-ONE track configuration settings
  track: TrackConfig;
  // X-ONE service configuration settings
  service: ServiceConfig;
  // X-ONE sales configuration settings
  sales: SalesConfig;
}

export interface AppConfig {
  available: boolean;
}

export interface TrackConfig extends AppConfig {}

export interface ServiceConfig extends AppConfig {}

export interface SalesConfig extends AppConfig {
  // X-mart commerce's account
  account: Account;
  // Verified vendors from a x-mart account
  vendorIds: number[];
  // salesProcess modality
  salesProcesses: SalesProcess[];
}

export interface SalesProcess {
  // SalesProcess type
  type: SalesProcessTypes;
  // Wheather the SalesProcess is active or not
  active: boolean;
  // Set of steps (processes) a SalesProcess will follow
  processes: {
    // The name of the process (Drawer name)
    [key: string]: Process;
  };
  // Additional Info
  additionalInfo: {
    // Verfied channels
    channels: Channel[];
    // Verfied vendors
    vendors: Vendor[];
  };
}

export interface Process {
  // Requirements to be fulfilled before transitioning to the next process
  prerequisites: PrerequisiteTypes[];
  // Set of available transitions
  transitions: {
    // A transition can lead to several destinies that is not more than a process name, its the front-end duty how to map to each process
    [key: string]: string[];
  };
}

export enum SalesProcessTypes {
  // When Order must be delivered
  DELIVERY = "DELIVERY",
  // When Order will be picked up in one of many predefined locations
  PICKUP = "PICKUP",
  // When Order is an appointment
  BOOKING = "BOOKING"
}

export enum PrerequisiteTypes {
  // The country where X-ONE Sales is targeted to be operating
  COUNTRY = "COUNTRY",
  // Address as text
  ADDRESS = "ADDRESS",
  // Geolocalization point given by a customer
  GEO = "GEO",
  // Billing information required
  BILLING = "BILLING",
  // Customer's dependants, insurances, etc.
  HEALTH = "HEALTH",
  // Customer's vehicles related data
  VEHICLES = "VEHICLES"
}
