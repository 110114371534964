import ApolloClient from "apollo-boost";
import gql from "graphql-tag";

/**
 * Set graphql clients
 */
const CRMContact = new ApolloClient({
  uri: process.env.REACT_APP_CRM_GRAPHQL
});

/**
 * Get current client tags
 * @param {*} clientId
 */
export const getCrmTags = async (contactId: string) => {
  const USERS_QUERY = gql`{
        getTagsByClient(id: "${contactId}"){
          id,
          name,
          nameCollection,
          collectionId,
          tagId,
          abbreviation,
          color
        }
      }`;

  const res = await CRMContact.query({
    query: USERS_QUERY
  });

  if (res.data.getTagsByClient) {
    return res.data.getTagsByClient;
  } else {
    return [];
  }
};

/**
 * Update client's tags
 * @param {*} data
 */
export const associateTagToContact = async (data: any) => {
  const USERS_MUTATION = gql`mutation {
        associateTagToClient(id:"${data.clientId}",collectionId:"${data.collectionId}", tagId: "${data.tagId}")
      {
        id
      }
    }`;

  const GLOBAL_USER_TAGS = refreshUserTags(data.clientId);

  const response = await CRMContact.mutate({
    mutation: USERS_MUTATION,
    refetchQueries: [{ query: GLOBAL_USER_TAGS }],
    variables: { id: data.clientId }
  });

  return response;
};
/**
 * Update Tag Client
 * @param data
 */
export const updateTagContact = async (data: any) => {
  const TAGS_MUTATION = gql`mutation {
        updateTagClient(id:"${data.id}",objectId:"${data.objectId}",collectionId:"${data.collectionId}",tagId: "${data.tagId}")
      {
        id
      }
    }`;

  const GLOBAL_USER_TAGS = refreshUserTags(data.id);
  const response: any = await CRMContact.mutate({
    mutation: TAGS_MUTATION,
    refetchQueries: [{ query: GLOBAL_USER_TAGS }],
    variables: { id: data.id }
  });

  return response;
};

/**
 * Refresh graphql clients tags
 */
const refreshUserTags = (clientId: any) => {
  return gql`{
      getTagsByClient(id: "${clientId}"){
        id,
        name,
        nameCollection,
        collectionId,
        tagId,
        abbreviation,
        color
      }
    }`;
};
