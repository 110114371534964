import { SalesState as State, SetProductDetailsAction } from "./sales.types";
import { SetProductsAction, SetCategoriesAction } from "./sales.types";
import { SalesActions as Actions } from "./sales.types";
import { SetStoresAction } from "./sales.types";
import {
  RESET_SALES_INIT_ORDER,
  SET_SELECTED_CART_ITEM_ID,
  SET_VENDORS
} from "./sales.actions";
import { RESET_SALES_AFTER_ORDER } from "./sales.actions";
import { SET_SELECTED_VENDOR_ID } from "./sales.actions";
import { SET_SHIPPING_COST, SET_SALES_PROCESS } from "./sales.actions";
import { SET_STORES, SET_PRODUCT_DETAILS } from "./sales.actions";
import { SET_SHOPPING_CART, RESET_SHOPPING_CART } from "./sales.actions";
import { SET_SELECTED_PAYMENT_METHOD_ID } from "./sales.actions";
import { SET_PAYMENT_METHODS } from "./sales.actions";
import { SET_SELECTED_PRODUCT_ID, SET_PRODUCTS } from "./sales.actions";
import { SET_CATEGORIES, SET_SELECTED_CATEGORY_ID } from "./sales.actions";
import { RESET_SALES, RESET_STORES, SET_ACCOUNT } from "./sales.actions";
import { SET_SELECTED_STORE_ID, SET_CHANNELS } from "./sales.actions";
import { SET_SELECTED_CHANNEL_ID, SET_LAST_ORDER } from "./sales.actions";
import { getInitialPaginationData } from "util/pagination";
import { findPaymentMethodById, stateInitOrder } from "./sales.reducer.helpers";
import { findStoreById, findCategoryById } from "./sales.reducer.helpers";
import { findProductById, findChannelById } from "./sales.reducer.helpers";
import { setPage } from "util/redux.utils";
import {
  SET_RECOMMENDED_PRODUCT,
  SET_PAYMENT_INSCRIPTIONS
} from "./sales.actions";
import { buildInitialShoppingCart } from "util/testUtils/builders/sales/shoppingCart.builder";
import { stateAfterOrder, salesStateLogout } from "./sales.reducer.helpers";
import { findVendorById } from "./sales.reducer.helpers";

const initialState: State = {
  account: undefined,
  channels: undefined,
  selectedChannel: findChannelById,
  vendors: undefined,
  salesProcess: undefined,
  selectedChannelId: undefined,
  selectedVendorId: undefined,
  selectedVendor: findVendorById,
  stores: getInitialPaginationData(),
  selectedStoreId: undefined,
  selectedStore: findStoreById,
  categories: getInitialPaginationData(),
  selectedCategoryId: undefined,
  selectedCategory: findCategoryById,
  products: {},
  selectedProductId: undefined,
  selectedProduct: findProductById,
  productsDetails: {},
  shoppingCart: buildInitialShoppingCart(),
  recommendedProduct: undefined,
  paymentMethods: undefined,
  selectedPaymentMethodId: undefined,
  selectedPaymentMethod: findPaymentMethodById,
  selectedCartItemId: undefined,
  shippingCost: undefined,
  lastOrder: undefined,
  paymentInscriptions: undefined
};

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_ACCOUNT:
      return { ...state, account: action.payload };
    case SET_SALES_PROCESS:
      return { ...state, salesProcess: action.payload };
    case SET_CHANNELS:
      return { ...state, channels: action.payload };
    case SET_VENDORS:
      return { ...state, vendors: action.payload };
    case SET_SELECTED_CHANNEL_ID:
      return { ...state, selectedChannelId: action.payload };
    case SET_SELECTED_VENDOR_ID:
      return { ...state, selectedVendorId: action.payload };
    case SET_STORES:
      return setStores(state, action.payload);
    case SET_SELECTED_STORE_ID:
      return { ...state, selectedStoreId: action.payload };
    case SET_CATEGORIES:
      return setCategories(state, action.payload);
    case SET_SELECTED_CATEGORY_ID:
      return { ...state, selectedCategoryId: action.payload };
    case SET_PAYMENT_METHODS:
      return { ...state, paymentMethods: action.payload };
    case SET_SELECTED_PAYMENT_METHOD_ID:
      return { ...state, selectedPaymentMethodId: action.payload };
    case SET_PRODUCTS:
      return setProducts(state, action.payload);
    case SET_SELECTED_PRODUCT_ID:
      return { ...state, selectedProductId: action.payload };
    case SET_PRODUCT_DETAILS:
      return setProductDetails(state, action.payload);
    case RESET_STORES:
      return { ...state, stores: getInitialPaginationData() };
    case SET_RECOMMENDED_PRODUCT:
      return { ...state, recommendedProduct: action.payload };
    case SET_LAST_ORDER:
      return { ...state, lastOrder: action.payload };
    case SET_SHOPPING_CART:
      return { ...state, shoppingCart: action.payload };
    case SET_SHIPPING_COST:
      return { ...state, shippingCost: action.payload };
    case SET_SELECTED_CART_ITEM_ID:
      return { ...state, selectedCartItemId: action.payload };
    case RESET_SHOPPING_CART:
      return { ...state, shoppingCart: buildInitialShoppingCart() };
    case RESET_SALES_AFTER_ORDER:
      return resetSalesAfterOrder(state);
    case RESET_SALES_INIT_ORDER:
      return resetSalesInitOrder(state);
    case RESET_SALES:
      return resetSales();
    case SET_PAYMENT_INSCRIPTIONS:
      return { ...state, paymentInscriptions: action.payload };
    default:
      return state;
  }
};

const setStores = (
  state: State,
  payload: SetStoresAction["payload"]
): State => {
  const newStores = setPage(state.stores, payload);
  return { ...state, stores: newStores };
};

const setCategories = (
  state: State,
  payload: SetCategoriesAction["payload"]
) => {
  const categories = setPage(state.categories, payload);
  return { ...state, categories };
};

const setProducts = (
  state: State,
  payload: SetProductsAction["payload"]
): State => {
  const { categoryId, channelId, products } = payload;
  const { totalPages, page, items } = products;
  const newProducts = { ...state.products };
  const channelExists = state.products[channelId];
  if (!channelExists) {
    newProducts[channelId] = {};
  }
  const newChannel = { ...state.products[channelId] };
  const categoryExists = newChannel[categoryId];
  if (!categoryExists) {
    newChannel[categoryId] = getInitialPaginationData({ totalPages, page });
  }

  const newCategory = { ...newChannel[categoryId] };
  newCategory.items[page] = items!;

  newChannel[categoryId] = newCategory;
  newProducts[channelId] = newChannel;
  return { ...state, products: newProducts };
};

const setProductDetails = (
  state: State,
  payload: SetProductDetailsAction["payload"]
): State => {
  const { productId } = payload;
  const newProducts = { ...state.productsDetails };
  const newDetails = { ...newProducts };
  newDetails[productId] = payload;
  return { ...state, productsDetails: newDetails };
};

const resetSalesAfterOrder = (state: State): State => {
  return {
    ...state,
    ...stateAfterOrder
  };
};

const resetSalesInitOrder = (state: State): State => {
  return {
    ...state,
    ...stateInitOrder
  };
};

const resetSales = (): State => {
  return salesStateLogout;
};

export default reducer;
