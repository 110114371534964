// Product builder functions
import faker from "faker";

import { Product, ProductType } from "types/sales/product.types";
import { genNumber, genWords, genNumericId } from "../common.builder";
import { ProductAttributes, PriceCategory } from "types/sales/product.types";
import { chooseRandomEnum, genBiasBoolean } from "../common.builder";
import { ProductCategory } from "types/sales/product.types";
import { genImages } from "./image.builder";

export const buildProductAttributes = (
  overrides: Partial<ProductAttributes> = {}
): ProductAttributes => {
  return {
    Cantidad: "1",
    externalId: genNumericId().toString(),
    showInMenu: genBiasBoolean(0.99),
    ...overrides
  };
};

export const buildPriceCategory = (
  overrides: Partial<PriceCategory> = {}
): PriceCategory => {
  return {
    category: chooseRandomEnum(ProductCategory),
    grossPrice: genNumber({ min: 1, max: 15, precision: 2 }),
    netPrice: genNumber({ min: 1, max: 15, precision: 2 }),
    symbol: "$",
    taxes: [],
    discounts: [],
    discountGrossPrice: 0,
    discountNetPrice: 0,
    discount: 0,
    ...overrides
  };
};

export const buildProduct = (overrides: Partial<Product> = {}): Product => {
  const numberOfImages = genBiasBoolean(0.7) ? 1 : 3;
  return {
    productId: genNumericId(),
    name: faker.commerce.productMaterial(),
    description: genWords(5),
    type: chooseRandomEnum(ProductType),
    prices: {
      NORMAL: buildPriceCategory(),
      POINTS: buildPriceCategory()
    },
    outOfService: genBiasBoolean(0.01),
    outOfStock: genBiasBoolean(0.1),
    stock: genNumber(),
    measure: "1",
    attributes: buildProductAttributes(),
    suggestedPrice: "$0.00",
    available: genBiasBoolean(0.9),
    images: genImages(numberOfImages, [], {
      bucket: "admin.kfc.production",
      key: "products/5e7e1b675acea_MEGA FESTIN 1.png",
      cloudFrontUrl: "https://d32dna7apnunfh.cloudfront.net",
      url:
        "https://s3.amazonaws.com/admin.kfc.production/products/5e7e1b675acea_MEGA FESTIN 1.png"
    }),
    standardTime: "YES",
    sponsored: genBiasBoolean(0.1),
    coverUrl: "",
    status: "ACTIVE",
    ...overrides
  };
};

export const genProducts = (quantity?: number): Product[] => {
  const num = quantity ?? Math.floor(Math.random() * 9) + 1;

  const products = [];
  for (let i = 0; i < num; i++) {
    const product = buildProduct();
    products.push(product);
  }
  return products;
};
