import {
  CommentsState as State,
  CommentsActions as Actions
} from "./comments.types";
import {
  SET_COMMENT,
  TOGGLE_DRAWER_COMMENT,
  ADD_LOCAL_COMMENT
} from "./comments.actions";

const initialState: State = {
  isDrawerFullCommentOpened: false,
  comment: null,
  localComments: []
};

const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case SET_COMMENT:
      return { ...state, comment: action.payload };
    case TOGGLE_DRAWER_COMMENT:
      return {
        ...state,
        isDrawerFullCommentOpened:
          action.payload ?? !state.isDrawerFullCommentOpened
      };
    case ADD_LOCAL_COMMENT:
      return {
        ...state,
        localComments: [...state.localComments, action.payload]
      };
    default:
      return state;
  }
};

export default reducer;
