import {
  IssuesState as State,
  ServiceIssuesActions as Actions,
  SetBoxesAction,
  SetIssuesAction,
  AddNewBoxAction,
  AddNewIssueAction
} from "./issues.types";
import {
  SET_SERVICE_MODULES,
  SET_SERVICE_BOXES,
  SET_SERVICE_SELECTED_MODULE,
  SET_SERVICE_ORDERBY_VALUE,
  SET_SERVICE_SELECTED_BOX,
  SET_SERVICE_VIEW_TYPE,
  SET_SERVICE_ISSUES,
  ADD_SERVICE_NEW_BOX,
  ADD_SERVICE_NEW_ISSUE,
  RESET_SERVICE_ISSUES
} from "./issues.actions";
import {
  getSelectedBox,
  getSelectedModule,
  insertInModule,
  insertInBox,
  insertInIssues,
  getModule,
  getIssuesPage
} from "./issues.reducer.helpers";
import { ViewTypes } from "types/issues.types";

const initialState: State = {
  modules: null,
  selectedModuleId: "",
  orderBy: "",
  selectedModule: getSelectedModule,
  selectedBoxId: "",
  selectedBox: getSelectedBox,
  viewType: ViewTypes.ROWS
};

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_SERVICE_MODULES:
      return { ...state, modules: action.payload };
    case SET_SERVICE_BOXES:
      return setBoxes(state, action.payload);
    case SET_SERVICE_SELECTED_MODULE:
      return { ...state, selectedModuleId: action.payload };
    case SET_SERVICE_ORDERBY_VALUE:
      return { ...state, orderBy: action.payload };
    case SET_SERVICE_SELECTED_BOX:
      return { ...state, selectedBoxId: action.payload };
    case SET_SERVICE_VIEW_TYPE:
      return { ...state, viewType: action.payload };
    case SET_SERVICE_ISSUES:
      return setIssues(state, action.payload);
    case ADD_SERVICE_NEW_BOX:
      return addNewBox(state, action.payload);
    case ADD_SERVICE_NEW_ISSUE:
      return addNewIssue(state, action.payload);
    case RESET_SERVICE_ISSUES:
      return resetReducer(state);
    default:
      return state;
  }
};

const setBoxes = (state: State, payload: SetBoxesAction["payload"]): State => {
  const { moduleId, boxes } = payload;
  const { modules } = insertInModule(state, moduleId, { boxes });
  return { ...state, modules };
};

const setIssues = (
  state: State,
  payload: SetIssuesAction["payload"]
): State => {
  const { moduleId, boxId, page } = payload;
  const newIssues = payload.issues;
  const issuesRes = insertInIssues(state, moduleId, boxId, page, newIssues);
  const { issuePages } = issuesRes;
  const { boxes } = insertInBox(state, moduleId, boxId, { issuePages });
  const { modules } = insertInModule(state, moduleId, { boxes });
  return { ...state, modules };
};

const addNewBox = (
  state: State,
  payload: AddNewBoxAction["payload"]
): State => {
  const { moduleId, box } = payload;
  const { module } = getModule(state, moduleId);
  if (!module.boxes) {
    throw new Error(
      "You should not call addNewBox before boxes are initialized"
    );
  }
  const boxes = [box, ...module.boxes];
  const { modules } = insertInModule(state, moduleId, { boxes });
  return { ...state, modules };
};

const addNewIssue = (state: State, payload: AddNewIssueAction["payload"]) => {
  const { issue, boxId, moduleId } = payload;
  const { issuePage } = getIssuesPage(state, moduleId, boxId, 0);
  if (!issuePage) {
    throw new Error(
      "You should not call addNewIssue before issues of given page are initialized"
    );
  }
  const issues = [issue, ...issuePage];
  const { issuePages } = insertInIssues(state, moduleId, boxId, 0, issues);
  const { boxes } = insertInBox(state, moduleId, boxId, { issuePages });
  const { modules } = insertInModule(state, moduleId, { boxes });
  return { ...state, modules };
};

const resetReducer = (state: State): State => {
  return {
    ...state,
    modules: null,
    selectedModuleId: "",
    orderBy: "",
    selectedBoxId: ""
  };
};

export default reducer;
