import { AppState, ThunkDispatch } from "redux/store.types";
// import { Workspace } from "../../types/workspace.types";
import {
  fetchCallsByDirection,
  fetchSummaryTotalsTask,
  fetchCallsByStatus,
  fetchChatsByStatus,
  fetchTasksByCategories
} from "services/supervisor/supervisor.service";
import { generateDataToClassificationTasks } from "components/supervisor/Panel/Panel.helpers";

export const RESET_SUPERVISOR = "RESET_SUPERVISOR";
export const CALLS_DIRECTION = "CALLS_DIRECTION";
export const SUMMARY_TOTAL_TASK = "SUMMARY_TOTAL_TASK";
export const COUNT_TASK = "COUNT_TASK";
export const TASKS_BY_CATEGORIES = "TASKS_BY_CATEGORIES";

export const resetSupervisor = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  return dispatch({ type: RESET_SUPERVISOR });
};

export const getCallsByDirection = () => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const payload = await fetchCallsByDirection();
  return dispatch({ type: CALLS_DIRECTION, payload });
};

export const getSummaryTotalTask = () => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const payload = await fetchSummaryTotalsTask();
  return dispatch({ type: SUMMARY_TOTAL_TASK, payload });
};

export const getCountTask = () => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const payload = [];
  const payloadCallsStatus = await fetchCallsByStatus();
  const payloadChatsStatus = await fetchChatsByStatus();
  payload.push({ uniqueName: "calls", tasks: payloadCallsStatus });
  payload.push({ uniqueName: "chats", tasks: payloadChatsStatus });
  return dispatch({ type: COUNT_TASK, payload });
};

export const getTasksByCategories = () => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const payload = [];
  const payloadDate = {
    from: new Date(Date.now() - 86400000).toISOString().slice(0, 10),
    to: new Date().toISOString().slice(0, 10)
  };
  const payloadIn = await fetchTasksByCategories(payloadDate, "IN");
  const payloadOut = await fetchTasksByCategories(payloadDate, "OUT");
  payload.push(payloadIn);
  payload.push(payloadOut);
  const obj = generateDataToClassificationTasks(payload);
  return dispatch({
    type: TASKS_BY_CATEGORIES,
    payload: obj
  });
};
