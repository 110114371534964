import { QueuedTaskTypes, QueuedTaskBody } from "./taskQueue.types";

// Reservation interfaces and types

export interface Reservation extends BaseReservation {
  sid: string;
  channel: Channel;
  task: Task;
  contact?: Contact | ContactScheduledTask;
  scheduledTask?: QueuedTaskBody;
  reservationStatus: string;
  unread: number;
  title: string;
}

export interface RawReservation extends BaseReservation {
  sid: string;
  task: Task;
}

export interface ReservationTwilio extends BaseReservation {
  sid: string;
  task: Task;
  reservation_status: string;
}

interface BaseReservation {
  worker_sid: string;
  task_sid: string;
  date_created: string;
  date_updated: string;
  workspace_sid: string;
  account_sid: string;
  worker_name: string;
  links: Links;
}

interface Links {
  task: string;
  worker: string;
  workspace: string;
}

export interface Channel {
  sid: string;
  mediaChannel: MEDIA_CHANNELS;
  mediaChannelId: MEDIA_CHANNELS_ID;
  from: string;
  messages: Message[];
}

export enum MEDIA_CHANNELS {
  MESSENGER = "messenger",
  WHATSAPP = "whatsapp",
  SIP = "sip",
  SMS = "sms",
  EMAIL = "email",
  UNKNOWN = "UNKNOWN"
}

export enum MEDIA_CHANNELS_ID {
  WHATSAPP_ID = "whatsappId",
  FACEBOOK_ID = "facebookId",
  PHONE = "phone.value",
  UNKNOWN = "UNKNOWN"
}

export interface Message {
  sid?: string;
  id: string;
  from: string;
  body: string;
  type: string;
  mediaUrl: string | null;
  dateCreated: string;
  typeChannel: MEDIA_CHANNELS;
  messageSid: string;
  attributes: MessageAttributes;
}

export interface MessageAttributes {
  read: boolean;
}

export interface Contact {
  id: string;
  name: string;
  lastname: string;
  contactName: string | null;
  document: string;
  email: MultivalueSelection[];
  phone: MultivalueSelection[];
  address: MultivalueSelection[];
  facebookId: string[];
  whatsappId: string[];
  twitterId: string[];
  birthday: string | null;
  age: string | null;
  tags: string[];
}

export interface ContactScheduledTask {
  id: string;
  name: string;
  lastname: string;
  contactName: string | null;
  document: string;
  emails: MultivalueSelection[];
  phones: MultivalueSelection[];
  addresses: MultivalueSelection[];
  facebookId: string[];
  whatsappId: string[];
  twitterId: string[];
  birthday: string | null;
  age: string | null;
  phone?: never;
}

export interface MultivalueSelection {
  key: string;
  value: string;
}

export interface Task {
  sid: string;
  assignment_status: string; //TODO:
  assignmentStatus: string; //TODO:
  date_created: string;
  date_updated: string;
  attributes: TaskAttributes;
  taskSource?: QueuedTaskTypes;
}

export interface TaskAttributes {
  channelSid: string;
  from: string;
  mediaChannel: MEDIA_CHANNELS;
  type: string;
  direction: DIRECTIONS;
  callerPhone?: string;
  recordUri?: string;
  call_sid?: string;
  transfered?: boolean;
  source?: string;
}

export enum DIRECTIONS {
  INBOUND = "inbound",
  OUTBOUND = "outbound"
}
