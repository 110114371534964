import { AppState, ThunkDispatch } from "redux/store.types";
import {
  fetchModules,
  fetchIssues,
  fetchBoxes
} from "services/service/issues/issues.service";
import { ViewTypes, ISSUE_TYPES, Box, Issue } from "types/issues.types";
import CONSTANTS from "config/constants";
import { getModule } from "./issues.reducer.helpers";

export const SET_SERVICE_MODULES = "SET_SERVICE_MODULES";
export const SET_SERVICE_BOXES = "SET_SERVICE_BOXES";
export const SET_SERVICE_SELECTED_MODULE = "SET_SERVICE_SELECTED_MODULE";
export const SET_SERVICE_ORDERBY_VALUE = "SET_SERVICE_ORDERBY_VALUE";
export const SET_SERVICE_SELECTED_BOX = "SET_SERVICE_SELECTED_BOX";
export const SET_SERVICE_VIEW_TYPE = "SET_SERVICE_VIEW_TYPE";
export const SET_SERVICE_ISSUES = "SET_SERVICE_ISSUES";
export const ADD_SERVICE_NEW_BOX = "ADD_SERVICE_NEW_BOX";
export const ADD_SERVICE_NEW_ISSUE = "ADD_SERVICE_NEW_ISSUE";
export const RESET_SERVICE_ISSUES = "RESET_SERVICE_ISSUES";

export const setModules = () => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const modules = await fetchModules();
  dispatch({ type: SET_SERVICE_MODULES, payload: modules });
};

export const setBoxes = (moduleId: string) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const issueState = getState().ServiceIssues;
  const selectedBoxId = issueState.selectedBoxId;
  const { module } = getModule(issueState, moduleId);
  const boxes = await fetchBoxes(module.type);
  dispatch({ type: SET_SERVICE_BOXES, payload: { moduleId, boxes } });
  if (!selectedBoxId && boxes.length) {
    dispatch(setSelectedBox(boxes[0].id, moduleId));
  }
};

export const setSelectedModule = (moduleId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SERVICE_SELECTED_MODULE, payload: moduleId });
};
export const setOrderBy = (orderBy: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  console.log("setOrderBy", orderBy);

  dispatch({ type: SET_SERVICE_ORDERBY_VALUE, payload: orderBy });
};

export const setSelectedBox = (boxId: string, moduleId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch(setSelectedModule(moduleId));
  dispatch({ type: SET_SERVICE_SELECTED_BOX, payload: boxId });
};

export const setViewType = (viewType: ViewTypes) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SERVICE_VIEW_TYPE, payload: viewType });
};

export const setIssues = (
  page: number,
  boxId: string,
  moduleId: string,
  //DELETE ME LATER
  type: ISSUE_TYPES,
  email: string,
  uid: string,
  orderBy: string
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const pageSize = CONSTANTS.ISSUES.PAGE_SIZE;
  const issues = await fetchIssues(
    moduleId,
    boxId,
    page,
    pageSize,
    type,
    email,
    uid,
    orderBy
  );
  dispatch({
    type: SET_SERVICE_ISSUES,
    payload: { issues, page, boxId, moduleId }
  });
};

export const addNewBox = (moduleId: string, box: Box) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: ADD_SERVICE_NEW_BOX, payload: { moduleId, box } });
};

export const addNewIssue = (issue: Issue, boxId: string, moduleId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({
    type: ADD_SERVICE_NEW_ISSUE,
    payload: { issue, boxId, moduleId }
  });
};

export const resetIssues = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: RESET_SERVICE_ISSUES });
};
