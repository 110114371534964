// pagination utility functions and data

import { Pagination } from "types/common.types";

const initialPaginationData = { page: 0, totalPages: 1, items: {} };
Object.freeze(initialPaginationData);

export const getInitialPaginationData = <T>(
  overrides: Partial<Pagination<T>> = {}
): Pagination<T> => {
  const initialData = { ...initialPaginationData, items: {} };
  return { ...initialData, ...overrides };
};

export const getPaginationItems = <T>(
  paginationItems: Pagination<T>["items"]
): T[] => {
  const itemsByPage = Object.values(paginationItems);
  const flatItems: (T | undefined)[] = itemsByPage.flat();
  // @ts-ignore
  const items: T[] = flatItems.filter(item => !!item);
  return items;
};

export const getPageOfItem = (
  paginationItems: Pagination<any>["items"],
  id: string,
  field: string
): string | undefined => {
  const page = Object.entries(paginationItems).find(([k, v]) => {
    if (!v) {
      return false;
    }
    return v.find(item => {
      return id === item[field];
    });
  });

  if (!page) {
    return;
  }
  return page[0];
};
