// Customer utility functions and data
import CONSTANTS from "config/constants";
import { MapData } from "redux/chat/chat.types";

const { SALES_ISSUES } = CONSTANTS;
const { DEFAULT_LNG, DEFAULT_LAT, DEFAULT_ZOOM } = SALES_ISSUES;

const initialMapData: MapData = {
  modified: false,
  opened: false,
  latitude: DEFAULT_LAT,
  longitude: DEFAULT_LNG,
  zoom: DEFAULT_ZOOM,
  address: "",
  label: ""
};

export const getInitialMapData = (
  overrides: Partial<MapData> = {}
): MapData => {
  return { ...initialMapData, ...overrides };
};
