import firebase from "firebase/app";
import { message } from "antd";
import { push } from "connected-react-router";

import axiosDefault from "../../util/axios";
import { getWorkerActivities } from "../track/track.actions";
import { updateWorkerStatus } from "../track/track.actions";
import CONSTANTS from "../../config/constants";
import { fetchWorkerMSTaskRouter } from "../../services/auth/auth";
import { resetWidgets } from "redux/widgets/widgets.actions";
import { workspace } from "./auth.helpers";
import { setChannels, setVendors } from "redux/sales/sales/sales.actions";
import { setSalesProcess } from "redux/sales/sales/sales.actions";
import { setSelectedVendorId } from "redux/sales/sales/sales.actions";
import { setSelectedChannelId } from "redux/sales/sales/sales.actions";
import { setAccount, resetSales } from "redux/sales/sales/sales.actions";
import { resetCustomer } from "redux/customer/customer.actions";
import { resetSalesIssues } from "redux/sales/issues/issues.actions";
import { resetLocation } from "redux/location/location.actions";
import { Worker } from "types/worker.types";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const SET_WORKER_AUTH_DATA = "SET_WORKER_AUTH_DATA";
export const SET_WORKER_TWILIO_DATA = "SET_WORKER_TWILIO_DATA";
export const SET_WORKER_ACTIVITY = "SET_WORKER_ACTIVITY";
export const REMOVE_CALL_TOKEN = "REMOVE_CALL_TOKEN";

const roles = CONSTANTS.ROLES;

export const logIn: any = (formData?: any, url?: string) => async (
  dispatch: any,
  getState: any
) => {
  try {
    dispatch({ type: LOGIN_ERROR, payload: null });
    dispatch({ type: LOGIN_LOADING, payload: true });
    if (!formData) {
      const data = firebase.auth().currentUser;
      await dispatch(fetchWorkerData(data));
      await dispatch(getWorkerActivities());
    } else {
      const { email, password } = formData;
      // License Check
      const licenseCheckPassed = await axiosDefault.post(
        "/api/twilio/worker/license"
      );
      // Sign in
      await axiosDefault.post("/api/auth/signIn", { email, password });
      const res = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      await dispatch(fetchWorkerData(res.user));
      await dispatch(getWorkerActivities());
      const roleId = getState().Auth.worker.additionalInfo.roleId;
      if (!licenseCheckPassed.data && roleId === roles.OPERATOR) {
        try {
          dispatch(logOut());
          dispatch({
            type: LOGIN_ERROR,
            payload: "Ha llegado al límite de licencias"
          });
          message.destroy();
          message.error("Ha llegado al límite de licencias");
          dispatch({ type: LOGIN_LOADING, payload: false });
          return;
        } catch (e) {
          message.destroy();
          dispatch({ type: LOGIN_LOADING, payload: false });
          return;
        }
      }
    }
    const redirectUrl = url || CONSTANTS.AUTHORIZED_ENTRY_PATH;

    dispatch(push(redirectUrl));
  } catch (error) {
    clearToken();
    dispatch({
      type: LOGIN_ERROR,
      payload: "Usuario o contraseña incorrecto"
    });
    message.error("Usuario o contraseña incorrecto");
  }
  dispatch({ type: LOGIN_LOADING, payload: false });
};

export const logOut: any = () => async (dispatch: any, getState: Function) => {
  const reservations = getState().Track.reservations;
  if (reservations.length > 0) {
    message.warning("Tiene reservaciones por gestionar");
    return;
  }

  const worker = getState().Auth.worker;
  const activities = getState().Track.workerActivities;

  const activitySidLogout = activities.filter(
    (activity: any) => activity.attributes.activityLogout
  );

  try {
    const body = {
      workerSid: worker?.sid,
      activitySid:
        activitySidLogout.length > 0 ? activitySidLogout[0]?.sid : undefined
    };
    await dispatch(resetCustomer());
    await dispatch(resetSales());
    await dispatch(resetLocation());
    await dispatch(resetSalesIssues());
    await updateWorkerStatus(body);
    await firebase.auth().signOut();
    clearToken();
    dispatch(push("/signin"));
    dispatch({ type: SET_WORKER_AUTH_DATA, payload: null });
  } catch (e) {
    message.error("Error al cerrar sesión");
  }
  dispatch(resetWidgets());
};

export const isLoggedIn = () => {
  const user = firebase.auth().currentUser;
  return !!user;
};

export function clearToken() {
  const tokenCurrentSessionExpiracy = process.env
    .REACT_APP_CURRENT_SESSION_EXPIRACY_TOKEN as string;
  // localStorage.removeItem(tokenChatManager);
  localStorage.removeItem(tokenCurrentSessionExpiracy);
}

export const passwordRecovery = (email: any) => (dispatch: any) => {
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      message.info(
        "Estamos validando tu informacion, te haremos llegar un correo con los pasos para recuperar tu cuenta",
        5
      );
      dispatch(push("/signin"));
    })
    .catch(error => {
      message.error("Usuario invalido, favor revisa la informacion");
    });
};

export const getAuthorization = () => {
  if (!firebase.auth().currentUser) {
    return null;
  }
  // FIXME:
  // @ts-ignore
  return firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(idToken => `Bearer ${idToken}`);
};

export const fetchWorkerData = (user: any) => async (dispatch: any) => {
  if (user && !user.isAnonymous) {
    try {
      const msTaskRouterData = await fetchWorkerMSTaskRouter();
      const userData: Partial<Worker> = {
        email: user.email,
        uid: user.uid,
        accessToken: user.ra,
        emailVerified: user.emailVerified,
        refreshToken: user.refreshToken,
        isAuth: true,
        name: msTaskRouterData.name,
        lastName: msTaskRouterData.lastname,
        //TODO: remove ?? workspace once the back-end sends us the real workspace
        workspace: msTaskRouterData.workspace ?? workspace,
        additionalInfo: { ...msTaskRouterData.additionalInfo }
      };
      await dispatch(fetchWorkerTwilioData(msTaskRouterData.twilioSid));
      const userWorkspace = userData.workspace!;
      document.title = `X-One | ${userWorkspace.friendlyName}`;
      const salesConfig = userWorkspace.config.sales;
      const { account, salesProcesses } = salesConfig;
      const defaultSalesProcess = salesProcesses[0];

      if (defaultSalesProcess) {
        const { channels, vendors } = defaultSalesProcess.additionalInfo;
        dispatch(setAccount(account));
        dispatch(setChannels(channels));
        dispatch(setVendors(vendors));
        dispatch(setSalesProcess(defaultSalesProcess));
        dispatch(setSelectedVendorId(vendors[0]?.id));
        dispatch(setSelectedChannelId(channels[0]?.id));
      }
      dispatch({
        type: SET_WORKER_AUTH_DATA,
        payload: userData
      });
      dispatch({
        type: LOGIN_ERROR,
        payload: null
      });
    } catch (e) {
      console.log(e.message);
    }
  } else {
    dispatch(push(CONSTANTS.NOT_AUTHORIZED_ENTRY_PATH));
  }
  clearToken();
};

export const setLoginError = (error: string | null) => (dispatch: any) => {
  dispatch({
    type: LOGIN_ERROR,
    payload: error
  });
};

//TODO: refactor login
export const fetchWorkerAuthData = () => {};

export const fetchWorkerTwilioData = (workerSid: any) => async (
  dispatch: any
) => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.post(
      "/api/twilio/login",
      {
        workerSid: workerSid
      },
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    //TODO: manejar evento que worker no llega con los tokens del chat, taskrouter y call
    dispatch({
      type: SET_WORKER_TWILIO_DATA,
      payload: data.worker
    });
  } catch (error) {
    message.error("Hubo un error autenticando al usuario");
  }
};

export const setWorkerActivity = (
  activitySid: string,
  activityName: string,
  available: boolean
) => (dispatch: any) => {
  const payload = { activitySid, activityName, available };
  dispatch({ type: SET_WORKER_ACTIVITY, payload });
};
