// Sales widget helper functions and data
import { widgetInitialData } from "../widgets.helpers";
import { SalesDrawers } from "./widgets.sales.types";

const drawers: SalesDrawers = {
  stores: {
    visible: false
  },
  categories: {
    visible: false
  },
  products: {
    visible: false
  },
  product: {
    visible: false
  },
  cart: {
    visible: false
  },
  checkout: {
    visible: false
  },
  order: {
    visible: false
  },
  checkoutCash: {
    visible: false
  },
  checkoutCreditCard: {
    visible: false
  },
  creditCardList: {
    visible: false
  },
  newCreditCard: {
    visible: false
  },
  checkoutPayLink: {
    visible: false
  },
  history: {
    visible: false
  }
};

export const salesInitialData = { ...widgetInitialData, drawers };
