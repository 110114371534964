import { createGlobalStyle } from "styled-components";

import animations from "./animations";
import base from "./base";
import typography from "./typography";
import cssVariables from "../util/cssVariables";

const styles = `${cssVariables} ${base} ${typography} ${animations}`;

const GlobalStyle = createGlobalStyle`
    ${styles}
`;

export default GlobalStyle;
