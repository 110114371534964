// Customer services
import {
  PaginatedCustomers,
  PaginatedExternalCustomers
} from "./customer.service.types";
import { ShippingAddressRes } from "./customer.service.types";
import CONSTANTS from "config/constants";
import { genCustomersMocked } from "util/testUtils/builders/customer/customer.builder";
import { BillingInformation, BillingInformationReq } from "types/billing.types";
import { Address } from "types/address.types";
import { PaginationRes } from "types/common.types";
import { NewCustomer } from "types/customer.types";
import { firebaseError } from "util/errors/xmartErrors";
import { message } from "antd";
import { axiosXmart } from "util/axios";
import { getAuthorization } from "redux/auth/auth.actions";

const { SALES_ISSUES, DEFAULT_PAGE_SIZE, DEFAULT_CUSTOMERS_SIZE } = CONSTANTS;
const { BILLING_DATA } = SALES_ISSUES;
const { PLATFORM, CLIENT_VERSION, CONTENT_TYPE } = SALES_ISSUES;
const { SHIPPING_ADDRESS, USERS, UPDATE_USERS, FIND_USERS } = SALES_ISSUES;

const headers = {
  Platform: PLATFORM,
  "client-version": CLIENT_VERSION,
  "Content-Type": CONTENT_TYPE
};

export const addNewCustomer = async (
  newCustomer: NewCustomer
): Promise<NewCustomer | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${USERS}`;
    const result = await axiosXmart.post(url, newCustomer, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    return result.data;
  } catch (e) {
    const data = e.response?.data;
    if (data && data.code === 422) {
      firebaseError(data.warning);
      return;
    }
    message.error("Falló la creación del usuario");
    return;
  }
};

export const updateCustomer = async (
  updateCustomer: NewCustomer
): Promise<NewCustomer | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${UPDATE_USERS}`;
    const result = await axiosXmart.put(url, updateCustomer, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    const filteredCustomer = { ...result.data };
    return filteredCustomer;
  } catch (e) {
    const data = e.response?.data;
    if (data && data.code === 422) {
      firebaseError(data.warning);
      return;
    }
    message.error("Ocurrió un error al actualizar el usuario");
    return;
  }
};

export const fetchCustomers = async (
  query: string,
  page: number,
  findBy?: string
): Promise<PaginatedCustomers | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    let url;
    if (findBy) {
      url = `/${FIND_USERS}?parameter=${query}&size=${DEFAULT_CUSTOMERS_SIZE}&page=${page}&findBy=${findBy}`;
    } else {
      url = `/${FIND_USERS}?parameter=${query}&size=${DEFAULT_CUSTOMERS_SIZE}&page=${page}`;
    }

    const result = await axiosXmart.get(url, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    const totalPages = Math.ceil(result.data.length / DEFAULT_PAGE_SIZE);
    return { page, totalPages, items: result.data };
  } catch (e) {
    message.error(
      "Ocurrió un problema al obtener los clientes en datos internos"
    );
    return;
  }
};

export const fetchBillingInformation = async (
  page: number,
  uid: string
): Promise<PaginationRes<BillingInformation> | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${BILLING_DATA}?uid=${uid}`;
    const result = await axiosXmart.get(url, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    const { data, total } = result.data;
    return { page, totalPages: total, items: data };
  } catch (e) {
    message.error(
      "Ocurrió un problema al obtener los datos de facturación del usuario"
    );
    return;
  }
};

export const updateBillingInformation = async (
  billingId: string,
  billingInformation: BillingInformationReq
): Promise<BillingInformationReq | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    const { uid } = billingInformation;
    const url = `/${BILLING_DATA}/${billingId}?uid=${uid}`;
    const result = await axiosXmart.put(url, billingInformation, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    return result.data;
  } catch (e) {
    const data = e.response?.data;
    if (data && data.code === 422) {
      firebaseError(data.warning);
      return;
    }
    message.error(
      "Falló la actualización de los datos de facturación del usuario"
    );
    return;
  }
};

export const addNewBillingInformation = async (
  billingInformation: BillingInformationReq
): Promise<BillingInformationReq | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${BILLING_DATA}`;
    const result = await axiosXmart.post(url, billingInformation, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    return result.data;
  } catch (e) {
    const data = e.response?.data;
    if (data && data.code === 422) {
      firebaseError(data.warning);
      return;
    }
    message.error("Falló la creación de los datos de facturación del usuario");
    return;
  }
};

export const fetchAddress = async (
  page: number,
  uid: string
): Promise<PaginationRes<Address> | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${SHIPPING_ADDRESS}?uid=${uid}`;
    const result: ShippingAddressRes = await axiosXmart.get(url, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    const { data, total } = result.data;
    return { page, totalPages: total, items: data };
  } catch (e) {
    message.error("Ocurrió un problema al obtener las direcciones del usuario");
    return;
  }
};

export const updateAddress = async (
  id: string,
  address: Address
): Promise<Address | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${SHIPPING_ADDRESS}/${id}`;
    const result = await axiosXmart.put(url, address, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    return result.data;
  } catch (e) {
    const data = e.response?.data;
    if (data && data.code === 422) {
      firebaseError(data.warning);
      return;
    }
    message.error(
      "Falló la actualización de la dirección de envío del usuario"
    );
    return;
  }
};

export const addNewAddress = async (
  address: Address
): Promise<Address | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${SHIPPING_ADDRESS}`;
    const result = await axiosXmart.post(url, address, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    return result.data;
  } catch (e) {
    const data = e.response?.data;
    if (data && data.code === 422) {
      firebaseError(data.warning);
      return;
    }
    message.error("Falló la creación de la dirección de envío del usuario");
    return;
  }
};

export const fetchCustomersMocked = (
  page: number
): Promise<PaginatedCustomers> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        page,
        totalPages: 5,
        items: genCustomersMocked(DEFAULT_PAGE_SIZE)
      });
    }, 1000);
  });
};

export const fetchExternalCustomers = async (
  query: string,
  page: number
): Promise<PaginatedExternalCustomers | undefined> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/users/kfc?querySearch=${query}&size=${DEFAULT_CUSTOMERS_SIZE}&page=${page}`;
    const result = await axiosXmart.get(url, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    const respuesta = result.data;
    const totalPages = Math.ceil(respuesta.data.length / DEFAULT_PAGE_SIZE);
    return { page, totalPages, total: respuesta.total, items: respuesta.data };
  } catch (e) {
    message.error(
      "Ocurrió un problema al obtener los clientes en datos externos"
    );
    return;
  }
};
