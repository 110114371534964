// Track layer utilities
import { store } from "../redux/store";
import { Reservation } from "types/reservation.types";

export const getReservationById = (reservationSid: string) => {
  const { Track } = store.getState();
  return Track.reservations.find(
    (reservation: Reservation) => reservation.sid === reservationSid
  );
};
