const palette = {
  empty: "", // Fixes a bug with styled components that appends a coma
  primary: "#fb5058",
  "primary-light": "#FFEEEE",
  primaryHover: "#f19096",
  secondary: "#272c32",
  secondaryLight: "#323840",
  darkGrey: "#272c32",
  link: "#007aff",

  success: "#45c656",
  success2: "#13c259",
  successHover: "#9cf5a8",

  error: "#ff3247",

  white: [
    "#ffffff", // 0
    "#fafafa", // 1
    "#e2e5e8", // 2
    "#eee", // 3
    "#ffffffde", // 4
    "#f8f8f8", // 5
    "#f5f5f5", // 6
    "#EFF5FC" // 7
  ],
  black: ["#000000", "rgba(0, 0, 0, 0.6)"],
  blue: ["#1890ff", "#3A9AF1", "#EFF5FC"],
  grey: [
    "#808080", // 0
    "#515e6d", // 1
    "#bbbbbb", // 2
    "#d9d9d9", // 3
    "#d0d0d0", // 4
    "#cccccc", // 5
    "#5a5a5a", // 6
    "#333333", // 7
    "#515151", // 8
    "#878787", // 9
    "#505050", // 10
    "#7A7F85", // 11
    "#ced4d9", // 12
    "#ced4d980", // 13
    "#51575D", // 14
    "#f0f3f4", // 15
    "#373f49", // 16
    "#5c6174", // 17
    "#717171", // 18
    "#596178", // 19
    "#9da0ae", // 20
    "#343c4b", // 21
    "#00000065", // 22
    "#9da0ae", // 23
    "#f3f3f3", // 24
    "#c3c3c3", // 25
    "#A8ABB0", // 26
    "#9e9e9e", // 27
    "#e6e7e9", // 28
    "#6F747C", // 29
    "#8C8C8C", // 30
    "#262626", // 31
    "#595959", // 32
    "#A1A1A1", // 33
    "#BEC0C8", // 34
    "#F6F9FC", // 35
    "#5C6174", // 36
    "#373F49", // 37
    "#47505A", // 38
    "#7E8993", // 39
    "#5b6174", // 40
    "#727D89", // 41
    "#e6e8ea", // 42
    "#979797", // 43
    "#55617C", //44
    "#F2F2F2", // 45
    "#E9E9E9", // 46
    "#f1f2f5", // 47
    "#e8e8e8", // 48
    "#5C5C5C", // 49
    "rgba(0, 0, 0, 0.65)", // 50
    "rgba(0, 0, 0, 0.40)", // 51
    "rgba(0, 0, 0, 0.85)", // 52
    "#ADADAD", // 53
    "#D8D8D8", // 54
    "#EBEBED", // 55
    "#DCDCDC", // 56
    "#E1E1E1", // 57
    "rgba(0, 0, 0, 0.38)", //58
    "rgba(0, 0, 0, 0.45)" //59
  ],
  purple: "#58617A",
  red: ["#DE7171", "#FB5058", "#FFCCC7", "#FF3A51"],
  yellow: ["#FFF2F0"],

  topbar: {
    bg: "var(--palette-secondary)"
  },

  sidebar: {
    bg: "#000000",

    item: {
      selected: "#373f49"
    }
  },

  inbox: {
    bg: "var(--palette-secondary)"
  },

  chat: {
    gradient: "linear-gradient(180deg, #f0f2f5 0%, #f8d3dc 100%)"
  },

  crm: {
    primary: "var(--palette-grey-40)"
  },

  settings: {
    bg: "#F0F2F5",
    disabled: "#d9d9d9"
  },

  cards: {
    contact: "#fbc527",
    ticket: "#70d99a",
    comment: "#5f53c9",
    "task-complete": "#fb5058",
    "call-success": "#1890ff",
    "call-failed": "#d8d8d8",
    tag: "#24c6da",
    forms: "var(--palette-white-7)"
  },

  "flow-status": {
    opened: "#52C41A",
    resolved: "#1890FF",
    closed: "#F5222D"
  },

  themes: ["#fb5058", "#f6a23e", "#3c77fe", "#13c2c2", "#fa541c"]
};

const fonts = {
  /* primary: "'Montserrat', sans-serif", */
  settingsFont: "PingFangSC"
};

const sizes = {
  page: {
    minWidth: "100vw",
    maxWidth: "100vw",
    minHeight: "100vh"
  },

  topbar: {
    height: "4rem"
  },

  main: {
    height: "calc(100vh - var(--sizes-topbar-height))"
  },

  sidebar: {
    width: {
      extended: "20rem",
      collapsed: "6.5rem"
    }
  },

  inbox: {
    width: "25rem",

    callReservation: {
      height: "15rem"
    },

    activityWrapper: {
      height: "9.5rem"
    },

    reservation: {
      avatar: {
        hight: "4rem",
        width: "4rem"
      },

      content: {
        width: "10rem"
      },

      gutter: "1rem"
    }
  },

  chat: {
    width: "calc(100% - var(--sizes-inbox-width) - var(--sizes-widget-width))",

    header: {
      collapsed: {
        height: "10rem"
      },
      extended: {
        height: "21rem"
      }
    },
    body: {
      minHeight:
        "calc(100vh - var(--sizes-chat-footer-maxHeight) - var(--sizes-topbar-height))",
      maxHeight:
        "calc(100vh - var(--sizes-chat-footer-minHeight) - var(--sizes-topbar-height))"
    },
    footer: {
      minHeight: "9.1rem",
      maxHeight: "15.5rem"
    }
  },

  widget: {
    width: "56rem",
    height: "33rem",
    drawer: {
      width: "52rem"
    },
    placeholder: {
      height: "33rem"
    },
    preview: {
      height: "8.8rem",
      width: "100%"
    }
  },

  service: {
    aside: {
      width: "32rem"
    },
    issues: {
      header: {
        height: "8.4rem"
      }
    }
  }
};

export default { palette, fonts, sizes };
