import { COLLECTIONS_LIST, CURRENT_CLIENT_TAGS } from "./tag.actions";

const initialState = {};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case COLLECTIONS_LIST:
      return { ...state, collectionsList: action.payload };
    case CURRENT_CLIENT_TAGS:
      return { ...state, currentClientTags: action.payload };
    default:
      return state;
  }
};
