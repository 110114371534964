import {
  ChatState as State,
  ChatActions as Actions,
  MapData
} from "./chat.types";
import { TOGGLE_MAP, SET_MAP } from "./chat.actions";

const initialState: State = {
  map: {
    opened: false,
    latitude: -0.180241,
    longitude: -78.48826,
    zoom: 15,
    address: "",
    label: ""
  }
};

const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TOGGLE_MAP:
      return toggleMap(state, action.payload);
    case SET_MAP:
      return setMap(state, action.payload);
    default:
      return state;
  }
};

const toggleMap = (state: State, opened: boolean | undefined) => {
  const lastState = state.map.opened;
  return updateMapProp(state, "opened", opened ?? !lastState);
};

const setMap = (state: State, options: Partial<MapData>) => {
  const entries = Object.entries(options);
  let newState = state;
  for (const [k, v] of entries) {
    newState = updateMapProp(newState, k, v);
  }
  return newState;
};

const updateMapProp = (state: State, field: string, value: any) => {
  const stateClone = { ...state };
  const map = { ...stateClone.map };
  map[field] = value;
  stateClone.map = map;
  return stateClone;
};

export default reducer;
