// Redux utility functions
import { Pagination, PaginationRes } from "types/common.types";

export const setPage = <T>(
  pagination: Pagination<T>,
  newPage: T extends PaginationRes<T> ? T : PaginationRes<T>
) => {
  const { page, totalPages, items, ...rest } = newPage;
  const newPagination = { ...pagination, ...rest };
  newPagination.page = page;
  newPagination.totalPages = totalPages;
  const newItems = { ...newPagination.items };
  newItems[page] = items;
  return { ...newPagination, items: newItems };
};
