import {
  OperatorsState as State,
  OperatorsActions as Actions,
  SetOperatorIssuesAction,
  SetOperatorSelectedChannelIdAction
} from "./operators.types";
import {
  RESET_OPERATORS,
  RESET_OPERATOR_ORDER_WORKFLOW,
  SET_OPERATOR_SALES_MODULES,
  SET_OPERATOR_SEARCH,
  SET_OPERATOR_SELECTED_CHANNEL_ID,
  SET_OPERATOR_SELECTED_STORE_ID
} from "./operators.actions";
import { ViewTypes } from "types/issues.types";
import { SET_OPERATOR_ORDER_WORKFLOW } from "./operators.actions";
import {
  SetOperatorSelectedCountryIdAction,
  SetOperatorSelectedVendorIdAction
} from "./operators.types";
import {
  SET_OPERATOR_CHANNELS,
  SET_OPERATOR_STORES
} from "./operators.actions";
import {
  SET_OPERATOR_SELECTED_CITY_ID,
  SET_OPERATOR_VENDORS
} from "./operators.actions";
import {
  SET_OPERATOR_CITIES,
  SET_OPERATOR_SELECTED_VENDOR_ID
} from "./operators.actions";
import {
  SET_OPERATOR_PAGE,
  SET_OPERATOR_RANGE_DATES
} from "./operators.actions";
import {
  SET_OPERATOR_SELECTED_COUNTRY_ID,
  SET_OPERATOR_SALES_ISSUES
} from "./operators.actions";
import {
  SET_OPERATOR_SALES_SELECTED_BOX,
  SET_OPERATOR_COUNTRIES
} from "./operators.actions";
import {
  getSelectedModule,
  getSelectedBox,
  insertInIssues,
  insertInBox
} from "./operators.reducer.helpers";
import {
  SET_OPERATOR_SALES_BOXES,
  SET_OPERATOR_SALES_SELECTED_MODULE
} from "./operators.actions";

const initialState: State = {
  modules: null,
  selectedModuleId: "",
  orderBy: "",
  selectedModule: getSelectedModule,
  selectedBoxId: undefined,
  selectedBox: getSelectedBox,
  viewType: ViewTypes.LIST,
  // issuesByCustomer: getInitialPaginationData(),
  issueSteps: undefined,
  countries: undefined,
  selectedCountryId: undefined,
  page: 1,
  cities: undefined,
  vendors: undefined,
  channels: undefined,
  stores: undefined,
  boxes: undefined,
  rangeDates: ["", ""],
  search: undefined,
  selectedVendorId: undefined,
  selectedChannelId: undefined,
  selectedStoreId: undefined,
  selectedCityId: undefined,
  orderWorkflow: undefined
};

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case RESET_OPERATORS:
      return { ...state };
    case SET_OPERATOR_SALES_MODULES:
      return { ...state, modules: action.payload };
    case SET_OPERATOR_SALES_BOXES:
      return { ...state, boxes: action.payload };
    case SET_OPERATOR_SALES_SELECTED_MODULE:
      return { ...state, selectedModuleId: action.payload };
    case SET_OPERATOR_SALES_SELECTED_BOX:
      return { ...state, selectedBoxId: action.payload };
    case SET_OPERATOR_COUNTRIES:
      return { ...state, countries: action.payload };
    case SET_OPERATOR_SELECTED_COUNTRY_ID:
      return setSelectedCountryId(state, action.payload);
    case SET_OPERATOR_SALES_ISSUES:
      return setIssues(state, action.payload);
    case SET_OPERATOR_PAGE:
      return { ...state, page: action.payload };
    case SET_OPERATOR_SEARCH:
      return { ...state, search: action.payload };
    case SET_OPERATOR_RANGE_DATES:
      return { ...state, rangeDates: action.payload };
    case SET_OPERATOR_CITIES:
      return { ...state, cities: action.payload };
    case SET_OPERATOR_SELECTED_VENDOR_ID:
      return setSelectedVendorId(state, action.payload);
    case SET_OPERATOR_SELECTED_CHANNEL_ID:
      return setSelectedChannelId(state, action.payload);
    case SET_OPERATOR_SELECTED_STORE_ID:
      return { ...state, selectedStoreId: action.payload };
    case SET_OPERATOR_SELECTED_CITY_ID:
      return { ...state, selectedCityId: action.payload };
    case SET_OPERATOR_VENDORS:
      return { ...state, vendors: action.payload };
    case SET_OPERATOR_CHANNELS:
      return { ...state, channels: action.payload };
    case SET_OPERATOR_STORES:
      return { ...state, stores: action.payload };
    case SET_OPERATOR_ORDER_WORKFLOW:
      return { ...state, orderWorkflow: action.payload };
    case RESET_OPERATOR_ORDER_WORKFLOW:
      return { ...state, orderWorkflow: action.payload };
    default:
      return state;
  }
};

const setIssues = (
  state: State,
  payload: SetOperatorIssuesAction["payload"]
): State => {
  const { boxId, issues } = payload;
  const { count } = issues;
  const issuesRes = insertInIssues(state, boxId, issues);
  const { issuePages } = issuesRes;
  const { boxes } = insertInBox(state, boxId, { issuePages, count });
  return { ...state, boxes };
};

const setSelectedCountryId = (
  state: State,
  payload: SetOperatorSelectedCountryIdAction["payload"]
): State => {
  return {
    ...state,
    selectedCountryId: payload,
    selectedVendorId: undefined,
    selectedChannelId: undefined,
    selectedStoreId: undefined,
    selectedCityId: undefined
  };
};

const setSelectedVendorId = (
  state: State,
  payload: SetOperatorSelectedVendorIdAction["payload"]
): State => {
  return {
    ...state,
    selectedVendorId: payload,
    selectedChannelId: undefined,
    selectedStoreId: undefined
  };
};

const setSelectedChannelId = (
  state: State,
  payload: SetOperatorSelectedChannelIdAction["payload"]
): State => {
  return {
    ...state,
    selectedChannelId: payload,
    selectedStoreId: undefined
  };
};

export default reducer;
