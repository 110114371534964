// Product and product details types and interfaces
import { Image } from "./image.types";

export interface ProductDetails extends Product {
  isPriceVip: boolean;
  manufacturer: string[];
  maxAmountForSale: number;
  schedule: DayOfWeek[];
  questions: ProductQuestion[];
  amount?: number;
  priceCategory?: ProductCategory;
}

export interface Product {
  productId: number;
  name: string;
  description: string;
  type: ProductType;
  coverUrl: string;
  prices: {
    NORMAL: PriceCategory;
    POINTS: PriceCategory;
  };
  outOfService: boolean;
  outOfStock: boolean;
  stock: number;
  measure: string;
  attributes: ProductAttributes;
  suggestedPrice: string;
  available: boolean;
  images: Image[];
  standardTime: string;
  sponsored: boolean;
  status: "ACTIVE" | "INACTIVE";
}

export interface PriceCategory {
  category: ProductCategory;
  grossPrice: number;
  netPrice: number;
  symbol: string;
  taxes: Tax[];
  discounts: any[];
  discountGrossPrice: number;
  discountNetPrice: number;
  discount: number;
}

export interface Tax {
  percentage: number;
  name: TaxType;
  vatRateCode: number;
  code: number;
  vatRate: string;
}

export interface ProductAttributes {
  Cantidad: string;
  externalId: string;
  showInMenu: boolean;
}

export enum ProductType {
  PRODUCT = "PRODUCT",
  MODIFIER = "MODIFIER"
}
export enum ProductCategory {
  NORMAL = "NORMAL",
  POINTS = "POINTS"
}
export enum TaxType {
  IVA = "IVA"
}

export interface DayOfWeek {
  day: DayOfWeekName;
  from: number;
  to: number;
}

export enum DayOfWeekName {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY"
}

export interface ProductQuestion {
  questionId: string;
  name: string;
  description: string | null;
  min: number;
  max: number;
  type: QuestionTypes | null;
  answers: ProductAnswer[];
}

export enum QuestionTypes {
  BOOLEAN = "BOOLEAN",
  RADIO = "RADIO",
  CHECKBOX = "CHECKBOX",
  QUANTITY = "QUANTITY"
}

type CommonProductAndAnswersProps =
  | "name"
  | "prices"
  | "attributes"
  | "productId"
  | "type"
  | "images";

export interface ProductAnswer
  extends Pick<Product, CommonProductAndAnswersProps> {}
