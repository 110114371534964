// Customer reducer helper functions and data
import { CustomerState } from "./customer.types";
import { Customer } from "types/customer.types";
import { BillingInformation } from "types/billing.types";
import { Address } from "types/address.types";

export const findCustomer = (
  state: CustomerState,
  customerId: string | undefined
): Customer | undefined => {
  if (!customerId) {
    return undefined;
  }
  const paginatedCustomers = Object.values({ ...state.customers.items });
  const customers = paginatedCustomers.reduce<Customer[]>((acc, next) => {
    if (!next) {
      return [...acc];
    }
    return [...acc, ...next];
  }, []);
  const customer = customers.find(customer => customer.uid === customerId);
  if (!customer) {
    return undefined;
  }
  return { ...customer };
};

export const findCustomerPage = (
  state: CustomerState,
  customerId: string | undefined
): { page: string; customers: Customer[] | undefined } | undefined => {
  if (!customerId) {
    return undefined;
  }
  const paginatedCustomers = Object.entries({ ...state.customers.items });
  const pageEntry = paginatedCustomers.find(page => {
    const [, values] = page;
    if (!values) {
      return false;
    }
    return values.find(customer => customer.uid === customerId);
  });
  if (!pageEntry) {
    return undefined;
  }
  const newCustomers = pageEntry[1] ? [...pageEntry[1]] : undefined;
  return { page: pageEntry[0], customers: newCustomers };
};

export const findAddressById = (
  state: CustomerState,
  addressId: string | undefined
): Address | undefined => {
  if (!addressId) {
    return undefined;
  }
  const firstPage = state.addressByCustomer.items[0] ?? [];
  const address = firstPage.find(address => address.id === addressId);
  if (!address) {
    return undefined;
  }
  return { ...address };
};

export const findBillingById = (
  state: CustomerState,
  billingId: string | undefined
): BillingInformation | undefined => {
  if (!billingId) {
    return undefined;
  }
  const firstPage = state.billingInformationByCustomer.items[0] ?? [];
  const billing = firstPage.find(
    billingInformation => billingInformation.id === billingId
  );
  if (!billing) {
    return undefined;
  }
  return { ...billing };
};
