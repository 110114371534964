import Auth from "./auth/auth.reducer";
import Track from "./track/track.reducer";
import Tag from "./tag/tag.reducer";
import Contacts from "./contacts/contacts.reducer";
import Comments from "./comments/comments.reducer";
import Forms from "./forms/forms.reducer";
import Chat from "./chat/chat.reducer";
import Errors from "./errors/errors.reducer";
import ServiceIssues from "./service/issues/issues.reducer";
import SalesIssues from "./sales/issues/issues.reducer";
import Widgets from "./widgets/widgets.reducer";
import Booking from "./booking/booking.reducer";
import Customer from "./customer/customer.reducer";
import Location from "./location/location.reducer";
import Sales from "./sales/sales/sales.reducer";
import Supervisor from "./supervisor/supervisor.reducer";
import SalesOperator from "./sales/operators/operators.reducer";

export default {
  Auth,
  Track,
  Tag,
  Contacts,
  Comments,
  Forms,
  Chat,
  Errors,
  ServiceIssues,
  SalesIssues,
  SalesOperator,
  Widgets,
  Booking,
  Customer,
  Location,
  Sales,
  Supervisor
};
