import CONSTANTS from "../config/constants";
import { MEDIA_CHANNELS_ID } from "types/reservation.types";

type Return = {
  mediaChannelId: MEDIA_CHANNELS_ID | undefined;
  from: string | undefined;
};

export const getChannelType = (channelType: any) => {
  if (!channelType) {
    return {
      mediaChannelId: undefined,
      from: undefined
    };
  }
  const channel = channelType.split(":");
  let type = MEDIA_CHANNELS_ID.PHONE;
  let numberId = channelType;
  switch (channel[0]) {
    case CONSTANTS.WHATSAPP:
      type = MEDIA_CHANNELS_ID.WHATSAPP_ID;
      break;
    case CONSTANTS.MESSENGER:
      type = MEDIA_CHANNELS_ID.FACEBOOK_ID;
      break;
    case CONSTANTS.SIP: {
      type = MEDIA_CHANNELS_ID.PHONE;
      const sip = channel[1].split("@");
      numberId = sip[0];
      break;
    }
    default:
      break;
  }
  const data = {
    mediaChannelId: type,
    from: numberId
  };
  return data;
};
