import { OperatorsState } from "./operators.types";
import { Module, Box, Order } from "types/orders.types";
import { PaginationPage } from "../issues/issues.types";
import { Pagination } from "types/common.types";

export const getSelectedModule = (
  state: OperatorsState
): Module | undefined => {
  return state.modules?.find(module => {
    return module.id === state.selectedModuleId;
  });
};

export const getSelectedBox = (state: OperatorsState): Box | undefined => {
  const boxes = state.boxes;
  return boxes?.find(box => {
    return box.id === state.selectedBoxId;
  });
};

// find and return a module
export const getModule = (
  state: OperatorsState,
  moduleId: string
): { module: Module; moduleIndex: number; modules: Module[] } => {
  if (!state.modules) {
    throw new Error(
      `Modules should be initialized before attempting to save a box`
    );
  }
  const index = state.modules?.findIndex(module => module.id === moduleId);
  if (index === -1) {
    throw new Error(`Module id ${moduleId} does not exist on Modules`);
  }
  const modules = [...state.modules];
  const newModule = { ...modules[index] };
  return { module: newModule, moduleIndex: index, modules };
};

// find and return a box
export const getBox = (
  state: OperatorsState,
  boxId: string
): {
  box: Box;
  boxIndex: number;
  boxes: Box[];
} => {
  const moduleBoxes = state.boxes;
  const index = moduleBoxes?.findIndex(box => box.id === boxId);
  if (typeof index === "undefined" || index === -1 || !module) {
    throw new Error(`Box id ${boxId} does not exist on Boxes`);
  }
  if (!moduleBoxes) {
    throw new Error("To get a box you must initialize boxes first");
  }
  const boxes = [...moduleBoxes];
  const newBox = { ...boxes[index] };
  return { box: newBox, boxIndex: index, boxes };
};

// find and return a issue page
export const getOrdersPage = (
  state: OperatorsState,
  boxId: string,
  page: number
): {
  issuePage: PaginationPage<Order>;
  issuePages: Pagination<Order>;
  box: Box;
  boxIndex: number;
  boxes: Box[];
} => {
  const getBoxRes = getBox(state, boxId);
  const box = getBoxRes.box;
  const pages = Object.entries(box.issuePages.items);
  const oldPage = pages.find(issuePage => {
    return issuePage[0] === page.toString();
  });
  const newItems = oldPage ? oldPage[1] : undefined;
  const fullPage: PaginationPage<Order> = {
    items: newItems,
    page,
    totalPages: box.issuePages.totalPages,
    count: box.count
  };
  return {
    issuePage: fullPage,
    issuePages: box.issuePages,
    ...getBoxRes
  };
};

export const insertInModule = (
  state: OperatorsState,
  moduleId: string,
  payload: Partial<Module>
): { modules: Module[]; module: Module; moduleIndex: number } => {
  const { module, moduleIndex, modules } = getModule(state, moduleId);
  const newModule = { ...module, ...payload };
  const newModules = [...modules!];
  newModules[moduleIndex] = newModule;
  return { modules: newModules, module: newModule, moduleIndex };
};

export const insertInBox = (
  state: OperatorsState,
  boxId: string,
  payload: Partial<Box>
): { boxes: Box[]; box: Box; boxIndex: number } => {
  const { box, boxIndex, boxes } = getBox(state, boxId);
  const newBox = { ...box, ...payload };
  const newBoxes = [...boxes];
  newBoxes[boxIndex] = newBox;
  return { boxes: newBoxes, box: newBox, boxIndex };
};

export const insertInIssues = (
  state: OperatorsState,
  boxId: string,
  issues: PaginationPage<Order>
): { issuePages: Pagination<Order> } => {
  const { page, totalPages, items } = issues;
  const { issuePages } = getOrdersPage(state, boxId, page);
  const newIssuePages = { ...issuePages };
  newIssuePages.totalPages = totalPages;
  newIssuePages.page = page;
  const newItems = { ...newIssuePages.items };
  newItems[page] = items;
  newIssuePages.items = newItems;
  return { issuePages: newIssuePages };
};
