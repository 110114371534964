import axios from "axios";
import { message } from "antd";

/**
 * Post Pacific forms
 * @param uri
 */
export const sendFormSolicitudTitular = async (uri: string, values: any) => {
  let response: any = "";
  const celhomePhone =
    values.homePhone2 !== undefined
      ? values.homePhone2.substr(values.homePhone2.length - 8)
      : "00000000";
  const celWorkPhone =
    values.workPhone2 !== undefined
      ? values.workPhone2.substr(values.workPhone2.length - 8)
      : "00000000";
  const totalExpensesSum =
    Number(values.billExpenses) +
    Number(values.foodExpenses) +
    Number(values.rentMortageExpenses) +
    Number(values.transportExpenses) +
    Number(values.clothsExpenses) +
    Number(values.educationExpenses) +
    Number(values.healthExpenses);
  try {
    await axios
      .post(
        `${process.env.REACT_APP_INTEGRATION_PACIFICO}${uri}`,
        {
          ci: values.cedula,
          requestNumber: values.requestNumber,
          fathersLastName: values.fathersLastName,
          mothersLastName: values.mothersLastName,
          firstName: values.firstName,
          middleName: values.middleName,
          dob: values.dob,
          gender: values.gender,
          maritalStatus: values.maritalStatus,
          familyBurdens: values.familyBurdens,
          nameOnCard: values.nameOnCard,
          studyLevel: values.studyLevel,
          profession: values.profession,
          correspondencePlace: values.correspondencePlace,
          homeCity: values.homeCity,
          homeDistrict: values.homeDistrict,
          homeAddress: values.homeAddress,
          areaCodeHomePhone1: values.areaCodeHomePhone1,
          homePhone1: values.homePhone1,
          areaCodeHomePhone2: values.areaCodeHomePhone2,
          homePhone2: celhomePhone,
          homeBuildingType: values.homeBuildingType,
          homeValue: values.homeValue,
          homeAgeYears: values.homeAgeYears,
          homeAgeMonths: values.homeAgeMonths,
          dependanceRelationship: values.dependanceRelationship,
          incomeSource: values.incomeSource,
          workCity: values.workCity,
          workDistrict: values.workDistrict,
          workAddress: values.workAddress,
          areaCodeWorkPhone1: values.areaCodeWorkPhone1,
          workPhone1: values.workPhone1,
          areaCodeWorkPhone2: values.areaCodeWorkPhone2,
          workPhone2: celWorkPhone,
          workPhone1extension: values.workPhone1extension,
          workName: values.workName,
          workActivity: values.workActivity,
          workPosition: values.workPosition,
          antiquityAtWorkYears: values.antiquityAtWorkYears,
          antiquityAtWorkMonths: values.antiquityAtWorkMonths,
          salaryInDolars: values.salaryInDolars,
          billExpenses: values.billExpenses,
          foodExpenses: values.foodExpenses,
          rentMortageExpenses: values.rentMortageExpenses,
          transportExpenses: values.transportExpenses,
          clothsExpenses: values.clothsExpenses,
          educationExpenses: values.educationExpenses,
          healthExpenses: values.healthExpenses,
          totalExpenses: totalExpensesSum ? totalExpensesSum : 0,
          wealth: values.wealth,
          relativeName: values.relativeName,
          realativeRelationship: values.realativeRelationship,
          realativeHomeCity: values.realativeHomeCity,
          relativeHomeAddress: values.relativeHomeAddress,
          areaCodeRelativePhone1: values.areaCodeRelativePhone1,
          relativePhone1: values.relativePhone1,
          email: values.email,
          additionalObservations: values.additionalObservations,
          courierAgendaObservations: values.courierAgendaObservations,
          zoneHomeAddress: values.zoneHomeAddress,
          zoneWorkAddress: values.zoneWorkAddress,
          creditLimit: values.creditLimit,
          cardBrand: values.cardBrand,
          cardType: values.cardType,
          cardResolution: values.cardResolution,
          milesAnswer: values.milesAnswer,
          resolutionReason: values.resolutionReason
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Access-Control-Allow-Headers":
              "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
          }
        }
      )
      .then(responseData => {
        response = responseData.status;
        message.success("El Formulario se ha enviado correctamente");
      });
  } catch (e) {
    message.error("El Formulario no pudo ser enviado");
  }
  return response;
};

/**
 * Post Pacific forms
 * @param uri
 */
export const sendFormPagoRecurrente = async (uri: string, values: any) => {
  let response: any = "";
  try {
    await axios
      .post(
        `${process.env.REACT_APP_INTEGRATION_PACIFICO}${uri}`,
        {
          ci: values.cedula,
          requestNumber: values.requestNumber,
          recurrentPaymentCode: values.recurrentPaymentCode,
          ciServiceOwner: values.ciServiceOwner,
          fatherLastName: values.fatherLastName,
          motherLastName: values.motherLastName,
          firstName: values.firstName,
          middleName: values.middleName,
          contractNumber: values.contractNumber
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Access-Control-Allow-Headers":
              "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
          }
        }
      )
      .then(responseData => {
        response = responseData.status;
        message.success("El Formulario se ha enviado correctamente");
      });
  } catch (e) {
    message.error("El Formulario no pudo ser enviado");
  }
  return response;
};

/**
 * Post Pacific forms
 * @param uri
 */
export const sendFormAdicionales = async (uri: string, values: any) => {
  let response: any = "";
  const celhomePhone =
    values.homePhone2 !== undefined
      ? values.homePhone2.substr(values.homePhone2.length - 8)
      : "00000000";
  try {
    await axios
      .post(
        `${process.env.REACT_APP_INTEGRATION_PACIFICO}${uri}`,
        {
          ci: values.cedula,
          requestNumber: values.requestNumber,
          ciAddicional: values.ciAddicional,
          fathersLastName: values.fathersLastNameAdditional,
          mothersLastName: values.mothersLastNameAdditional,
          firstName: values.firstNameAdditional,
          middleName: values.middleNameAdditional,
          dob: values.dob,
          gender: values.gender,
          maritalStatus: values.maritalStatus,
          nameOnCard: values.nameOnCard,
          profession: values.profession,
          areaCodeHomePhone1: values.areaCodeHomePhone1,
          homePhone1: values.homePhone1,
          areaCodeHomePhone2: values.areaCodeHomePhone2,
          homePhone2: celhomePhone,
          homeCity: values.homeCity,
          homeAddress: values.homeAddress,
          realativeRelationship: values.realativeRelationship,
          creditLimit: values.creditLimitAssigned
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Access-Control-Allow-Headers":
              "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
          }
        }
      )
      .then(responseData => {
        response = responseData.status;
        message.success("El Formulario se ha enviado correctamente");
      });
  } catch (e) {
    message.error("El Formulario no pudo ser enviado");
  }
  return response;
};

/**
 * Post Pacific forms
 * @param uri
 */
export const getCustomerAnswers = async (ci: string) => {
  let response: any = "";
  try {
    await axios
      .get(
        `${process.env.REACT_APP_INTEGRATION_PACIFICO}/api/v1/data/customer/answer?filter={"ci": "${ci}"}&sort={"created_at"}`
      )
      .then(responseData => {
        response = responseData;
      });
  } catch (e) {
    message.error(" No se pudo obtener datos de cliente");
  }
  return response;
};
