import ApolloClient from "apollo-boost";
import gql from "graphql-tag";

import { message } from "antd";
import { getTitle } from "../../containers/operator/InboxContainer/InboxContainer.helpers";
import {
  setActiveReservation,
  updateStaticReservation
} from "../track/track.actions";
import { getCrmTags } from "../../services/tags/tags";
import { Contact } from "types/reservation.types";
import { ThunkDispatch, AppState } from "redux/store.types";

export const SET_ACTIVE_CONTACT_CARDS_HISTORY =
  "SET_ACTIVE_CONTACT_CARDS_HISTORY";
export const SET_ACTIVE_CONTACT = "SET_ACTIVE_CONTACT";
export const SET_SEARCHED_CONTACT = "SET_SEARCHED_CONTACT";

/**
 * Set graphql contanct
 */
const CRMContact = new ApolloClient({
  uri: process.env.REACT_APP_CRM_GRAPHQL
});

const ContactHistory = new ApolloClient({
  uri: process.env.REACT_APP_HISTORY
});

const refreshContactData = (type: any, uniqueName: any) => {
  return gql`{
    searchClients(property:"${type}",value:"${uniqueName}")
  {
    id
    name
    lastname
    contactName
    email{
      key
      value
    }
    document
    phone{
      key
      value
    }
    facebookId
    whatsappId
    twitterId
    address{
      key
      value
    }
    birthday
    age
  }
  }`;
};

export const createContact = (
  contact: any,
  uniqueName: any,
  type: any
) => async (dispatch: any, getState: any): Promise<Contact | false> => {
  const USERS_MUTATION = gql`mutation {
      createClient(input:${contact})
    {
      id
      name
      lastname
      contactName
      email {key, value}
      document
      phone {key, value}
      facebookId
      whatsappId
      twitterId
      address {key, value}
      birthday
      age
    }
  }`;

  const GLOBAL_USER_QUERY = refreshContactData(type, uniqueName);
  try {
    const res = await CRMContact.mutate({
      mutation: USERS_MUTATION,
      refetchQueries: [{ query: GLOBAL_USER_QUERY }],
      variables: { channel: type, uniqueName }
    });
    if (res.data && res.data.createClient) {
      const { createClient } = res.data;
      const contact = { ...createClient, tags: [] };

      const { Track } = getState();
      const reservation = Track.activeReservation();
      const title = getTitle(contact, reservation.channel.mediaChannel);
      const updatedReservation = {
        ...reservation,
        contact: contact,
        title
      };
      dispatch(
        updateStaticReservation({ ...reservation, title }, "contact", contact)
      );
      dispatch(setActiveReservation(updatedReservation.sid));
      message.success("Contacto creado exitosamente");
      return contact;
    } else {
      dispatch({ type: SET_ACTIVE_CONTACT, payload: null });
      message.error("Hubo un error al crear el contacto");
      return false;
    }
  } catch (e) {
    console.log(e.message);
    message.error("Error desconocido");
    return false;
  }
};

export const clearCurrentContact = () => (dispatch: any) => {
  dispatch({ type: SET_ACTIVE_CONTACT, payload: null });
};

/**
 * Get contact with exact match in crm
 * @param field
 * @param value
 */
export const fetchContactUniqueData = (field: string, value: string) => {
  const USERS_QUERY = gql`{
      searchClient(property:"${field}",value:"${value}")
      {
        id
        name
        lastname
        contactName
        email{
          key
          value
        }
        document
        phone{
          key
          value
        }
        facebookId
        whatsappId
        twitterId
        address{
          key
          value
        }
        birthday
        age
      }
    }`;
  return CRMContact.query({
    query: USERS_QUERY,
    fetchPolicy: "no-cache"
  })
    .then(res => {
      return res.data.searchClient;
    })
    .catch(err => {
      message.error("Falló la busqueda del contacto en el CRM");
    });
};

/**
 * get contact info
 * @param {*} data
 */
export const fetchContactData = (field: string, value: string) => {
  const USERS_QUERY = gql`{
      searchClients(property:"${field}",value:"${value}")
      {
        id
        name
        lastname
        contactName
        email{
          key
          value
        }
        document
        phone{
          key
          value
        }
        facebookId
        whatsappId
        twitterId
        address{
          key
          value
        }
        birthday
        age
      }
    }`;
  return CRMContact.query({
    query: USERS_QUERY,
    fetchPolicy: "no-cache"
  })
    .then(res => {
      if (res.data.searchClients.length > 0) {
        return res.data.searchClients[0];
      }
      return null;
    })
    .catch(err => {
      message.error("Falló la busqueda del contacto en el CRM");
    });
};

export const updateContactData = (
  id: string,
  data: any,
  type: string,
  uniqueName: string,
  messages?: any
) => (dispatch: any, getState: any) => {
  const { Track } = getState();

  const USERS_MUTATION = gql`mutation {
      updateClient(id:"${id}",client:${data})
      {
        id
        name
        lastname
        contactName
        email{
          key
          value
        }
        document
        phone{
          key
          value
        }
        facebookId
        whatsappId
        twitterId
        address{
          key
          value
        }
        birthday
        age
      }
  }`;

  return CRMContact.mutate({
    mutation: USERS_MUTATION,
    // refetchQueries: [{ query: GLOBAL_USER_SQUERY }],
    variables: { channel: type, uniqueName },
    fetchPolicy: "no-cache"
  })
    .then(async res => {
      if (res.data && res.data.updateClient) {
        const tags = await getCrmTags(id);
        const contact = { ...res.data.updateClient, tags: tags };
        const reservation = Track.activeReservation();
        const currentMessages = reservation.channel.messages.reverse();
        const title = getTitle(contact, reservation.channel.mediaChannel);

        //Update list reservations
        const updateChannel = { ...reservation.channel };
        updateChannel.messages = [...currentMessages]
          .concat([...messages])
          .reverse();

        const newReservation = { ...reservation, channel: updateChannel };
        dispatch(
          updateStaticReservation(
            { ...newReservation, title },
            "contact",
            contact
          )
        );

        // Update active reservation
        dispatch(setActiveReservation(newReservation.sid));
      }
      return true;
    })
    .catch(error => {
      console.log(error.message);
      message.error("Error desconocido");
      return false;
    });
};

/**
 * get current client cards history
 * @param {*} contactId
 * @param {*} data
 */
export const fetchContactCardsHistory = (contactId: any, data: any) => {
  let filter = "";
  if (data.searchType) {
    filter = `,searchType:"${data.searchType}",searchValue:${data.searchValue}`;
  }
  //TODO: cambiar contactId en back
  const USERS_QUERY = gql`{
        cards(contactId: "${contactId}",page:${data.page},limit:${data.limit}${filter}){
          data{
            id,
            icon,
            dateCreated,
            from,
            title,
            link,
            type,
            color,
            attributesCard
          }
        }
      }`;

  return (dispatch: any) => {
    ContactHistory.query({
      query: USERS_QUERY,
      fetchPolicy: "no-cache"
    })
      .then(res => {
        if (res.data && res.data.cards) {
          dispatch({
            type: SET_ACTIVE_CONTACT_CARDS_HISTORY,
            payload: res.data.cards.data
          });
        } else {
          dispatch({ type: SET_ACTIVE_CONTACT_CARDS_HISTORY, payload: [] });
        }
      })
      .catch(err => {
        dispatch({ type: SET_ACTIVE_CONTACT_CARDS_HISTORY, payload: [] });
      });
  };
};

/**
 * Update client's data with graphql
 * @param {*} data
 */
export const fetchContactMessagesHistory = (
  contactId: string,
  page: number,
  limit = 1
) => {
  if (!contactId) {
    return Promise.reject([]);
  }
  const CHAT_HISTORY = gql`query{
      messages(contactId:"${contactId}",page:${page},limit:${limit})
    {
      metadata{currentPage,nextPage},
      data{id,from, body, media, type, mediaUrl, attributes, dateCreated, typeChannel, messageSid}
    }
  }`;

  return ContactHistory.query({
    query: CHAT_HISTORY,
    fetchPolicy: "no-cache"
  })
    .then(res => {
      if (
        res.data &&
        res.data.messages &&
        res.data.messages.data &&
        res.data.messages.data.length
      ) {
        const historyMessages = res.data.messages.data;
        const list = historyMessages.map((message: any) => {
          const ms = { ...message };
          const att = ms.attributes;
          if (att && att.length) {
            ms.attributes = JSON.parse(att);
          }
          return ms;
        });

        return list;
      } else {
        return [];
      }
    })
    .catch(err => {
      return [];
    });
};

export const setSearchedContact = (contact: Contact | undefined) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SEARCHED_CONTACT, payload: contact });
};
