// Stores services
import { Store } from "types/sales/stores.types";
import { genStores } from "util/testUtils/builders/sales/stores.builder";
import { PaginationRes } from "types/common.types";
import CONSTANTS from "config/constants";
import { ShippingCost } from "types/sales/shippingCost.types";
import { axiosXmart } from "util/axios";
import { getAuthorization } from "redux/auth/auth.actions";
import { message } from "antd";

const {
  STORE,
  PLATFORM,
  CLIENT_VERSION,
  SHIPPING_COST
} = CONSTANTS.SALES_ISSUES;

const headers = {
  Platform: PLATFORM,
  "client-version": CLIENT_VERSION
};

export const fetchStores = async (
  page: number | undefined,
  accountId: number,
  channelId: number[],
  latitude: number,
  longitude: number,
  vendorId: number[]
): Promise<PaginationRes<Store>> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${STORE}/find?account=${accountId}&channel=${channelId}&latitude=${latitude}&longitude=${longitude}&page=${page}`;
    const result = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data } = result.data;
    return { page: page ?? 1, totalPages: 1, items: data };
  } catch (e) {
    throw new Error(
      `Failed to fetch stores of channelId ${channelId} at coordinates (${latitude},${longitude}).`
    );
  }
};

export const fetchStoresMocked = (
  page: number,
  accountId: number,
  latitude: number,
  longitude: number
): Promise<PaginationRes<Store>> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const stores = genStores(80);
      const storesPage = { page, totalPages: 20, items: stores };
      resolve(storesPage);
    }, 1000);
  });
};

export const fetchShippingCost = async (
  storeId: number,
  channelId: number,
  lat: number,
  lng: number
): Promise<ShippingCost> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${SHIPPING_COST}?stores=${storeId}&lat=${lat}&lng=${lng}&channelId=${channelId}`;
    const result = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    throw new Error(`Failed to fetch shipping cost from store ${storeId}.`);
  }
};

export const fetchStoresByVendorOrChannel = async (
  vendorId: number | undefined,
  channelId: number | undefined
) => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/storesByVendor`;
    const result = await axiosXmart.get(url, {
      params: {
        status: "ACTIVE",
        channelId,
        vendorId
      },
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    message.error(`No se pudo cargar la lista de tiendas`);
  }
};
