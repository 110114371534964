import { ErrorsState as State, ErrorsActions as Actions } from "./errors.types";
import {
  TOGGLE_CALL_ERROR,
  TOGGLE_TASK_ROUTER_ERROR,
  TOGGLE_CHAT_ERROR,
  TOGGLE_NO_MIC_ERROR,
  TOGGLE_NO_MIC_WARNING,
  TOGGLE_NO_MIC_PERMISSION_ERROR,
  TOGGLE_CALL_WARNING
} from "./errors.actions";

const initialState: State = {
  callError: false,
  noMicError: false,
  noMicWarning: false,
  noMicPermissionError: false,
  taskRouterError: false,
  chatError: false,
  callWarning: false
};

const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TOGGLE_CALL_ERROR:
      return toggleState(state, "callError", action.payload);
    case TOGGLE_TASK_ROUTER_ERROR:
      return toggleState(state, "taskRouterError", action.payload);
    case TOGGLE_CHAT_ERROR:
      return toggleState(state, "chatError", action.payload);
    case TOGGLE_NO_MIC_ERROR:
      return toggleState(state, "noMicError", action.payload);
    case TOGGLE_NO_MIC_WARNING:
      return toggleState(state, "noMicWarning", action.payload);
    case TOGGLE_NO_MIC_PERMISSION_ERROR:
      return toggleState(state, "noMicPermissionError", action.payload);
    case TOGGLE_CALL_WARNING:
      return toggleState(state, "callWarning", action.payload);
    default:
      return state;
  }
};

const toggleState = (state: State, field: string, payload?: boolean) => {
  return { ...state, [field]: toggle(state[field], payload) };
};

const toggle = (previous: boolean, force?: boolean) => {
  return force ?? !previous;
};

export default reducer;
