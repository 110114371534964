// Module and boxes entity builders
import faker from "faker";

import { Box, Module, ISSUE_TYPES } from "types/issues.types";
import { genId, chooseRandom, genNumber } from "../common.builder";

const fixedModules = {
  tickets: { type: ISSUE_TYPES.TICKET, weight: 0 },
  incidentes: { type: ISSUE_TYPES.ISSUE, weight: 1 },
  actividades: { type: ISSUE_TYPES.ISSUE, weight: 2 },
  leads: { type: ISSUE_TYPES.ISSUE, weight: 3 },
  bases: { type: ISSUE_TYPES.ISSUE, weight: 4 },
  agendamientos: { type: ISSUE_TYPES.BOOKING, weight: 5 },
  ordenes: { type: ISSUE_TYPES.ORDERS, weight: 6 }
};

const genBoxTitles = () => {
  return ticketBoxTitles.map(() => faker.company.companyName());
};

const ticketBoxTitles = [
  "Mis tickets",
  "Todos los tickets",
  "Asignados a mi grupo",
  "Urgente",
  "Actualizados recientemente",
  "Sin asignar"
];

const bookingBoxTitles = [
  "Agendadas",
  "En Proceso",
  "Asistidas",
  "No Asistidas",
  "Canceladas"
];

export const buildBox = (overrides: Partial<Box> = {}): Box => {
  const unread = genNumber(20);
  return {
    id: genId(),
    name: faker.company.companyName(),
    count: genNumber({ min: unread, max: 80 }),
    issuePages: {},
    ...overrides
  };
};

const mapIssueTypeToTitleOptions = (moduleType: ISSUE_TYPES) => {
  switch (moduleType) {
    case ISSUE_TYPES.ISSUE:
      return genBoxTitles();
    case ISSUE_TYPES.TICKET:
      return ticketBoxTitles;
    case ISSUE_TYPES.INCIDENT:
      return genBoxTitles();
    case ISSUE_TYPES.ACTIVITIES:
      return genBoxTitles();
    case ISSUE_TYPES.LEADS:
      return genBoxTitles();
    case ISSUE_TYPES.DATABASES:
      return genBoxTitles();
    case ISSUE_TYPES.BOOKING:
      return bookingBoxTitles;
    default:
      throw new Error(`Module type ${moduleType} is not supported`);
  }
};

export const genBoxes = (
  quantity?: number,
  moduleType: ISSUE_TYPES = ISSUE_TYPES.ISSUE
): Box[] => {
  const num = quantity ?? Math.floor(Math.random() * 4) + 1;
  let titleOptions = mapIssueTypeToTitleOptions(moduleType);
  const Boxes = [];
  for (let i = 0; i < num; i++) {
    const name = chooseRandom(titleOptions);
    const Box = buildBox({ name });
    titleOptions = titleOptions.filter(option => {
      return option !== name;
    });
    Boxes.push(Box);
  }
  return Boxes;
};

export const buildModule = (overrides: Partial<Module> = {}): Module => {
  const [title, props] = chooseRandom(Object.entries(fixedModules));
  return {
    id: genId(),
    title,
    weight: props.weight,
    type: props.type,
    ...overrides
  };
};

export const genModules = (quantity?: number): Module[] => {
  const num = quantity ?? Math.floor(Math.random() * 9) + 1;
  let options = Object.entries(fixedModules);
  const Modules = [];
  for (let i = 0; i < num; i++) {
    if (!options.length) {
      break;
    }
    const [title, props] = chooseRandom(options);
    const Module = buildModule({ title, ...props });
    options = options.filter(option => option[0] !== title);
    Modules.push(Module);
  }
  return Modules;
};
