// Vendors services

import { genVendors } from "util/testUtils/builders/sales/vendor.builder";
import { Vendor } from "types/sales/vendor.types";
import CONSTANTS from "config/constants";
import { getAuthorization } from "redux/auth/auth.actions";
import { axiosXmart } from "util/axios";
import { PaginationRes } from "types/common.types";
import { message } from "antd";

const { SALES_ISSUES } = CONSTANTS;
const { PLATFORM, CLIENT_VERSION } = SALES_ISSUES;
const { VENDORS, CITIES } = SALES_ISSUES;

const headers = {
  Platform: PLATFORM,
  "client-version": CLIENT_VERSION
};

export const fetchVendors = (
  channelId: number,
  pageNumber: number
): Promise<PaginationRes<Vendor>> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const vendors = genVendors(3);
      resolve({
        page: pageNumber,
        totalPages: 1,
        items: vendors
      });
    }, 3000);
  });
};

export const fetchVendorByChannel = async (channelId: number): Promise<any> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${VENDORS}?channelId=${channelId}`;
    const result: any = await axiosXmart.get(url, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    //TODO: Add types to result
    console.log(result);
    return {};
  } catch (e) {
    throw new Error("Failed to fetch vendors by channels");
  }
};

export const fetchVendorByCountryAndAccount = async (
  accountId: number,
  countryId: number
) => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${CITIES}${countryId}/vendors`;
    const result = await axiosXmart.get(url, {
      params: {
        account: accountId
      },
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    message.error(`No se pudo cargar la lista de vendors`);
  }
};
