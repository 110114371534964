// X-ONE Workspace builder
import faker from "faker";

import { SalesProcessTypes, Process } from "types/workspace.types";
import { Workspace, Config } from "types/workspace.types";
import { SalesProcess } from "types/workspace.types";
import { TrackConfig, ServiceConfig, SalesConfig } from "types/workspace.types";
import { genId } from "./common.builder";
import { genBiasBoolean, chooseRandomEnum } from "./common.builder";
import { buildAccount } from "./sales/account.builder";
import { genVendors } from "./sales/vendor.builder";
import { genChannels } from "./sales/channel.builder";

export const buildTrackConfig = (
  overrides: Partial<TrackConfig> = {}
): TrackConfig => {
  return {
    available: genBiasBoolean(0.5),
    ...overrides
  };
};

export const buildServiceConfig = (
  overrides: Partial<ServiceConfig> = {}
): ServiceConfig => {
  return {
    available: genBiasBoolean(0.5),
    ...overrides
  };
};

export const buildProcess = (overrides: Partial<Process> = {}): Process => {
  return {
    prerequisites: [],
    transitions: {
      next: [faker.commerce.product(), faker.commerce.product()],
      prev: [faker.commerce.product()]
    },
    ...overrides
  };
};

export const genProcesses = (quantity?: number): SalesProcess["processes"] => {
  const num = quantity ?? Math.floor(Math.random() * 9) + 1;

  const processes: SalesProcess["processes"] = {};
  for (let i = 0; i < num; i++) {
    const process = buildProcess();
    const processName = faker.commerce.productName();
    processes[processName] = process;
  }
  return processes;
};

export const buildSalesProcess = (
  overrides: Partial<SalesProcess> = {}
): SalesProcess => {
  return {
    type: chooseRandomEnum(SalesProcessTypes),
    active: genBiasBoolean(0.8),
    processes: genProcesses(),
    additionalInfo: {
      channels: genChannels(),
      vendors: genVendors()
    },
    ...overrides
  };
};

export const genSalesProcesses = (quantity?: number): SalesProcess[] => {
  const num = quantity ?? Math.floor(Math.random() * 3) + 1;

  const salesProcesses = [];
  for (let i = 0; i < num; i++) {
    const salesProcess = buildSalesProcess();
    salesProcesses.push(salesProcess);
  }
  return salesProcesses;
};

export const buildSalesConfig = (
  overrides: Partial<SalesConfig> = {}
): SalesConfig => {
  const vendorIds = genVendors().map(vendor => {
    return vendor.id;
  });
  return {
    available: genBiasBoolean(0.5),
    account: buildAccount(),
    vendorIds,
    salesProcesses: genSalesProcesses(),
    ...overrides
  };
};

export const buildConfig = (overrides: Partial<Config> = {}): Config => {
  return {
    track: buildTrackConfig(),
    service: buildServiceConfig(),
    sales: buildSalesConfig(),
    ...overrides
  };
};

export const buildWorkspace = (
  overrides: Partial<Workspace> = {}
): Workspace => {
  return {
    id: genId(),
    friendlyName: faker.company.companyName(),
    config: buildConfig(),
    ...overrides
  };
};
