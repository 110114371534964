import firebase from "firebase/app";
import { message } from "antd";

import { store } from "../redux/store";
import { logIn, logOut } from "../redux/auth/auth.actions";
import { push } from "connected-react-router";
import CONSTANTS from "../config/constants";
import { firebaseSales } from "config/firebase";

export const checkAuthStatus = (url?: string) => {
  handleSessionExpiration();
  try {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        store.dispatch(logIn(null, url));
      } else {
        store.dispatch(push(CONSTANTS.NOT_AUTHORIZED_ENTRY_PATH));
      }
    });
  } catch {
    message.error("Coundn't Authenticate");
  }
};

const handleSessionExpiration = () => {
  //Signout to expire session token on firebase at 12pm every day, then set expiracy token for the next day
  const token = process.env.REACT_APP_CURRENT_SESSION_EXPIRACY_TOKEN as string;
  const expiracyDateStr = localStorage.getItem(token);

  if (expiracyDateStr) {
    const expiracyDate = new Date(expiracyDateStr);
    const expired = expiracyDate < new Date();
    if (expired) {
      store.dispatch(logOut());
    }
  }
  const expiration = new Date().setHours(24, 0, 0, 0);
  localStorage.setItem(token, JSON.stringify(expiration));
};

export const xmartSignin = async () => {
  const email = process.env.REACT_APP_FIREBASE_USER as string;
  const password = process.env.REACT_APP_FIREBASE_PASSWORD as string;
  await firebaseSales
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch(function(error) {
      console.log(error.code, error.message);
      message.error("Falló la conección con el carrito");
      throw new Error(error);
    });
};
