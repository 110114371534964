import { sortReservations, getActiveReservation } from "./helpers";
import { Reservation } from "types/reservation.types";
import {
  SET_RESERVATIONS,
  ADD_RESERVATION,
  REMOVE_RESERVATION,
  UPDATE_RESERVATION,
  UPDATE_STATIC_RESERVATION,
  SET_ACTIVE_RESERVATION,
  WORKER_ACTIVITIES,
  SET_CALL_STATUS,
  SET_SCHEDULED_TASK,
  SET_SCHEDULED_TASK_AVAILABLE,
  SET_LOADING_RESERVATIONS,
  TICK_RINGING_TIMER,
  RESET_RINGING_TIMER,
  SET_TRANSFERING,
  ADD_CHANNEL,
  REMOVE_CHANNEL,
  SET_CHECK_CALL_RESERVATION
} from "./track.actions";

const initialState = {
  activeReservationSid: null,
  activeReservation: getActiveReservation,
  reservations: [],
  // Channels sid already listening
  channelListeners: [],
  // Count of reservations that arrived but still are fetching more data
  loadingReservations: 0,
  // Selected scheduled task
  scheduledTask: null,
  // Whether a scheduled task is available to be reserved
  scheduledTaskAvailable: false,
  call: {
    // Inbound call not answered yet
    callingIn: false,
    // Outbound call not answered yet
    callingOut: false,
    // During any answered call
    inCall: false,
    // After a call is closed and has not been completed
    wrapping: false,
    // Any additinal info
    options: {}
  },
  tranfering: false,
  workerActivities: [],
  ringingTime: 0,
  checkCallReservation: false
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SET_RESERVATIONS:
      return { ...state, reservations: action.payload };
    case ADD_RESERVATION:
      return addReservation(state, action.payload);
    case REMOVE_RESERVATION:
      return removeReservation(state, action.payload);
    case UPDATE_RESERVATION: {
      const { reservation, field, value } = action.payload;
      return updateReservation(state, reservation, field, value);
    }
    case UPDATE_STATIC_RESERVATION: {
      const { reservation, field, value } = action.payload;
      return updateStaticReservation(state, reservation, field, value);
    }
    case SET_ACTIVE_RESERVATION:
      return { ...state, activeReservationSid: action.payload };
    case WORKER_ACTIVITIES:
      return { ...state, workerActivities: action.payload };
    case SET_CALL_STATUS:
      return { ...state, call: action.payload };
    case SET_SCHEDULED_TASK:
      return { ...state, scheduledTask: action.payload };
    case SET_SCHEDULED_TASK_AVAILABLE:
      return { ...state, scheduledTaskAvailable: action.payload };
    case SET_LOADING_RESERVATIONS:
      return { ...state, loadingReservations: action.payload };
    case TICK_RINGING_TIMER:
      return { ...state, ringingTime: state.ringingTime + 1 };
    case RESET_RINGING_TIMER:
      return { ...state, ringingTime: 0 };
    case SET_TRANSFERING:
      return { ...state, transfering: action.payload };
    case SET_CHECK_CALL_RESERVATION:
      return { ...state, callReservationCheck: action.payload };
    case ADD_CHANNEL:
      return {
        ...state,
        channelListeners: [...state.channelListeners, action.payload]
      };
    case REMOVE_CHANNEL:
      return removeChannel(state, action.payload);
    default:
      return state;
  }
};

const removeChannel = (state: any, channelSid: string) => {
  const channelListeners = state.channelListeners.filter(
    (sid: string) => sid !== channelSid
  );
  return { ...state, channelListeners };
};

// filter a reservation from the reservations list
const removeReservation = (state: any, reservationSid: string) => {
  // if activeReservation, clear it
  const filteredReservations = state.reservations.filter(
    (reservation: Reservation) => {
      return reservation.sid !== reservationSid;
    }
  );
  return { ...state, reservations: filteredReservations };
};

// this method will always append a reservation to the top of the list following the sorting order.
// NOTE: this method will first filter any duplicated reservation
const addReservation = (state: any, reservation: Reservation) => {
  // if activeReservation, update it
  const filteredReservations = removeReservation(state, reservation.sid)
    .reservations;
  const reservations = sortReservations([reservation, ...filteredReservations]);
  return {
    ...state,
    reservations
  };
};

const updateReservation = (
  state: any,
  reservation: Reservation,
  field: string,
  value: any
) => {
  const newReservation = { ...reservation, [field]: value };
  const reservations = addReservation(state, newReservation).reservations;
  return {
    ...state,
    reservations
  };
};

// This function will update a reservation field without changing the list order
// ******  DO NOT use this method unless strictly necessary ************
const updateStaticReservation = (
  state: any,
  reservation: Reservation,
  field: string,
  value: any
) => {
  const newReservation = { ...reservation, [field]: value };
  // if activeReservation, update it
  const reservations = state.reservations.map((item: any) => {
    if (item.sid === reservation.sid) {
      return newReservation;
    }
    return item;
  });

  return { ...state, reservations };
};
