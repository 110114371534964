import {
  BookingState as State,
  BookingActions as Actions
} from "./booking.types";

const initialState: State = {
  customersData: null
};

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
