// Customer types and interfaces
import { AddressCountry, Address } from "./address.types";
import { BillingInformation } from "./billing.types";

export interface Customer {
  uid: string;
  name: string;
  lastname: string;
  displayName: string;
  phone: Phone;
  email: string;
  emailType: EmailType;
  documents: DocumentObject;
  country: AddressCountry;
  reasonDisabled: string;
  /*   billings: BillingInformation[];
  selectedBillingId: string | undefined;
  addresses: Address[];
  selectedAddressId: string | undefined;
  selectedAddress?: Address; */
}

export interface ExternalCustomer {
  name: string;
  lastname: string;
  displayName: string;
  phone: Phone;
  email: string;
  emailType: EmailType;
  documents: DocumentObject;
  country: AddressCountry;
  reasonDisabled: string;
  billingData: BillingInformation[];
  shippingAddress: Address[];
  /*   billings: BillingInformation[];
  selectedBillingId: string | undefined;
  addresses: Address[];
  selectedAddressId: string | undefined;
  selectedAddress?: Address; */
}

export interface NewCustomer {
  uid: string;
  document: string;
  documentType: DocumentType;
  name: string;
  lastname: string;
  displayName: string;
  phone: Phone;
  email: string;
  emailType: EmailType;
  country: AddressCountry;
}

export interface DocumentObject {
  [key: string]: {
    document: string;
    documentType: DocumentType;
  };
}

export interface Phone {
  countryCode: string;
  countryIsoCode: string;
  number: string;
  type: string;
  verified?: boolean;
}

export enum DocumentType {
  PASSPORT = "PASSPORT",
  //ECUADOR
  CI = "CI",
  RUC = "RUC",
  //Colombia
  CI_CO = "CI_CO",
  NIT = "NIT",
  CE = "CE",
  //Chile
  RUT = "RUT"
}

export enum PhoneType {
  Movil = "Móvil",
  Casa = "Casa",
  Oficina = "Oficina",
  Otro = "Otro"
}

export enum EmailType {
  Personal = "Personal",
  Secundario = "Secundario",
  Oficina = "Oficina",
  Otro = "Otro"
}
