// Location reducer helper functions and data
import { LocationState } from "./location.types";
import { Country, City, Areas } from "types/location.types";

export const findCountryById = (
  state: LocationState,
  countryId: number | undefined
): Country | undefined => {
  const country = Object.values(state.countries).find(
    country => country.id === countryId
  );
  if (!country) {
    return undefined;
  }
  return { ...country };
};

export const findCityById = (
  state: LocationState,
  countryId: number,
  cityId: number
): City | undefined => {
  if (!countryId || !cityId) {
    return undefined;
  }
  const { id } = findCountryById(state, countryId) ?? {};
  if (!id) {
    throw new Error(
      `Country of countryId ${countryId} was not found. You should first set the country before trying to set its cities.`
    );
  }
  const cityPage = state.cities[id] ?? [];
  const city = cityPage.find(city => city.id === cityId);
  if (!city) {
    return undefined;
  }
  return { ...city };
};

export const findAreaById = (
  state: LocationState,
  countryId: number,
  cityId: number,
  areaId: number
): Areas | undefined => {
  const { id: selectedCountryId } = findCountryById(state, countryId) ?? {};
  const { id: selectedCityId } = findCityById(state, countryId, cityId) ?? {};
  if (!selectedCityId) {
    throw new Error(
      `City of cityId ${cityId} was not found. You should first set the country before trying to set its areas.`
    );
  }
  const area = Object.values(
    state.areasByCity[selectedCityId][selectedCountryId!]
  ).find(area => area.id === areaId);
  if (!area) {
    return undefined;
  }
  return { ...area };
};

export const resetLocationState: LocationState = {
  countries: {},
  selectedCountry: findCountryById,
  selectedCountryId: undefined,
  cities: {},
  selectedCity: findCityById,
  selectedCityId: undefined,
  areasByCity: {},
  livingPlaces: {},
  selectedArea: findAreaById,
  selectedAreaId: undefined
};
