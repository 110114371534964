// Supervisor services
import axiosDefault from "../../util/axios";
import { message } from "antd";

import { getAuthorization } from "../../redux/auth/auth.actions";
import { Workspace } from "../../types/workspace.types";

/**
 * Fetch calls by direction
 */
export const fetchCallsByDirection = async () => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get(
      `/api/twilio/report/callsByDirection`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    return data;
  } catch (e) {
    message.error(
      "Fallo en conseguir las llamadas por tipo, inténtelo nuevamente."
    );
  }
};

/**
 * Fetch calls by status
 */
export const fetchCallsByStatus = async () => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get(
      `/api/twilio/report/callsByStatus`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    return data;
  } catch (e) {
    message.error(
      "Fallo en conseguir el estado de llamadas por tipo, inténtelo nuevamente."
    );
  }
};

/**
 * Fetch chats by status
 */
export const fetchChatsByStatus = async () => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get(
      `/api/twilio/report/chatsByStatus`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    return data;
  } catch (e) {
    message.error(
      "Fallo en conseguir el estado de mensajes por tipo, inténtelo nuevamente."
    );
  }
};

export const fetchSummaryTotalsTask = async () => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get(
      `/api/twilio/report/summaryTotalsTask`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    return data;
  } catch (e) {
    message.error(
      "Fallo en conseguir el total de resumen de tareas, inténtelo nuevamente."
    );
  }
};

export const fetchCountTasks = async (
  workspace: Workspace
): Promise<Worker[] | undefined> => {
  if (!workspace) {
    return [];
  }
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get(
      `/api/task_router2/taskChannel/countTasks/workspace/${workspace.id}?channels=tickets,activities`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    return data;
  } catch (e) {
    message.error("Falló la búsqueda de tareas");
  }
};

export const fetchTasksByCategories = async (
  payloadDate: any,
  type: string
): Promise<Worker[] | undefined> => {
  if (!type) {
    return [];
  }
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get(
      `/api/task/classifier/tasksByCategories?from=${payloadDate.from}&to=${payloadDate.to}&type=${type}`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    return data;
  } catch (e) {
    message.error("Falló la búsqueda de tareas por categorias");
  }
};
