import {
  SET_ACTIVE_CONTACT_CARDS_HISTORY,
  SET_ACTIVE_CONTACT,
  SET_SEARCHED_CONTACT
} from "./contacts.actions";

const initialState = {
  activeContact: null,
  activeContactCardsHistory: null,
  searchedContact: undefined
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ACTIVE_CONTACT_CARDS_HISTORY:
      return { ...state, activeContactCardsHistory: action.payload };
    case SET_ACTIVE_CONTACT:
      return { ...state, activeContact: action.payload };
    case SET_SEARCHED_CONTACT:
      return { ...state, searchedContact: action.payload };
    default:
      return state;
  }
};
