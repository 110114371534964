import {
  fetchContactUniqueData,
  fetchContactMessagesHistory
} from "../../../redux/contacts/contacts.actions";
import { getChannelType } from "../../../util/mapper";
import { fetchMessagesByChannel } from "../../../services/track/track";
import { getCrmTags } from "../../../services/tags/tags";
import { getNumberChannel } from "../../../util/contact";
import CONSTANTS from "../../../config/constants";
import {
  getHistoricalId,
  getMediaChannelId
} from "../../../util/taskRouter.utils";
import { store } from "redux/store";
import { decreaseLoadingReservations } from "redux/track/track.actions";
import {
  Reservation,
  Channel,
  MEDIA_CHANNELS_ID
} from "types/reservation.types";

export const initReservation = async (
  reservationData: any
): Promise<Reservation> => {
  const reservation = { ...reservationData };
  const task = { ...reservation.task };
  const parsedAttributes = task.attributes;
  const channelTypeData = getChannelType(parsedAttributes.from);
  const { mediaChannelId } = channelTypeData;
  let { from } = channelTypeData;
  if (!mediaChannelId || !from) {
    throw new Error(
      "Couldn't get mediaChannelId and/or from properties from channel"
    );
  }
  const messages = await setupMessages(parsedAttributes.channelSid);
  const channel: Channel = {
    sid: parsedAttributes.channelSid,
    mediaChannel: parsedAttributes.mediaChannel,
    mediaChannelId,
    from,
    messages
  };
  if (mediaChannelId === MEDIA_CHANNELS_ID.PHONE) {
    const countryCode = CONSTANTS.COUNTRY_CODE;
    from = `${countryCode}${parsedAttributes.callerPhone}`;
  }
  reservation.contact = await fetchContactUniqueData(mediaChannelId, from);
  reservation.reservationStatus = !reservation.reservationStatus
    ? reservation.reservation_status
    : reservation.reservationStatus;
  reservation.unread = getNumberUnread(messages);
  reservation.task = task;
  reservation.task.attributes = parsedAttributes;
  reservation.channel = channel;
  reservation.title = reservation.contact
    ? getTitle(reservation.contact, channel.mediaChannel)
    : getNumberChannel(getMediaChannelId(reservation));

  const historicalId = getHistoricalId(reservation);
  if (!historicalId) {
    throw new Error("Couldn't find historicalId");
  }
  let messageHistorical = await getMessagesHistoryByContact(
    historicalId,
    1,
    CONSTANTS.CHAT.PAGINATION.LIMIT
  );
  messageHistorical = messageHistorical.reverse();
  reservation.channel.messages = messageHistorical.concat(messages);

  if (reservation.contact) {
    reservation.contact.tags = await getCrmTags(reservation.contact.id);
  }
  store.dispatch(decreaseLoadingReservations());
  return reservation;
};

export const getTitle = (contact: any, mediaChannel: string) => {
  if (!mediaChannel) {
    return "Problema";
  }
  let title = mediaChannel;
  if (contact) {
    if (contact.name && contact.lastname) {
      title = `${contact.name} ${contact.lastname}`;
    } else if (contact.name) {
      title = contact.name;
    } else if (contact.lastname) {
      title = contact.lastName;
    }
  }
  return title.trim();
};

const setupMessages = async (channelSid: string) => {
  let messages = [];
  try {
    messages = await fetchMessagesByChannel(channelSid);
    messages = messages.map((message: any) => {
      message.attributes = JSON.parse(message.attributes);
      return message;
    });
    // newCurrentTask.pageMessages = 0;
    messages = messages.reverse();
    // this.getMessagesHistory(false);
    return messages;
  } catch (e) {
    console.log(e.message);
  }
};

/**
 * Get messages from ms historical by contact id
 * @param activeContactId
 * @param numberPage
 * @param numberLimit
 */
const getMessagesHistoryByContact = async (
  activeContactId: string,
  numberPage: number,
  numberLimit: number
) => {
  const messages = await fetchContactMessagesHistory(
    activeContactId,
    numberPage,
    numberLimit
  );
  return messages;
};

const getNumberUnread = (messages: []) => {
  let count = 0;
  const worker = store.getState().Auth.worker;
  messages.forEach((message: any) => {
    const att = message.attributes;
    const notRead = !att.read;
    const notWorker = message.from !== worker.sid;
    if (notRead && notWorker) {
      count++;
    }
  });
  return count;
};
