import CONSTANTS from "../config/constants";

export const getChannelType = (channelType: any) => {
  const channel = channelType.split(":");
  let type = "phone";
  let numberId = channelType;

  switch (channel[0]) {
    case CONSTANTS.WHATSAPP:
      type = "whatsappId";
      break;
    case CONSTANTS.MESSENGER:
      type = "facebookId";
      break;
    case CONSTANTS.SIP: {
      type = "phone";
      const sip = channel[1].split("@");
      numberId = sip[0];
      break;
    }
    default:
      break;
  }
  const data = {
    channel: type,
    uniqueName: numberId
  };
  return data;
};

export const getNumberChannel = (channelType: string | undefined) => {
  if (!channelType) {
    return "";
  }
  const channel = channelType.split(":");
  let title = "";

  switch (channel[0]) {
    case CONSTANTS.WHATSAPP:
      title = formatNumber(channel[1]);
      break;
    case CONSTANTS.MESSENGER:
      title = `ID: #${channel[1]}`;
      break;
    case CONSTANTS.SIP: {
      const sip = channel[1].split("@");
      title = sip[0];
      break;
    }
    default:
      title = formatNumber(channelType);
      break;
  }

  return title;
};

export const formatNumber = (numberPhone: any) => {
  const phone = numberPhone.replace("+", "");
  const digits = `${phone.trim()}`.split("");
  let title = "";
  digits.forEach((num: string, index: number) => {
    if (num === "+") {
      return;
    }
    const digit = parseInt(num, 10);
    const left = digits.length - (index + 1);
    if (index % 3 === 0 && index !== 0 && left > 1) {
      title += ` ${digit}`;
      return;
    }
    title += digit;
  });
  title = `+${title}`;
  const letters = title.split("");
  const countryCode = CONSTANTS.COUNTRY_CODE;
  letters[1] = letters[1] === "0" ? `${countryCode} ` : letters[1];
  title = letters.join("");
  return title;
};
