import firebase from "firebase/app";

export const xone = firebase.initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`
});

export const firebaseSales = firebase.initializeApp(
  {
    projectId: process.env.REACT_APP_FIREBASE_SALES_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_SALES_API_KEY,
    authDomain: `${process.env.REACT_APP_FIREBASE_SALES_PROJECT_ID}.firebaseapp.com`
  },
  "sales"
);
