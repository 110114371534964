import { AppState, ThunkDispatch } from "redux/store.types";

import { fetchCategoriesByStore } from "services/sales/product/product.service";
import { fetchProductsByCategory } from "services/sales/product/product.service";
import { fetchRecommendedProduct } from "services/sales/product/product.service";
import { fetchProductDetails } from "services/sales/product/product.service";
import { fetchPaymentInscription } from "services/sales/orders/orders.service";
import { fetchStores } from "services/sales/stores/stores.service";
import { Account } from "types/sales/Account.types";
import { ShoppingCart } from "types/sales/shoppingCart.types";
import { fetchPaymentMethods } from "services/sales/orders/orders.service";
import { fetchOrder } from "services/sales/orders/orders.service";
import { fetchShippingCost } from "../../../services/sales/stores/stores.service";
import { SalesProcess } from "types/workspace.types";
import { Channel } from "types/sales/channel.types";
import { Vendor } from "types/sales/vendor.types";
import { message } from "antd";

export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_CHANNELS = "SET_CHANNELS";
export const SET_SELECTED_CHANNEL_ID = "SET_SELECTED_CHANNEL_ID";
export const SET_VENDORS = "SET_VENDORS";
export const SET_SELECTED_VENDOR_ID = "SET_SELECTED_VENDOR_ID";
export const SET_SELECTED_STORE_ID = "SET_SELECTED_STORE_ID";
export const SET_STORES = "SET_STORES";
export const SET_SELECTED_STORE = "SET_SELECTED_STORE";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_SELECTED_CATEGORY_ID = "SET_SELECTED_CATEGORY_ID";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_SELECTED_PRODUCT_ID = "SET_SELECTED_PRODUCT_ID";
export const SET_PRODUCT_DETAILS = "SET_PRODUCT_DETAILS";
export const SET_RECOMMENDED_PRODUCT = "SET_RECOMMENDED_PRODUCT";
export const RESET_VENDORS = "RESET_VENDORS";
export const RESET_STORES = "RESET_STORES";
export const SET_SHOPPING_CART = "SET_SHOPPING_CART";
export const RESET_SHOPPING_CART = "RESET_SHOPPING_CART";
export const RESET_SALES = "RESET_SALES";
export const SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS";
export const SET_SELECTED_PAYMENT_METHOD_ID = "SET_SELECTED_PAYMENT_METHOD_ID";
export const SET_SELECTED_CART_ITEM_ID = "SET_SELECTED_CART_ITEM_ID";
export const SET_LAST_ORDER = "SET_LAST_ORDER";
export const SET_SHIPPING_COST = "SET_SHIPPING_COST";
export const RESET_SALES_AFTER_ORDER = "RESET_SALES_AFTER_ORDER";
export const RESET_SALES_INIT_ORDER = "RESET_SALES_INIT_ORDER";
export const SET_SALES_PROCESS = "SET_SALES_PROCESS";
export const SET_PAYMENT_INSCRIPTIONS = "SET_PAYMENT_INSCRIPTIONS";

export const setAccount = (account: Account) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_ACCOUNT, payload: account });
};

export const setChannels = (channels: Channel[]) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_CHANNELS, payload: channels });
};

export const setSelectedChannelId = (channelId: number | undefined) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SELECTED_CHANNEL_ID, payload: channelId });
};

export const setStores = (
  page: number | undefined,
  accountId: number,
  latitude: number,
  longitude: number
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const channels = getState().Sales.channels;
  const vendors = getState().Sales.vendors;
  const channelsId: number[] | undefined = channels
    ? Object.values(channels).map(channel => {
        return channel.id;
      })
    : undefined;

  const vendorsId: number[] | undefined = vendors
    ? Object.values(vendors).map(vendor => {
        return vendor.id;
      })
    : undefined;

  if (!channelsId || !vendorsId) {
    return;
  }
  try {
    const stores = await fetchStores(
      page,
      accountId,
      channelsId,
      latitude,
      longitude,
      vendorsId
    );
    dispatch({ type: SET_STORES, payload: stores });
  } catch (error) {
    message.info("Ocurrió un error al consultar las tiendas disponibles");
  }
};

export const setSelectedStoreId = (
  storeId: number | string | undefined
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  dispatch({ type: SET_SELECTED_STORE_ID, payload: storeId });
};

export const setCategories = (
  page: number,
  accountId: number,
  channelId: number,
  storeId: number
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const categories = await fetchCategoriesByStore(
    page,
    accountId,
    storeId,
    channelId
  );
  if (!categories) {
    return false;
  }
  dispatch({ type: SET_CATEGORIES, payload: categories });
  return true;
};

export const setSelectedCategoryId = (categoryId: number) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SELECTED_CATEGORY_ID, payload: categoryId });
};

export const setProducts = (
  page: number,
  accountId: number,
  channelId: number,
  storeId: number,
  categoryId: number
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const products = await fetchProductsByCategory(
    page,
    storeId,
    channelId,
    categoryId
  );
  if (!products) {
    return false;
  }
  dispatch({
    type: SET_PRODUCTS,
    payload: { channelId, categoryId, storeId, products }
  });
  return true;
};

export const setProductDetail = (
  accountId: number,
  channelId: number,
  storeId: number,
  productId: number
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const productDetails = await fetchProductDetails(
    accountId,
    channelId,
    storeId,
    productId
  );
  dispatch({
    type: SET_PRODUCT_DETAILS,
    payload: productDetails
  });
};

export const setSelectedProducId = (productId: number) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SELECTED_PRODUCT_ID, payload: productId });
};

export const setVendors = (vendors: Vendor[]) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({
    type: SET_VENDORS,
    payload: vendors
  });
};

export const setSelectedVendorId = (vendorId: number | undefined) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SELECTED_VENDOR_ID, payload: vendorId });
};

export const resetVendors = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: RESET_VENDORS });
};

export const resetStores = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: RESET_STORES });
};

export const resetSales = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  return dispatch({ type: RESET_SALES });
};

export const resetSalesAfterOrder = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: RESET_SALES_AFTER_ORDER });
};

export const resetSalesInitOrder = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: RESET_SALES_INIT_ORDER });
};

export const setShoppingCart = (shoppingCart: ShoppingCart) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SHOPPING_CART, payload: shoppingCart });
};

export const resetShoppingCart = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: RESET_SHOPPING_CART });
};

export const setRecommendedProduct = () => async (dispatch: ThunkDispatch) => {
  const recommendedProduct = await fetchRecommendedProduct();
  dispatch({
    type: SET_RECOMMENDED_PRODUCT,
    payload: recommendedProduct
  });
};

export const setPaymentMethods = (
  vendorId: number,
  channelId: number
) => async (dispatch: ThunkDispatch) => {
  const paymentMethods = await fetchPaymentMethods(vendorId, channelId);
  if (!paymentMethods) {
    return;
  }
  dispatch({ type: SET_PAYMENT_METHODS, payload: paymentMethods });
};

export const setSelectedPaymentMethodId = (paymentMethodId: number) => async (
  dispatch: ThunkDispatch
) => {
  dispatch({ type: SET_SELECTED_PAYMENT_METHOD_ID, payload: paymentMethodId });
};

export const setSelectedCartItemId = (productId: string | undefined) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SELECTED_CART_ITEM_ID, payload: productId });
};

export const setLastOrder = (orderId: number | undefined) => async (
  dispatch: ThunkDispatch
) => {
  if (!orderId) {
    dispatch({ type: SET_LAST_ORDER, payload: undefined });
    return;
  }
  const order = await fetchOrder(orderId);
  if (!order) {
    return;
  }
  dispatch({ type: SET_LAST_ORDER, payload: order });
  return order;
};

export const setShippingCost = (
  storeId: number,
  channelId: number,
  lng: number,
  lat: number
) => async (dispatch: ThunkDispatch) => {
  const shippingCost = await fetchShippingCost(storeId, channelId, lng, lat);
  dispatch({ type: SET_SHIPPING_COST, payload: shippingCost });
};

export const setSalesProcess = (salesProcess: SalesProcess) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SALES_PROCESS, payload: salesProcess });
};

export const setPaymentInscriptions = (
  paymentMethodId: number,
  vendorId: number,
  storeId: number,
  custumerId: string
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const channels = await fetchPaymentInscription(
    paymentMethodId,
    vendorId,
    storeId,
    custumerId
  );
  dispatch({ type: SET_PAYMENT_INSCRIPTIONS, payload: channels });
};
