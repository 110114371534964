// Address builder functions
import { Address, AddressZone, Field } from "types/address.types";
import { AddressLivingPlace } from "types/address.types";
import {
  genId,
  genMobilPhone,
  chooseRandomEnum,
  genNumericId
} from "../common.builder";
import { genBiasBoolean, genWords, genStreetAddress } from "../common.builder";
import { genNumber, chooseRandom, genWord } from "../common.builder";
import { BaseLocationField } from "types/location.types";
import { Phone, PhoneType } from "types/customer.types";
import CONSTANTS from "config/constants";
import { AddressOrder } from "types/orders.types";

export const buildAddress = (
  overrides: Partial<AddressOrder> = {}
): AddressOrder => {
  const withReference = genBiasBoolean(0.6);
  const reference = withReference ? genWords(5) : undefined;

  const withDeliveryInstructions = genBiasBoolean(0.6);
  const deliveryInstructions = withDeliveryInstructions
    ? genWords(5)
    : undefined;

  return {
    id: genNumericId().toString(),
    uid: genId(),
    address: genStreetAddress(),
    addressByGoogle: genStreetAddress(),
    reference,
    country: buildBaseLocation(),
    sector: buildBaseLocation(),
    zone: buildZone(),
    city: buildBaseLocation(),
    livingPlace: buildLivingPlace(),
    phone: buildAddressPhone(),
    deliveryInstructions,
    validated: genBiasBoolean(0.1),
    lat: genNumber({ min: -79, max: 0 }),
    lng: genNumber({ min: -79, max: 0 }),
    default: true,
    mainStreet: genWords(3),
    secondaryStreet: genWords(2),
    number: "N4553",
    ...overrides
  };
};

export const buildLivingPlace = (
  overrides: Partial<AddressLivingPlace> = {}
): AddressLivingPlace => {
  const withFields = genBiasBoolean(0.2);
  const fields = withFields ? genFields() : undefined;
  return {
    id: genNumericId(),
    name: genWord(),
    idInt: genNumber(999),
    fields,
    ...overrides
  };
};

export const buildField = (overrides: Partial<Field> = {}): Field => {
  return {
    id: genNumericId().toString(),
    value: genWord(),
    ...overrides
  };
};

export const genFields = (quantity?: number): Field[] => {
  const num = quantity ?? Math.floor(Math.random() * 1) + 1;

  const Fields = [];
  for (let i = 0; i < num; i++) {
    const Field = buildField();
    Fields.push(Field);
  }
  return Fields;
};

export const buildAddressPhone = (overrides: Partial<Phone> = {}): Phone => {
  return {
    countryCode: chooseRandom(["56", "57", "593"]),
    countryIsoCode: genWord(),
    number: genMobilPhone(CONSTANTS.COUNTRY_CODE),
    type: chooseRandomEnum(PhoneType),
    verified: genBiasBoolean(0.1),
    ...overrides
  };
};

export const buildBaseLocation = (
  overrides: Partial<BaseLocationField> = {}
): BaseLocationField => {
  return {
    id: genNumericId(),
    name: genWord(),
    ...overrides
  };
};

export const buildZone = (
  overrides: Partial<AddressZone> = {}
): AddressZone => {
  return {
    id: genNumericId(),
    name: genWord(),
    zipCode: genWord(),
    ...overrides
  };
};

export const genAddresses = (quantity?: number): Address[] => {
  const num = quantity ?? Math.floor(Math.random() * 3);

  const addresses = [];
  for (let i = 0; i < num; i++) {
    const addresse = buildAddress();
    addresses.push(addresse);
  }
  const defaultAddress = chooseRandom(addresses);
  if (defaultAddress) {
    defaultAddress.default = true;
  }
  return addresses;
};
