import { AppState, ThunkDispatch } from "redux/store.types";
import { fetchCitiesByCountry } from "services/location/location.service";
import { fetchLivingPlaces } from "services/location/location.service";
import { fetchAreasByCity } from "services/location/location.service";
import { fetchCountries } from "services/location/location.service";

export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_CITIES = "SET_CITIES";
export const SET_AREAS_BY_CITY = "SET_AREAS_BY_CITY";
export const SET_LOCATION_ZONE = "SET_LOCATION_ZONE";
export const SET_LIVING_PLACES = "SET_LIVING_PLACES";
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY";
export const SET_SELECTED_CITY = "SET_SELECTED_CITY";
export const SET_SELECTED_AREA = "SET_SELECTED_AREA ";
export const RESET_LOCATION = "RESET_LOCATION";

export const resetLocation = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: RESET_LOCATION });
};

export const setCountries = () => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const countries = await fetchCountries();
  if (!countries) {
    return;
  }
  dispatch({ type: SET_COUNTRIES, payload: countries });
};

export const setCities = (countryId: number) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const citiesByCountry = await fetchCitiesByCountry(countryId);
  if (!citiesByCountry) {
    return;
  }
  dispatch({
    type: SET_CITIES,
    payload: { countryId, cities: citiesByCountry }
  });
};

export const setAreasByCity = (countryId: number, cityId: number) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const areasByCity = await fetchAreasByCity(cityId);
  if (!areasByCity) {
    return;
  }
  dispatch({
    type: SET_AREAS_BY_CITY,
    payload: { countryId, cityId, areas: areasByCity }
  });
};

export const setLivingPlaces = (countryId: number) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const livingPlaces = await fetchLivingPlaces(countryId);
  if (!livingPlaces) {
    return;
  }
  dispatch({ type: SET_LIVING_PLACES, payload: { countryId, livingPlaces } });
};

export const setSelectedCountryId = (countryId: number) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SELECTED_COUNTRY, payload: countryId });
};

export const setSelectedCityId = (cityId: number) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SELECTED_CITY, payload: cityId });
};

export const setSelectedAreaId = (areaId: number) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SELECTED_AREA, payload: areaId });
};
