// Customer builder functions
import { genNumericId } from "../common.builder";
import { chooseRandomEnum } from "../common.builder";
import { chooseRandom } from "../common.builder";
import { genId } from "../common.builder";
import { genEmail, genWord } from "../common.builder";
import { genNumber, genDocument } from "../common.builder";
import { genName, genLastName } from "../common.builder";
import { EmailType, NewCustomer } from "types/customer.types";
import { Customer, DocumentObject } from "types/customer.types";
import { Country, City } from "types/location.types";
import { DocumentType } from "types/customer.types";
import { buildAddressPhone } from "../address/address.builder";
import { buildBaseLocation } from "../address/address.builder";
import { BillingInformationReq, BillingInformation } from "types/billing.types";

export const buildCountry = (overrides: Partial<Country> = {}): Country => {
  return {
    id: genNumericId(),
    name: genWord(),
    code: genWord(),
    ...overrides
  };
};

export const buildCity = (overrides: Partial<City> = {}): City => {
  return {
    id: genNumericId(),
    name: genWord(),
    country_code: genWord(),
    country_name: genWord(),
    country_id: genNumericId(),
    latitude: genNumber(1),
    longitude: genNumber(80),
    ...overrides
  };
};

export const buildBillingInformation = (
  overrides: Partial<BillingInformation> = {}
): BillingInformation => {
  return {
    id: genId(),
    idInt: genNumber(999),
    document: genDocument(10),
    documentType: chooseRandomEnum(DocumentType),
    name: genName() + genLastName(),
    email: genEmail(),
    phone: buildAddressPhone(),
    country: buildBaseLocation(),
    address: genWord(),
    ...overrides
  };
};

export const buildBillingRequest = (
  overrides: Partial<BillingInformationReq> = {}
): BillingInformationReq => {
  return {
    uid: genId(),
    id: genId(),
    idInt: genNumber(999),
    name: genName() + genLastName(),
    document: genDocument(10),
    documentType: chooseRandomEnum(DocumentType),
    email: genEmail(),
    phone: buildAddressPhone(),
    country: buildCountry(),
    address: genWord(),
    ...overrides
  };
};

export const genBillingRequests = (
  quantity?: number
): BillingInformationReq[] => {
  const num = quantity ?? Math.floor(Math.random() * 3) + 1;

  const BillingRequests = [];
  for (let i = 0; i < num; i++) {
    const BillingRequest = buildBillingRequest();
    BillingRequests.push(BillingRequest);
  }
  return BillingRequests;
};

export const genBillingInformation = (
  quantity?: number,
  userData?: Partial<BillingInformation>
): BillingInformation[] => {
  const num = quantity ?? Math.floor(Math.random() * 3);

  const billings = [];
  for (let i = 0; i < num; i++) {
    let billing = buildBillingInformation();
    if (i === 0) {
      billing = buildBillingInformation(userData);
    }
    billings.push(billing);
  }
  const defaultAddress = chooseRandom(billings);
  if (defaultAddress) {
    defaultAddress.default = true;
  }
  return billings;
};

export const buildCustomer = (overrides: Partial<Customer> = {}): Customer => {
  const baseData = {
    name: genName(),
    lastname: genLastName()
  };

  return {
    uid: genId(),
    name: baseData.name,
    lastname: baseData.lastname,
    phone: buildAddressPhone(),
    country: buildBaseLocation(),
    displayName: baseData.name + baseData.lastname,
    emailType: chooseRandomEnum(EmailType),
    email: genEmail(),
    documents: buildDocumentsObject(),
    reasonDisabled: "",
    ...overrides
  };
};

export const buildNewCustomer = (
  overrides: Partial<NewCustomer> = {}
): NewCustomer => {
  const baseData = {
    name: genName(),
    lastname: genLastName()
  };

  return {
    uid: genId(),
    name: baseData.name,
    lastname: baseData.lastname,
    phone: buildAddressPhone(),
    country: buildBaseLocation(),
    displayName: baseData.name + baseData.lastname,
    emailType: chooseRandomEnum(EmailType),
    email: genEmail(),
    document: genDocument(10),
    documentType: chooseRandomEnum(DocumentType),
    ...overrides
  };
};

export const buildDocumentsObject = (
  overrides: Partial<DocumentObject> = {}
): DocumentObject => {
  return {
    "doc-1": {
      document: genDocument(10),
      documentType: chooseRandomEnum(DocumentType),
      ...overrides
    }
  };
};

export const genCustomersMocked = (quantity?: number): Customer[] => {
  const num = quantity ?? Math.floor(Math.random() * 9) + 1;

  const customers = [];
  for (let i = 0; i < num; i++) {
    const customer = buildCustomer();
    customers.push(customer);
  }
  return customers;
};
