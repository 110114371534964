// Channel builders
import faker from "faker";

import { Channel } from "types/sales/channel.types";
import { genNumericId, chooseRandom } from "../common.builder";

const channelOptions = ["A domicilio", "Pick-up"];

export const buildChannel = (overrides: Partial<Channel> = {}): Channel => {
  return {
    id: genNumericId(),
    name: faker.company.bsAdjective(),
    friendlyName: faker.company.bsAdjective(),
    ...overrides
  };
};

export const genChannels = (quantity?: number): Channel[] => {
  const num = quantity ?? Math.floor(Math.random() * 9) + 1;
  let options = [...channelOptions];
  const Channels = [];
  for (let i = 0; i < num; i++) {
    const overrides: Partial<Channel> = {};
    const option = chooseRandom(options);
    if (option) {
      overrides.name = option;
    }
    const Channel = buildChannel(overrides);
    Channels.push(Channel);
    options = options.filter(channel => {
      return channel !== option;
    });
  }
  return Channels;
};
