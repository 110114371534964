import { AppState, ThunkDispatch } from "redux/store.types";
import {
  fetchModules,
  fetchOperatorSalesIssues
} from "services/sales/orders/orders.service";
import { fetchModulesMocked } from "../../../services/sales/operators/operators.service";
import { orderPagination } from "../issues/issues.reducer.helpers";
import { fetchCitiesByCountry } from "services/location/location.service";
import { fetchChannelByVendor } from "../../../services/sales/channel/channel.service";
import { fetchVendorByCountryAndAccount } from "services/sales/vendors/vendors.service";
import { fetchStoresByVendorOrChannel } from "../../../services/sales/stores/stores.service";
import { moduloSteps } from "util/sales/salesOperator";

export const RESET_OPERATORS = "RESET_OPERATORS";
export const SET_OPERATOR_SALES_MODULES = "SET_OPERATOR_SALES_MODULES";
export const SET_OPERATOR_SALES_BOXES = "SET_OPERATOR_SALES_BOXES";
export const SET_OPERATOR_COUNTRIES = "SET_OPERATOR_COUNTRIES";
export const SET_OPERATOR_SALES_ISSUES = "SET_OPERATOR_SALES_ISSUES";
export const SET_OPERATOR_PAGE = "SET_OPERATOR_PAGE";
export const SET_OPERATOR_SEARCH = "SET_OPERATOR_SEARCH";
export const SET_OPERATOR_RANGE_DATES = "SET_OPERATOR_RANGE_DATES";
export const SET_OPERATOR_CITIES = "SET_OPERATOR_CITIES";
export const SET_OPERATOR_VENDORS = "SET_OPERATOR_VENDORS";
export const SET_OPERATOR_CHANNELS = "SET_OPERATOR_CHANNELS";
export const SET_OPERATOR_STORES = "SET_OPERATOR_STORES";
export const SET_OPERATOR_SELECTED_CITY_ID = "SET_OPERATOR_SELECTED_CITY_ID";
export const SET_OPERATOR_SELECTED_STORE_ID = "SET_OPERATOR_SELECTED_STORE_ID";
export const RESET_OPERATOR_COUNTRY_FILTERS = "RESET_OPERATOR_COUNTRY_FILTERS";
export const SET_OPERATOR_ORDER_WORKFLOW = "SET_OPERATOR_ORDER_WORKFLOW";
export const RESET_OPERATOR_ORDER_WORKFLOW = "RESET_OPERATOR_ORDER_WORKFLOW";
export const SET_OPERATOR_SELECTED_VENDOR_ID =
  "SET_OPERATOR_SELECTED_VENDOR_ID";
export const SET_OPERATOR_SELECTED_CHANNEL_ID =
  "SET_OPERATOR_SELECTED_CHANNEL_ID";
export const SET_OPERATOR_SELECTED_COUNTRY_ID =
  "SET_OPERATOR_SELECTED_COUNTRY_ID";
export const SET_OPERATOR_SALES_SELECTED_MODULE =
  "SET_OPERATOR_SALES_SELECTED_MODULE";
export const SET_OPERATOR_SALES_SELECTED_BOX =
  "SET_OPERATOR_SALES_SELECTED_BOX";

export const resetOperators = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  return dispatch({ type: RESET_OPERATORS });
};

export const resetOperatorOrderWorkflow = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  return dispatch({ type: RESET_OPERATOR_ORDER_WORKFLOW, payload: undefined });
};

export const setModules = () => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const oldModules = getState().SalesOperator.modules;
  const newModule = await fetchModulesMocked();
  const modules = oldModules ? [...oldModules, newModule] : [newModule];
  dispatch({
    type: SET_OPERATOR_SALES_MODULES,
    payload: modules
  });
};

export const setOperatorOrders = (
  countryId: number,
  page: number,
  boxId: string,
  boxCode: string,
  rangeDates: string[],
  search?: string,
  channelId?: number,
  vendorId?: number,
  storeId?: number,
  cityId?: number
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const issues = await fetchOperatorSalesIssues(
    page,
    countryId,
    boxCode,
    rangeDates,
    search,
    channelId,
    vendorId,
    storeId,
    cityId
  );
  if (!issues) {
    return;
  }
  dispatch({
    type: SET_OPERATOR_SALES_ISSUES,
    payload: { issues, page, boxCode, boxId }
  });
};

export const setOperatorSelectedCountryId = (countryId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_OPERATOR_SELECTED_COUNTRY_ID, payload: countryId });
};

export const setOperatorSelectedStoreId = (
  storeId: number | string | undefined
) => (dispatch: ThunkDispatch, getState: () => AppState) => {
  dispatch({ type: SET_OPERATOR_SELECTED_STORE_ID, payload: storeId });
};

export const setOperatorCities = (countryId: string) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const cities = await fetchCitiesByCountry(+countryId);
  if (!cities) {
    return;
  }
  dispatch({ type: SET_OPERATOR_CITIES, payload: cities });
};

export const setOperatorOrderWorkflow = (
  channelId: number,
  vendorId: number
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const workflow = await fetchModules(channelId, vendorId);
  if (!workflow) {
    return;
  }
  dispatch({ type: SET_OPERATOR_ORDER_WORKFLOW, payload: workflow });
};

export const setOperatorRangeDates = (rangeDates: [string, string]) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_OPERATOR_RANGE_DATES, payload: rangeDates });
};

export const setOperatorVendors = (
  accountId: number,
  countryId: number
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const vendors = await fetchVendorByCountryAndAccount(accountId, countryId);
  if (!vendors) {
    return;
  }
  dispatch({ type: SET_OPERATOR_VENDORS, payload: vendors });
};

export const setOperatorChannelsByVendor = (vendorId: number) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const channels = await fetchChannelByVendor(vendorId);
  if (!channels) {
    return;
  }
  dispatch({ type: SET_OPERATOR_CHANNELS, payload: channels });
};

export const setOperatorStoresByVendorOrChannel = (
  vendorId: number | undefined,
  channelId: number | undefined
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const stores = await fetchStoresByVendorOrChannel(vendorId, channelId);
  if (!stores) {
    return;
  }
  dispatch({ type: SET_OPERATOR_STORES, payload: stores });
};

export const setOperatorSearch = (search: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_OPERATOR_SEARCH, payload: search });
};

export const setSelectedModule = (moduleId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_OPERATOR_SALES_SELECTED_MODULE, payload: moduleId });
};

export const setSelectedBox = (boxId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_OPERATOR_SALES_SELECTED_BOX, payload: boxId });
};

export const setOperatorSelectedVendorId = (
  vendorId: number | string | undefined
) => (dispatch: ThunkDispatch, getState: () => AppState) => {
  dispatch({ type: SET_OPERATOR_SELECTED_VENDOR_ID, payload: vendorId });
};

export const setOperatorSelectedCityId = (
  cityId: number | string | undefined
) => (dispatch: ThunkDispatch, getState: () => AppState) => {
  dispatch({ type: SET_OPERATOR_SELECTED_CITY_ID, payload: cityId });
};

export const setOperatorSelectedChannelId = (
  channelId: number | string | undefined
) => (dispatch: ThunkDispatch, getState: () => AppState) => {
  dispatch({ type: SET_OPERATOR_SELECTED_CHANNEL_ID, payload: channelId });
};

export const setBoxes = () => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const issueState = getState().SalesOperator;
  const selectedBoxId = issueState.selectedBoxId;
  const module = moduloSteps;
  const { steps } = module;
  const mappedSteps =
    steps?.map((box: any) => {
      const { id, description } = box;
      return {
        ...box,
        id: id.toString(),
        description: !description ? undefined : description,
        issuePages: { ...orderPagination }
      };
    }) ?? [];
  const boxes = mappedSteps.filter((step: any) => step.code);
  dispatch({ type: SET_OPERATOR_SALES_BOXES, payload: boxes });
  if (!selectedBoxId && boxes.length) {
    dispatch(setSelectedBox(boxes[0].id));
  }
};

export const setOperatorPage = (page: number) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_OPERATOR_PAGE, payload: page });
};
