// Task router, Reservations, channels and contact utils
import CONSTANTS from "../config/constants";
import { Reservation } from "types/reservation.types";

export const getMediaChannelId = (reservation: Reservation) => {
  if (!reservation || !reservation.channel) {
    return;
  }

  const sip = CONSTANTS.MEDIA_CHANNELS.SIP;
  if (reservation.channel.mediaChannel === sip) {
    const number = reservation.task.attributes.callerPhone;
    const countryCode = CONSTANTS.COUNTRY_CODE;
    return `+${countryCode}${number}`;
  }
  return reservation.channel.from;
};

export const getHistoricalId = (reservation: Reservation) => {
  if (!reservation) {
    return;
  }

  let id;
  if (reservation.contact) {
    id = reservation.contact.id;
  } else {
    id = getMediaChannelId(reservation);
  }
  return id;
};
