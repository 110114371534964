import { AppState, ThunkDispatch } from "redux/store.types";
import { fetchIssueSteps } from "services/sales/orders/orders.service";
import { fetchModules, fetchBoxes } from "services/sales/orders/orders.service";
import { fetchOrdersMocked } from "services/sales/orders/orders.service";
import { fetchBoxesMocked } from "services/sales/orders/orders.service";
import { fetchSalesIssues } from "services/sales/orders/orders.service";
import { Box, Order, ORDER_TYPES } from "types/orders.types";
import { ViewTypes } from "types/issues.types";
import { getModule } from "./issues.reducer.helpers";
import { fetchOrdersByCustomer } from "services/sales/orders/orders.service";

export const SET_SALES_ISSUES = "SET_SALES_ISSUES";
export const SET_SALES_MODULES = "SET_SALES_MODULES";
export const SET_SALES_BOXES = "SET_SALES_BOXES";
export const SET_SALES_SELECTED_MODULE = "SET_SALES_SELECTED_MODULE";
export const SET_SALES_ORDERBY_VALUE = "SET_SALES_ORDERBY_VALUE";
export const SET_SALES_SELECTED_BOX = "SET_SALES_SELECTED_BOX";
export const SET_SALES_VIEW_TYPE = "SET_SALES_VIEW_TYPE";
export const ADD_SALES_NEW_BOX = "ADD_SALES_NEW_BOX";
export const ADD_SALES_NEW_ISSUE = "ADD_SALES_NEW_ISSUE";
export const RESET_SALES_ISSUES = "RESET_SALES_ISSUES";
export const SET_ISSUE_STEPS = "SET_ISSUE_STEPS";
export const SET_ISSUES_BY_CUSTOMER = "SET_ISSUES_BY_CUSTOMER";

export const setModules = (channelId: number, vendorId: number) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const oldModules = getState().SalesIssues.modules;
  const newModule = await fetchModules(channelId, vendorId);
  const modules = oldModules ? [...oldModules, newModule] : [newModule];
  /*  const vendors = getState().Sales.salesProcess?.additionalInfo.vendors;
  if (!vendors) {
    return;
  }
  const promises = vendors.map(vendor => {
    const { channelId } = vendor;
    return fetchModules(channelId, vendor.id);
  });
  let modules = await Promise.all(promises);
  if (modules.includes(null)) {
    console.error("Some vendors in the sales process are not valid");
  }
  modules = modules.filter(module => !!module);
  if (!modules) {
    return;
  }
  console.log(modules);
  const modulesMapped = modules.map((module, index) => {
    const vendor = vendors[index];
    if (module === null) {
      return null;
    }
    const moduleMapped: Module = {
      id: vendor.id.toString(),
      title: module.title,
      type: module.type,
      weight: module.weight,
      boxes: module.boxes
    };
    return moduleMapped;
  });
  console.log(modulesMapped); */
  dispatch({
    type: SET_SALES_MODULES,
    payload: modules
  });
};

export const setBoxes = (
  moduleId: string,
  channelId: number,
  vendorId: number
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const issueState = getState().SalesIssues;
  const selectedBoxId = issueState.selectedBoxId;
  const { module } = getModule(issueState, moduleId);
  const boxes = await fetchBoxes(module.type, channelId, vendorId);
  if (!boxes) {
    console.error("The vendor in the sales process is not valid");
    return;
  }
  dispatch({ type: SET_SALES_BOXES, payload: { moduleId, boxes } });
  if (!selectedBoxId && boxes.length) {
    dispatch(setSelectedBox(boxes[0].id, moduleId));
  }
};

export const setBoxesMocked = (moduleId: string) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const issueState = getState().SalesIssues;
  const selectedBoxId = issueState.selectedBoxId;
  //TODO: Uncomment and fix when integrating real data
  //const { module } = getModule(issueState, moduleId);
  const boxes = await fetchBoxesMocked();
  dispatch({ type: SET_SALES_BOXES, payload: { moduleId, boxes } });
  if (!selectedBoxId && boxes.length) {
    dispatch(setSelectedBox(boxes[0].id, moduleId));
  }
};

export const setSelectedModule = (moduleId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SALES_SELECTED_MODULE, payload: moduleId });
};

export const setOrderBy = (orderBy: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SALES_ORDERBY_VALUE, payload: orderBy });
};

export const setSelectedBox = (boxId: string, moduleId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch(setSelectedModule(moduleId));
  dispatch({ type: SET_SALES_SELECTED_BOX, payload: boxId });
};

export const setViewType = (viewType: ViewTypes) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_SALES_VIEW_TYPE, payload: viewType });
};

export const setOrdersMocked = (
  page: number,
  boxId: string,
  moduleId: string,
  //DELETE ME LATER
  type: ORDER_TYPES
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const paginatedOrders = await fetchOrdersMocked(boxId, page);
  dispatch({
    type: SET_SALES_ISSUES,
    payload: { issues: paginatedOrders, boxId, moduleId }
  });
};

export const setOrders = (
  page: number,
  boxCode: string,
  boxId: string,
  moduleId: string,
  channelId: number,
  vendorId: number
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const issues = await fetchSalesIssues(boxCode, page, channelId, vendorId);
  if (!issues) {
    return;
  }
  dispatch({
    type: SET_SALES_ISSUES,
    payload: { issues, page, boxCode, boxId, moduleId }
  });
};

export const setIssuesByCustomer = (
  page: number,
  channelId: number,
  vendorId: number,
  uid: string
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const issuesByCustomer = await fetchOrdersByCustomer(
    page,
    channelId,
    vendorId,
    uid
  );
  if (!issuesByCustomer) {
    return false;
  }
  dispatch({
    type: SET_ISSUES_BY_CUSTOMER,
    payload: { page, channelId, vendorId, issuesByCustomer }
  });
  return true;
};

export const addNewBox = (moduleId: string, box: Box) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: ADD_SALES_NEW_BOX, payload: { moduleId, box } });
};

export const addNewIssue = (issue: Order, boxId: string, moduleId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: ADD_SALES_NEW_ISSUE, payload: { issue, boxId, moduleId } });
};

export const resetSalesIssues = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: RESET_SALES_ISSUES });
};

export const setIssueSteps = (issueId: number | undefined) => async (
  dispatch: ThunkDispatch
) => {
  if (!issueId) {
    dispatch({ type: SET_ISSUE_STEPS, payload: undefined });
    return;
  }
  const issueSteps = await fetchIssueSteps(issueId);
  dispatch({ type: SET_ISSUE_STEPS, payload: issueSteps });
};
