import { LocationState as State } from "./location.types";
import { SetCitiesAction } from "./location.types";
import { SetLivingPlacesAction, SetAreasByCityAction } from "./location.types";
import { LocationActions as Actions } from "./location.types";
import { SET_COUNTRIES, SET_CITIES, RESET_LOCATION } from "./location.actions";
import { SET_SELECTED_CITY, SET_SELECTED_AREA } from "./location.actions";
import { SET_SELECTED_COUNTRY } from "./location.actions";
import { SET_AREAS_BY_CITY } from "./location.actions";
import { SET_LIVING_PLACES } from "./location.actions";
import { findCountryById, findCityById } from "./location.reducer.helpers";
import { resetLocationState } from "./location.reducer.helpers";
import { findAreaById } from "./location.reducer.helpers";

const initialState: State = {
  countries: {},
  selectedCountry: findCountryById,
  selectedCountryId: undefined,
  cities: {},
  selectedCity: findCityById,
  selectedCityId: undefined,
  areasByCity: {},
  livingPlaces: {},
  selectedArea: findAreaById,
  selectedAreaId: undefined
};

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_COUNTRIES:
      return { ...state, countries: action.payload };
    case SET_CITIES:
      return setCitiesByCountry(state, action.payload);
    case SET_SELECTED_COUNTRY:
      return { ...state, selectedCountryId: action.payload };
    case SET_SELECTED_CITY:
      return { ...state, selectedCityId: action.payload };
    case SET_SELECTED_AREA:
      return { ...state, selectedAreaId: action.payload };
    case SET_AREAS_BY_CITY:
      return setAreasByCity(state, action.payload);
    case SET_LIVING_PLACES:
      return setLivingPlacesByCountry(state, action.payload);
    case RESET_LOCATION:
      return resetLocation(state);
    default:
      return state;
  }
};

const setCitiesByCountry = (
  state: State,
  payload: SetCitiesAction["payload"]
): State => {
  const { countryId, cities } = payload;
  const { id } = findCountryById(state, countryId) ?? {};
  if (!id) {
    throw new Error(
      `Country of countryId ${countryId} was not found. You should first set the country before trying to set its cities.`
    );
  }
  const newCities = { ...state.cities, [id]: cities };
  return { ...state, cities: newCities };
};

const setLivingPlacesByCountry = (
  state: State,
  payload: SetLivingPlacesAction["payload"]
): State => {
  const { countryId, livingPlaces } = payload;
  const { id } = findCountryById(state, countryId) ?? {};
  if (!id) {
    throw new Error(
      `Country of countryId ${countryId} was not found. You should first set the country before trying to set its cities.`
    );
  }
  const newLivingPlaces = { ...state.livingPlaces, [id]: livingPlaces };
  return { ...state, livingPlaces: newLivingPlaces };
};

const setAreasByCity = (
  state: State,
  payload: SetAreasByCityAction["payload"]
): State => {
  const { countryId, cityId, areas } = payload;
  const { id: selectedCountryId } = findCountryById(state, countryId) ?? {};
  const { id: selectedCityId } = findCityById(state, countryId, cityId) ?? {};
  if (!selectedCityId) {
    return state;
  }
  const newCountry = { ...state.areasByCity[selectedCountryId!] };
  newCountry[selectedCityId] = areas;
  const newAreasByCity = {
    ...state.areasByCity,
    [selectedCountryId!]: newCountry
  };
  return { ...state, areasByCity: newAreasByCity };
};

const resetLocation = (state: State): State => {
  return {
    ...state,
    ...resetLocationState
  };
};

export default reducer;
