// Image builder
import faker from "faker";

import { Image } from "types/sales/image.types";
import { chooseRandom } from "../common.builder";

export const buildImage = (overrides: Partial<Image> = {}): Image => {
  return {
    bucket: faker.name.jobTitle(),
    cloudFrontUrl: faker.internet.url(),
    key: faker.name.jobArea(),
    name: faker.commerce.product(),
    url: faker.internet.avatar(),
    ...overrides
  };
};

export const genImages = (
  quantity?: number,
  options: Partial<Image>[] = [],
  overrides: Partial<Image> = {}
): Image[] => {
  const num = quantity ?? Math.floor(Math.random() * 9) + 1;

  let newOptions = [...options];
  const images = [];
  for (let i = 0; i < num; i++) {
    const option = chooseRandom(options) ?? {};
    const Image = buildImage({ ...option, ...overrides });
    images.push(Image);
    newOptions = newOptions.filter(image => {
      return image.name === option.name;
    });
  }
  return images;
};
