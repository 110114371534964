// FIXME: replace for ep when it exists
export const moduloSteps = {
  id: 1,
  steps: [
    {
      id: 1,
      name: "Pago en proceso",
      code: "PAYMENT_IN_PROGRESS"
    },
    {
      id: 2,
      name: "Pedidos con error",
      code: "START,ERROR"
    },
    {
      id: 3,
      name: "Pedido recibido",
      code: "ORDERED,RECEIVED"
    },
    {
      id: 4,
      name: "Pedido por asignar y asignado",
      code: "TO_ASSIGN,ASSIGNED"
    },
    {
      id: 5,
      name: "En camino / Pedido Listo",
      code: "ON_ROUTE"
    },
    {
      id: 6,
      name: "Entregado a cliente / Pedido retirado",
      code: "DISPATCHED"
    },
    {
      id: 7,
      name: "Cancelado",
      code: "CANCELED"
    }
  ],
  webhooks: []
};
