// Widgets Redux helper functions and data

export const widgetInitialData = {
  initialized: false,
  icon: "",
  title: "",
  view: {
    visible: false
  },
  drawers: {}
};
