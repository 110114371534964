import React, { useState, useEffect, useRef } from "react";

import NetworkDetectorStyled from "./NetworkDetectorStyled";
import { NetworkDetectorProps as Props } from "./NetworkDetector.types";
import { message } from "antd";

export default function(ComposedComponent: React.FC) {
  const MemoizedComponent: React.FC = React.memo(ComposedComponent);
  const NetworkDetector: React.FC<Props> = props => {
    const [isDisconnected, setIsDisconnected] = useState(false);
    const wasOffline = useRef(false);

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        const webPing = setInterval(async () => {
          try {
            await fetch("//google.com", {
              mode: "no-cors"
            });
            clearInterval(webPing);
            setIsDisconnected(false);
          } catch (e) {
            setIsDisconnected(true);
          }
        }, 2000);
        return;
      }

      return setIsDisconnected(true);
    };

    const offLineHandler = () => {
      if (!wasOffline.current) {
        message.destroy();
        message.error("Sin conexión de internet", 9999999);
        wasOffline.current = true;
      }
    };

    const onLineHandler = () => {
      if (wasOffline.current) {
        message.destroy();
        message.success("De vuelta en linea");
      }
    };

    useEffect(() => {
      handleConnectionChange();
      window.addEventListener("online", handleConnectionChange);
      window.addEventListener("offline", handleConnectionChange);

      return () => {
        window.removeEventListener("online", handleConnectionChange);
        window.removeEventListener("offline", handleConnectionChange);
      };
    });

    useEffect(() => {
      if (isDisconnected) {
        offLineHandler();
      } else {
        onLineHandler();
      }
    }, [isDisconnected]);

    return (
      <NetworkDetectorStyled className="NetworkDetector">
        <MemoizedComponent {...props} />
      </NetworkDetectorStyled>
    );
  };

  NetworkDetector.defaultProps = {};

  return NetworkDetector;
}
