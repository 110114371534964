import { css } from "styled-components";

const base = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  *::selection {
    background: var(--palette-primary) !important;
  }

  html {
    font-size: 62.5%; //16px default to 10px
    scroll-behavior: smooth;
  }

  body {
    box-sizing: border-box;
    margin: 0;
    /* overflow: hidden; */
  }

  pre {
    font-family: inherit;
    margin: 0;
    white-space: pre-wrap;
  }

  /********** ISOMORPHIC GLOBAL STYLES **********/

  body {
    -webkit-overflow-scrolling: touch;
  }

  html h1,
  html h2,
  html h3,
  html h4,
  html h5,
  html h6,
  html a,
  html p,
  html li,
  input,
  textarea,
  span,
  div,
  html,
  body,
  html a {
    margin-bottom: 0;
    /* font-family: "Roboto", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  html ul {
    -webkit-padding-start: 0px;
    list-style: none;
    margin-bottom: 0;
  }

  .scrollbar-track-y,
  .scrollbar-thumb-y {
    width: 5px !important;
  }

  .scrollbar-track-x,
  .scrollbar-thumb-x {
    height: 5px !important;
  }

  .scrollbar-thumb {
    border-radius: 0 !important;
  }

  .ant-drawer-content-wrapper {
    /* min-width: 590px !important; */
  }

  .ant-drawer-body {
    /* padding: 0px !important; */
  }

  .ant-drawer-close {
    display: none !important;
  }

  .popconfirm-header {
    position: absolute;
    top: 105px;
    right: 15px;
  }

  .ant-drawer-header {
    display: none;
  }

  .ant-divider-horizontal {
    margin: 10px 0 !important;
  }

  .ant-layout-sider-children {
    background: #181a1f !important;
  }

  .ant-popover-inner-content {
    max-width: 320px !important;
  }

  .scrollbar-track {
    background: rgba(222, 222, 222, 0.15) !important;
  }

  .scrollbar-thumb {
    border-radius: 0 !important;
    background: rgba(0, 0, 0, 0.5) !important;
  }

  .ant-input,
  .ant-time-picker-input {
    border-radius: 0.8rem !important;
  }

  .ant-popover-placement-bottom
    > .ant-popover-content
    > .ant-popover-arrow:after,
  .ant-popover-placement-bottomLeft
    > .ant-popover-content
    > .ant-popover-arrow:after,
  .ant-popover-placement-bottomRight
    > .ant-popover-content
    > .ant-popover-arrow:after,
  .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
  .ant-popover-placement-topLeft
    > .ant-popover-content
    > .ant-popover-arrow:after,
  .ant-popover-placement-topRight
    > .ant-popover-content
    > .ant-popover-arrow:after {
    left: 0;
    margin-left: -4px;
  }

  .ant-menu {
    background-color: var(--palette-sidebar-bg) !important;
  }

  .ant-menu-submenu > .ant-menu {
    background-color: var(--palette-secondary) !important;

    & .ant-menu-item {
      &&&:hover {
        background-color: var(--palette-secondaryLight) !important;
      }
      & > a {
        color: var(--palette-white-0) !important;
      }
    }
  }

  .ant-menu-submenu-popup,
  .ant-select-dropdown {
    background-color: var(--palette-secondary) !important;
  }

  .ant-dropdown-menu > *.ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-item-group > *.ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-item-group-list > *.ant-dropdown-menu-item:hover {
    background-color: var(--palette-secondaryLight) !important;
  }
  .ant-modal-header {
    border-bottom-color: transparent !important;
  }

  .ant-modal-body {
    padding-top: 0 !important;
  }

  /* Popover */

  html[dir="rtl"] .ant-popover {
    text-align: right;
  }

  /* Ecommerce Card */

  html[dir="rtl"] .isoCardInfoForm .ant-input {
    text-align: right;
  }

  /* Modal */

  html[dir="rtl"] .has-success.has-feedback:after,
  html[dir="rtl"] .has-warning.has-feedback:after,
  html[dir="rtl"] .has-error.has-feedback:after,
  html[dir="rtl"] .is-validating.has-feedback:after {
    left: 0;
    right: auto;
  }

  html[dir="rtl"] .ant-modal-close {
    right: inherit;
    left: 0;
  }

  html[dir="rtl"] .ant-modal-footer {
    text-align: left;
  }

  html[dir="rtl"] .ant-modal-footer button + button {
    margin-left: 0;
    margin-right: 8px;
  }

  html[dir="rtl"] .ant-confirm-body .ant-confirm-content {
    margin-right: 42px;
  }

  html[dir="rtl"] .ant-btn > .anticon + span,
  html[dir="rtl"] .ant-btn > span + .anticon {
    margin-right: 0.5em;
  }

  html[dir="rtl"] .ant-btn-loading span {
    margin-left: 0;
    margin-right: 0.5em;
  }

  html[dir="rtl"]
    .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
    padding-left: 25px;
    padding-right: 29px;
  }

  html[dir="rtl"]
    .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
    .anticon {
    margin-right: -14px;
    margin-left: 0;
  }

  /* Confirm */

  html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
    margin-left: 16px;
    margin-right: 0;
    float: right;
  }

  html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns {
    float: left;
  }

  html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns button + button {
    margin-right: 10px;
    margin-left: 0;
  }

  /* Message */

  html[dir="rtl"] .ant-message .anticon {
    margin-left: 8px;
    margin-right: 0;
  }

  /* Pop Confirm */

  html[dir="rtl"] .ant-popover-message-title {
    padding-right: 20px;
    padding-left: 0;
  }

  html[dir="rtl"] .ant-popover-buttons {
    text-align: left;
  }

  /* Notification */

  html[dir="rtl"]
    .ant-notification-notice-closable
    .ant-notification-notice-message {
    padding-left: 24px;
    padding-right: 0;
  }

  html[dir="rtl"]
    .ant-notification-notice-with-icon
    .ant-notification-notice-message,
  html[dir="rtl"]
    .ant-notification-notice-with-icon
    .ant-notification-notice-description {
    margin-right: 48px;
  }

  html[dir="rtl"] .ant-notification-notice-close {
    right: auto;
    left: 16px;
  }

  html[dir="rtl"] .ant-notification-notice-with-icon {
    left: 0;
  }

  /* Dropzone */

  html[dir="rtl"] .dz-hidden-input {
    display: none;
  }

  .WorkerActivitySelectOption {
    background-color: var(--palette-secondary);
    color: var(--palette-white-0) !important;

    &.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
    &.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
    &.ant-select-dropdown-menu-item-selected {
      background-color: var(--palette-secondaryLight);
    }
  }

  /* ReservationButtonOption menu */

  /* .ant-dropdown-menu.ReservationButtonOptions__menu { */
  .ant-dropdown-menu,
  .ant-dropdown-menu-item-group,
  .ant-dropdown-menu-item-group-list {
    background-color: var(--palette-secondary) !important;

    & .ant-dropdown-menu-submenu-disabled {
      background-color: red !important;
    }

    & .ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-submenu
      .ant-dropdown-menu-submenu-title
      .ant-dropdown-menu-submenu-arrow-icon {
      background-color: var(--palette-secondary) !important;
      color: var(--palette-white-0) !important;
    }

    &
      .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled
      .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled
      .ant-dropdown-menu-submenu-title
      .ant-dropdown-menu-submenu-arrow-icon {
      color: var(--palette-grey-0) !important;
    }

    & .ant-dropdown-menu-item-disabled,
    .ant-dropdown-menu-submenu-title-disabled {
      color: var(--palette-grey-0) !important;
    }

    & .ant-dropdown-menu-submenu-popup li {
      background-color: var(--palette-secondary) !important;
      color: var(--palette-white-0) !important;
    }

    & > * {
      color: var(--palette-white-0) !important;
      &.ant-dropdown-menu-item:hover {
        background-color: var(--palette-secondaryLight) !important;
      }
    }

    & .ant-dropdown-menu-item > .anticon:first-child,
    .ant-dropdown-menu-submenu-title > .anticon:first-child,
    .ant-dropdown-menu-item > span > .anticon:first-child,
    .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
      transform: translateX(0.4rem);
    }
  }

  .Disabled {
    cursor: not-allowed !important;
  }

  .SortIssuesDropdown__dropdown.ant-menu-submenu-popup,
  .SortIssuesDropdown__dropdown.ant-select-dropdown {
    background-color: var(--palette-white-0) !important;
  }

  .ant-select-dropdown {
    background-color: var(--palette-white-0) !important;
  }

  .SalesWidget {
    &__h1 {
      font-size: 2rem;
      color: var(--palette-grey-52);
      line-height: 28px;
    }

    &__h2 {
      font-size: 1.6rem;
      color: var(--palette-black-0);
      line-height: 24px;
    }

    &__primary-text {
      font-size: 1.2rem;
      line-height: 2.4rem;
      color: var(--palette-grey-49);
    }

    &__secondary-text {
      font-size: 1.2rem;
      line-height: 2.4rem;
      color: var(--palette-grey-51);
    }
  }
`;

export default base;
