import { OrdersState as State } from "./issues.types";
import { SetIssuesByCustomerAction } from "./issues.types";
import { SalesIssuesActions as Actions } from "./issues.types";
import { SetIssuesAction, SetBoxesAction } from "./issues.types";
import { AddNewBoxAction, AddNewOrderAction } from "./issues.types";
import { SET_ISSUES_BY_CUSTOMER } from "./issues.actions";
import { SET_SALES_ISSUES, SET_SALES_MODULES } from "./issues.actions";
import { SET_SALES_BOXES, SET_ISSUE_STEPS } from "./issues.actions";
import { SET_SALES_SELECTED_MODULE } from "./issues.actions";
import { SET_SALES_ORDERBY_VALUE } from "./issues.actions";
import { SET_SALES_SELECTED_BOX } from "./issues.actions";
import { SET_SALES_VIEW_TYPE, ADD_SALES_NEW_ISSUE } from "./issues.actions";
import { ADD_SALES_NEW_BOX, RESET_SALES_ISSUES } from "./issues.actions";
import { getSelectedModule, getSelectedBox } from "./issues.reducer.helpers";
import { insertInModule, insertInIssues } from "./issues.reducer.helpers";
import { getModule, getOrdersPage } from "./issues.reducer.helpers";
import { insertInBox } from "./issues.reducer.helpers";
import { ViewTypes } from "types/issues.types";
import { resetSalesIssuesState } from "./issues.reducer.helpers";
import { setPage } from "util/redux.utils";
import { getInitialPaginationData } from "util/pagination";

const initialState: State = {
  modules: null,
  selectedModuleId: "",
  orderBy: "",
  selectedModule: getSelectedModule,
  selectedBoxId: "",
  selectedBox: getSelectedBox,
  viewType: ViewTypes.ROWS,
  issuesByCustomer: getInitialPaginationData(),
  issueSteps: undefined
};

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_SALES_MODULES:
      return { ...state, modules: action.payload };
    case SET_SALES_BOXES:
      return setBoxes(state, action.payload);
    case SET_SALES_SELECTED_MODULE:
      return { ...state, selectedModuleId: action.payload };
    case SET_SALES_ORDERBY_VALUE:
      return { ...state, orderBy: action.payload };
    case SET_SALES_SELECTED_BOX:
      return { ...state, selectedBoxId: action.payload };
    case SET_SALES_VIEW_TYPE:
      return { ...state, viewType: action.payload };
    case SET_SALES_ISSUES:
      return setIssues(state, action.payload);
    case ADD_SALES_NEW_BOX:
      return addNewBox(state, action.payload);
    case ADD_SALES_NEW_ISSUE:
      return addNewOrder(state, action.payload);
    case SET_ISSUE_STEPS:
      return { ...state, issueSteps: action.payload };
    case SET_ISSUES_BY_CUSTOMER:
      return setIssuesByCustomer(state, action.payload);
    case RESET_SALES_ISSUES:
      return resetSalesIssues(state);
    default:
      return state;
  }
};

const setIssuesByCustomer = (
  state: State,
  payload: SetIssuesByCustomerAction["payload"]
): State => {
  const { issuesByCustomer } = payload;
  const oldIssues = { ...state.issuesByCustomer };
  const newIssues = setPage(oldIssues, issuesByCustomer);
  return { ...state, issuesByCustomer: newIssues };
};

const setIssues = (
  state: State,
  payload: SetIssuesAction["payload"]
): State => {
  const { moduleId, boxId, issues } = payload;
  const { count } = issues;
  const issuesRes = insertInIssues(state, moduleId, boxId, issues);
  const { issuePages } = issuesRes;
  const { boxes } = insertInBox(state, moduleId, boxId, { issuePages, count });
  const { modules } = insertInModule(state, moduleId, { boxes });
  return { ...state, modules };
};

const setBoxes = (state: State, payload: SetBoxesAction["payload"]): State => {
  const { moduleId, boxes } = payload;
  const { modules } = insertInModule(state, moduleId, { boxes });
  return { ...state, modules };
};

/* const setOrders = (state: State, payload: SetIssuesAction["payload"]) => {
  const oldOrders = { ...state.orders };
  const newOrders = setPage(oldOrders, payload);
  return { ...state, orders: newOrders };
}; */

const addNewBox = (
  state: State,
  payload: AddNewBoxAction["payload"]
): State => {
  const { moduleId, box } = payload;
  const { module } = getModule(state, moduleId);
  if (!module.boxes) {
    throw new Error(
      "You should not call addNewBox before boxes are initialized"
    );
  }
  const boxes = [box, ...module.boxes];
  const { modules } = insertInModule(state, moduleId, { boxes });
  return { ...state, modules };
};

const addNewOrder = (state: State, payload: AddNewOrderAction["payload"]) => {
  const { order, boxId, moduleId } = payload;
  const { issuePage } = getOrdersPage(state, moduleId, boxId, 0);
  if (!issuePage) {
    throw new Error(
      "You should not call addNewIssue before issues of given page are initialized"
    );
  }
  const newItems = issuePage.items ? [order, ...issuePage.items] : [order];
  const newIssuePage = { ...issuePage, items: newItems };
  const { issuePages } = insertInIssues(state, moduleId, boxId, newIssuePage);
  const { boxes } = insertInBox(state, moduleId, boxId, { issuePages });
  const { modules } = insertInModule(state, moduleId, { boxes });
  return { ...state, modules };
};

const resetSalesIssues = (state: State): State => {
  return {
    ...state,
    ...resetSalesIssuesState
  };
};

export default reducer;
