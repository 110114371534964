// Comments widget redux helpers
import { widgetInitialData } from "../widgets.helpers";
import { CommentsDrawers } from "./widgets.comments.types";

const drawers: CommentsDrawers = {
  previews: {
    visible: false
  },
  new: {
    visible: false
  },
  full: {
    visible: false
  }
};

export const commentsInitialData = { ...widgetInitialData, drawers };
