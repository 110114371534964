import styled from "styled-components";

import { NetworkDetectorStyledProps as Props } from "./NetworkDetector.types";

const NetworkDetectorStyled = styled.div<Props>`
  .NetworkDetector {
  }
`;

export default NetworkDetectorStyled;
