const FACEBOOK = "facebook";
const MESSENGER = "messenger";
const WHATSAPP = "whatsapp";
const SMS = "sms";
const SIP = "sip";
const EMAIL = "email";

const SUPPORTED_MEDIA_AUDIO_FILE_TYPES = {
  OGG: "audio/ogg",
  OGG_OPUS: "audio/ogg; codecs=opus"
};
const SUPPORTED_MEDIA_VIDEO_FILE_TYPES = {
  MP4: "video/mp4"
};
const SUPPORTED_MEDIA_IMAGE_FILE_TYPES = {
  JPG: "image/jpg",
  JPEG: "image/jpeg",
  PNG: "image/png",
  WEBP: "image/webp"
};
const SUPPORTED_MEDIA_DOCUMENT_FILE_TYPES = { PDF: "application/pdf" };
const SUPPORTED_MEDIA_LOCATION_FILE_TYPES = {
  MAP: "location"
};

// Project constants
const CONSTANTS = {
  // Commerce Name
  CLIENT_NAME: "Trade S.A.",
  // Default max waiting time for a request reply
  DEFAULT_REQUEST_TIMEOUT: 15000,
  // Excluded status codes that should not be logged in bugsnag
  EXCLUDED_LOGGER_STATUS_CODES: [422],
  // Included environments that should be logged in bugsnag
  INCLUDED_LOGGER_ENVS: ["staging", "production"],
  // Included Internationalization environments that should be logged to the console
  INCLUDED_INTL_LOG_ENVS: [""],
  // Campaign priority min value
  MIN_CAMPAIGN_PRIORITY: 0,
  // Campaign priority max value
  MAX_CAMPAIGN_PRIORITY: 999,
  // String time format for filters
  FILTERS_TIME_FORMAT: "YYYY-MM-DD HH:mm:ss",
  // Campaign period time format
  CAMPAIGN_PERIOD_TIME_FORMAT: "dddd DD [de] MMMM, YYYY hh:mm a",
  // Cards creation time format
  CARDS_DATE_TIME_FORMAT: "DD/MM/YYYY",
  // Comunication channels
  MESSENGER,
  // TODO: remove to only have messenger
  FACEBOOK,
  WHATSAPP,
  SMS,
  SIP,
  EMAIL,
  // TODO: remove to only have sip
  MEDIA_CHANNELS: { MESSENGER, FACEBOOK, WHATSAPP, SMS, SIP, EMAIL },
  MEDIA_CHANNELS_TO_CHAT: {
    MESSENGER: "messenger",
    FACEBOOK: "facebook",
    WHATSAPP: "whatsapp"
  },
  // Supported header file-types
  SUPPORTED_MEDIA_AUDIO_FILE_TYPES,
  SUPPORTED_MEDIA_VIDEO_FILE_TYPES,
  SUPPORTED_MEDIA_IMAGE_FILE_TYPES,
  SUPPORTED_MEDIA_DOCUMENT_FILE_TYPES,
  SUPPORTED_MEDIA_LOCATION_FILE_TYPES,
  SUPPORTED_MEDIA_FILE_TYPES: {
    ...SUPPORTED_MEDIA_AUDIO_FILE_TYPES,
    ...SUPPORTED_MEDIA_VIDEO_FILE_TYPES,
    ...SUPPORTED_MEDIA_IMAGE_FILE_TYPES,
    ...SUPPORTED_MEDIA_DOCUMENT_FILE_TYPES,
    ...SUPPORTED_MEDIA_LOCATION_FILE_TYPES
  },
  // Task reservation status
  RESERVATION_STATUS: {
    ALL: "all",
    ASSIGNED: "assigned",
    CREATED: "created",
    ACCEPTED: "accepted",
    REJECTED: "rejected",
    COMPLETED: "completed",
    TIMEOUT: "timeout",
    WRAPPING: "wrapping"
  },
  TASK_STATUS: {
    PENDING: "PENDING",
    RESERVED: "RESERVED",
    ASSIGNED: "ASSIGNED",
    CANCELED: "CANCELED",
    COMPLETED: "COMPLETED",
    WRAPPING: "WRAPPING"
  },
  TASK_DIRECTION: {
    INBOUND: "inbound",
    OUTBOUND: "outbound"
  },
  TASK_CATEGORY_TYPES: {
    INBOUND: "IN",
    OUTBOUND: "OUT"
  },
  TASK_ATTRIBUTES_TYPES: {
    AUTOASSIGN: "autoassign"
  },
  // How many decimanls should be displayed
  DECIMAL_POINTS: 2,
  // Path to the entry point of the web app
  ENTRY_PATH: "/",
  // Path to the entry point of the restricted routes
  AUTHORIZED_ENTRY_PATH: "/app",
  // Path where agents will be redirected when not authorized
  NOT_AUTHORIZED_ENTRY_PATH: "/signin",
  // Different Apps TODO: pull from end point that brings menu
  APPS: {
    TRACK: "track",
    SERVICE: "service",
    CASH: "cash"
  },
  // Types of scheduled tasks
  SCHEDULED_TASK_TYPES: {
    LEAD: "LEADS"
  },
  // Complete task forms currently supported
  COMPLETE_TASK_FORMS: {
    DEFAULT: {
      VALUE: "DEFAULT"
    },
    LEADS: {
      VALUE: "LEADS"
    },
    TRANSFER: {
      VALUE: "TRANSFER"
    }
  },
  QUEUES: {
    DELAY: "delay",
    TRACK: "track"
  },
  CALL_STATUS: {
    IN_CALL: "IN_CALL",
    CALLING_IN: "CALLING_IN",
    CALLING_OUT: "CALLING_OUT",
    WRAPPING: "WRAPPING"
  },
  CALL_DIRECTION: {
    OUTGOING: "OUTGOING",
    INCOMING: "INCOMING"
  },
  // Chat cards currently supported
  CARD_TYPES: {
    CONTACTS: {
      UPDATE: {
        VALUE: "card:contact-updated",
        LABEL: "Contacto Actualizado"
      },
      NEW: {
        VALUE: "card:contact-new",
        LABEL: "Nuevo Contacto"
      }
    },
    TICKETS: {
      NEW: {
        VALUE: "card:ticket-new",
        LABEL: "Ticket"
      }
    },
    COMMENTS: {
      NEW: {
        VALUE: "card:comment-new",
        LABEL: "Comentario"
      }
    },
    CALL: {
      IN: {
        VALUE: "card:call-in",
        LABEL: "Ll. entrante"
      },
      OUT: {
        VALUE: "card:call-out",
        LABEL: "Ll. saliente"
      },
      BOUNCED: {
        VALUE: "card:call-bounced",
        LABEL: "Ll. rebotada"
      },
      MISSED: {
        VALUE: "card:call-missed",
        LABEL: "Ll. perdida"
      }
    },
    TAG: {
      NEW: {
        VALUE: "card:tag-new",
        LABEL: "Nueva Etiqueta"
      },
      UPDATE: {
        VALUE: "card:tag-update",
        LABEL: "Actualización Etiqueta"
      }
    },
    TASK: {
      COMPLETE: {
        VALUE: "card:task-complete",
        LABEL: "Tarea Completada"
      },
      TRANSFERED: {
        VALUE: "card:task-transfered",
        LABEL: "Tarea Transferida"
      },
      RESCHEDULED: {
        VALUE: "card:task-rescheduled",
        LABEL: "Tarea Reagendada"
      }
    }
  },
  // Card constant
  CARDS_PAGE_SIZE: 50,
  // CHAT
  CHAT: {
    // chat update direction
    UPDATE: {
      TOP: "TOP",
      BOTTOM: "BOTTOM"
    },
    PAGINATION: {
      LIMIT: 50
    },
    PANEL: {
      MODE: {
        DEFAULT: "DEFAULT",
        MEDIA: "MEDIA",
        EMOJI: "EMOJI",
        LOCATION: "LOCATION"
      }
    }
  },
  // Ticket constants
  TICKETS: {
    PRIORITY_TYPES: {
      LOW: "low",
      MEDIUM: "medium",
      HIGH: "high"
    },
    MIN_TICKET_DIGITS: 6
  },
  // Media
  MEDIA: {
    MAX_ATTACHMENTS: 23
  },
  // Minimum number of items that should be rendered in the inbox
  INBOX_MIN_ITEMS: 5,
  // All letters in the unicode language
  UNICODE_LETTERS:
    "a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ",
  CHANNEL_CONTACT_IDS: {
    FACEBOOK: "facebookId",
    WHATSAPP: "whatsappId"
  },
  COUNTRY_CODE: "",
  OFFLINE_ACTIVITY_NAME: "Offline",
  AVAILABLE_ACTIVITY_NAME: "Available",
  ROLES: {
    ADMIN: 1,
    SUPERVISOR: 2,
    OPERATOR: 3
  },
  ISSUES: {
    PAGE_SIZE: 20
  },
  MAPS: {
    COUNTRY: "cl",
    CCTLD_CODE: "cl"
  },
  SALES_ISSUES: {
    ADDRESS_LIMIT: 7,
    MIGRATION_ACTIVE: false,
    CONFIG_CHANNELS: [
      { id: 43, friendlyName: "A domicilio" },
      { id: 42, friendlyName: "A domicilio" }
    ],
    CONFIG_VENDORS: [
      { id: 7, name: "KFC", channelId: 43 },
      { id: 9, name: "Wendys", channelId: 42 }
    ],
    COUNTRY_ID: 1,
    ACCOUNT_ID: 7,
    PLATFORM: "oauth",
    CLIENT_VERSION: 3,
    CONTENT_TYPE: "application/json",
    API_GATEWAY: "https://api.v2.desarrollo-redbrand.com/",
    LOGIN: "api/authentication/auth/login",
    USERS: "users/storeCompleteUser",
    UPDATE_USERS: "users",
    FIND_USERS: "users/findUsers",
    WORKFLOW: "workflows/1",
    GET_ISSUES: "order/operatorHistory",
    SHIPPING_ADDRESS: "shipping-address",
    BILLING_DATA: "billing-data",
    COUNTRIES: "countries",
    CITIES: "country/",
    SECTOR: "city/",
    LOCATION: "location",
    LIVING_PLACES: "living-places",
    PAYMENT_METHODS: "payment-method/vendor",
    ISSUE_STEPS: "issues",
    PRODUCTS: "products",
    STORE: "store",
    SHIPPING_COST: "shippingCost",
    CATEGORY_BY_STORE: "store/categories",
    VENDORS: "vendors",
    CHANNELS: "channels",
    ORDER: "order",
    SHOPPING_CART: "shopping-cart",
    PAYMENT_IN_PROGRESS: "paymentInProgress",
    PAYMENT_RETRY: "payment-retry",
    PAYMENT_METHOD: "payment-method",
    PAYMENT_INSCRIPTIONS: "payment-inscriptions",
    PAYMENT_ENVIRONMENT_TEST: true,
    CASH_PAYMENT_METHOD_ID: 1,
    CREDIT_CARD_PAYMENT_METHOD_ID: 2,
    PLACE_TO_PAY_PAYMENT_METHOD_ID: 4,
    KUSHKI_PAYMENT_METHOD_ID: 14,
    TRANSBANK_PAYMENT_METHOD_ID: 15,
    DEFAULT_LAT: -0.180241,
    DEFAULT_LNG: -78.48826,
    DEFAULT_ZOOM: 15,
    DEFAULT_ISO_CODE: "EC",
    DOCUMENT_TYPE: {
      CI: "Cédula de identificación (Ecuador)",
      RUC: "RUC",
      PASSPORT: "Pasaporte",
      NIT: "NIT",
      CE: "Cédula de extranjería",
      CI_CO: "Cédula de identificación (Colombia)",
      RUT: "Registro Único Tributario"
    },
    PHONE_TYPE: {
      MOBILE: "Móvil",
      HOUSE: "Casa"
    },
    EMAIL_TYPE: {
      PRIMARY: "Principal",
      SECONDARY: "Secundario",
      OFFICE: "Oficina",
      OTHER: "Otro"
    },
    COUNTRY_CODE: {
      CHILECC: "56",
      COLOMBIACC: "57",
      ECUADORCC: "593"
    },
    SHOPPING_CART_DATE_FORMAT: "YYYY-MM-DD hh:mm:ss.SSS",
    DATE_FORMAT: "YYYY/MM/DD",
    DATE_PLACEHOLDER: "DD/MM/AAAA"
  },
  SALES_OPERATORS_ISSUES: {
    COUNTRY_ID: 1,
    DISABLED_COUNTRY_FILTER: true
  },
  DEFAULT_PAGE_SIZE: 20,
  DEFAULT_PRODUCTS_SIZE: 50,
  DEFAULT_CUSTOMERS_SIZE: 50
};

export default CONSTANTS;
