// Module and boxes entity builders
import faker from "faker";

import { Box, Module, ORDER_TYPES } from "types/orders.types";
import { genId, chooseRandom, genNumber } from "../common.builder";
import { orderPagination } from "redux/sales/issues/issues.reducer.helpers";

const fixedModules = {
  /* agendamientos: { type: ORDER_TYPES.BOOKING, weight: 1 }, */
  ordenes: { type: ORDER_TYPES.ORDERS, weight: 0 }
};

/* const genBoxTitles = () => {
  return orderBoxTitles.map(() => faker.company.companyName());
}; */

const orderBoxTitles = [
  "Pedidos recibidos",
  "Pedidos por asignar",
  "Asignados",
  "Entregados a cliente",
  "Pedidos con error",
  "Pago en proceso"
];

/* const bookingBoxTitles = [
  "Agendadas",
  "En Proceso",
  "Asistidas",
  "No Asistidas",
  "Canceladas"
]; */

export const buildBox = (overrides: Partial<Box> = {}): Box => {
  const unread = genNumber(20);
  return {
    id: genId(),
    name: faker.company.companyName(),
    count: genNumber({ min: unread, max: 80 }),
    issuePages: { ...orderPagination },
    ...overrides
  };
};

const mapIssueTypeToTitleOptions = (moduleType: ORDER_TYPES) => {
  switch (moduleType) {
    /*     case ORDER_TYPES.BOOKING:
      return bookingBoxTitles; */
    case ORDER_TYPES.ORDERS:
      return orderBoxTitles;
    default:
      throw new Error(`Module type ${moduleType} is not supported`);
  }
};

export const genBoxes = (
  quantity?: number,
  moduleType: ORDER_TYPES = ORDER_TYPES.ISSUE
): Box[] => {
  const num = quantity ?? Math.floor(Math.random() * 4) + 1;
  let titleOptions = mapIssueTypeToTitleOptions(moduleType);
  const Boxes = [];
  for (let i = 0; i < num; i++) {
    const name = chooseRandom(titleOptions);
    const Box = buildBox({ name });
    titleOptions = titleOptions.filter(option => {
      return option !== name;
    });
    Boxes.push(Box);
  }
  return Boxes;
};

export const buildModule = (overrides: Partial<Module> = {}): Module => {
  const [title, props] = chooseRandom(Object.entries(fixedModules));
  return {
    id: genId(),
    title,
    weight: props.weight,
    type: props.type,
    ...overrides
  };
};

export const genModulesMocked = (quantity?: number): Module[] => {
  const num = quantity ?? Math.floor(Math.random() * 9) + 1;
  let options = Object.entries(fixedModules);
  const Modules = [];
  for (let i = 0; i < num; i++) {
    if (!options.length) {
      break;
    }
    const [title, props] = chooseRandom(options);
    const Module = buildModule({ title, ...props });
    options = options.filter(option => option[0] !== title);
    Modules.push(Module);
  }
  return Modules;
};
