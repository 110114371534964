// Vendor builders
import faker from "faker";

import { Vendor } from "types/sales/vendor.types";
import { chooseRandom, genNumber, genNumericId } from "../common.builder";

import kfcSVG from "assets/images/widgets/sales/kfc.svg";
import juanValdezSVG from "assets/images/widgets/sales/juan-valdez.svg";
import menestrasSVG from "assets/images/widgets/sales/menestras-del-negro.svg";
import { genImages } from "./image.builder";
import { Image } from "types/sales/image.types";

const vendorOptions: Partial<Image>[] = [
  { url: kfcSVG, name: "Kentucky Fried Chicken" },
  { url: juanValdezSVG, name: "Juan Valdez Café" },
  { url: menestrasSVG, name: "Menestras del Negro" }
];

export const buildVendor = (overrides: Partial<Vendor> = {}): Vendor => {
  return {
    id: Math.round(genNumericId()),
    images: genImages(undefined, vendorOptions),
    name: faker.company.companyName(),
    maxPurchaseValue: genNumber(80),
    channelId: genNumber(99),
    ...overrides
  };
};

export const genVendors = (quantity?: number): Vendor[] => {
  const num = quantity ?? Math.floor(Math.random() * 9) + 1;
  let options = [...vendorOptions];
  const Vendors = [];
  for (let i = 0; i < num; i++) {
    const option = chooseRandom(options) ?? {};
    const Vendor = buildVendor(option);
    Vendors.push(Vendor);
    options = options.reduce<Partial<Vendor>[]>((acc, next) => {
      if (next.name === option?.name) {
        return [...acc];
      }
      return [...acc, next];
    }, []);
  }
  return Vendors;
};
