import axiosDefault from "../../util/axios";

import { getAuthorization } from "../../redux/auth/auth.actions";
import { TwilioWorker } from "types/worker.types";
import { message } from "antd";

export const fetchWorkerMSTaskRouter = async () => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get(`api/task_router/user`, {
      headers: {
        Authorization: authorizationToken
      }
    });
    return data;
  } catch (e) {
    message.error("Login falló, inténtelo nuevamente.");
  }
};

export const fetchWorkersTwilio = async (): Promise<
  TwilioWorker[] | undefined
> => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get("/api/twilio/worker/all", {
      headers: {
        Authorization: authorizationToken
      }
    });
    return data as TwilioWorker[];
  } catch (e) {
    message.error("Falló la búsqueda de workers");
  }
};

export const fetchWorkerTwilioById = async (
  workerId: string
): Promise<TwilioWorker | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get(
      `/api/twilio/worker?workerSid=${workerId}`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    return data as TwilioWorker;
  } catch (e) {
    message.error(`Falló la búsqueda del worker`);
  }
};
