import i18next from "i18next";

export const generateDataToCallsGraph = (payload: any) => {
  if (payload === null) return [];

  return payload.map((o: { type: string }) => {
    const obj = {
      ...o,
      type: i18next.t(`taskDirection.${o.type}`)
    };
    return obj;
  });
};

export const generateDataSummaryTotalTask = (payload: any) => {
  if (payload === null) return [];

  return payload.map((o: { total: number; value: number; channel: string }) => {
    const obj = {
      channel: i18next.t(
        `translationTrackSupervisor:summaryTotalTask.channel.${o.channel}`
      ),
      total: o.value
    };
    return obj;
  });
};

export const generateDataToClassificationTasks = (payload: any) => {
  if (payload === null) return [];

  const obj: { value: any; type: any; name: any }[] = [];
  payload.forEach((type: any[]) => {
    return type.forEach(o => {
      obj.push({
        value: parseInt(o.count),
        type: o.name,
        name: i18next.t(
          `translationTrackSupervisor:tasksByCategories.${o.type}`
        )
      });
    });
  });

  return obj;
};
