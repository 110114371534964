import {
  SupervisorState as State,
  SupervisorActions as Actions
} from "./supervisor.types";
import {
  RESET_SUPERVISOR,
  CALLS_DIRECTION,
  SUMMARY_TOTAL_TASK,
  COUNT_TASK,
  TASKS_BY_CATEGORIES
} from "./supervisor.actions";

const initialState: State = {
  countTask: null,
  callsDirection: null,
  summaryTotalTask: null,
  taskByCategories: null
};

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case RESET_SUPERVISOR:
      return { ...initialState };
    case CALLS_DIRECTION:
      return { ...state, callsDirection: action.payload };
    case SUMMARY_TOTAL_TASK:
      return { ...state, summaryTotalTask: action.payload };
    case COUNT_TASK:
      return { ...state, countTask: action.payload };
    case TASKS_BY_CATEGORIES:
      return { ...state, taskByCategories: action.payload };
    default:
      return state;
  }
};

export default reducer;
