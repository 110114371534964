// Order services
import { message } from "antd";

import { SalesIssuesByQueryConfig } from "./orders.service.types";
import { PaginatedDetailedOrdersRes } from "./orders.service.types";
import { PaginatedOrdersRes, ModulesRes } from "./orders.service.types";
import { genOrders } from "util/testUtils/builders/orders/orders.builder";
import CONSTANTS from "config/constants";
import { Box, Module, PaymentMethod } from "types/orders.types";
import { genModulesMocked } from "util/testUtils/builders/orders/module.builder";
import { genBoxes } from "util/testUtils/builders/orders/module.builder";
import { Order, ORDER_TYPES } from "types/orders.types";
import { orderPagination } from "redux/sales/issues/issues.reducer.helpers";
import { PaginationPage } from "redux/sales/issues/issues.types";
import { PaymentMethodsRes } from "../vendors/vendors.service.types";
import { Objectify, PaginationRes } from "types/common.types";
import { SaveOrderReq, SavePaymentRetry } from "./orders.service.types";
import { SavePaymentInscription, SaveKushkiPay } from "./orders.service.types";
import { CancelCreditCardPayment } from "./orders.service.types";
import { SaveKushkiAudit } from "./orders.service.types";
import { DetailedOrder } from "types/orders.types";
import { axiosXmart } from "util/axios";
import { getAuthorization } from "redux/auth/auth.actions";
import { IssueStep } from "types/issueSteps.types";
import { OrderInProgress, PaymentInscription } from "types/orders.types";

const { PAGE_SIZE } = CONSTANTS.ISSUES;
const { SALES_ISSUES } = CONSTANTS;
const { GET_ISSUES } = SALES_ISSUES;
const { PLATFORM, WORKFLOW, CLIENT_VERSION, PAYMENT_METHODS } = SALES_ISSUES;
const { ORDER, ISSUE_STEPS } = SALES_ISSUES;
const { PAYMENT_IN_PROGRESS, PAYMENT_RETRY } = SALES_ISSUES;
const { PAYMENT_INSCRIPTIONS, PAYMENT_METHOD, CONFIG_VENDORS } = SALES_ISSUES;

const headers = {
  Platform: PLATFORM,
  "client-version": CLIENT_VERSION
};

export const fetchBoxes = async (
  type: string,
  channelId: number,
  vendorId: number
): Promise<Box[] | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${WORKFLOW}?vendorId=${vendorId}&channelId=${channelId}`;
    const data: ModulesRes = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { steps } = data.data;
    const mappedSteps = steps.map(box => {
      const { id, description } = box;
      return {
        ...box,
        id: id.toString(),
        description: !description ? undefined : description,
        issuePages: { ...orderPagination }
      };
    });
    const filters = ["START", "PAYMENT_IN_PROGRESS", "RECEIVED"];
    return mappedSteps.filter(
      step => step.code && !filters.includes(step.code)
    );
  } catch (error) {
    message.error("Ocurrió un problema al obtener las bandejas de pedidos");
    return;
  }
};

export const fetchSalesIssues = async (
  boxCode: string,
  page: number,
  channelId: number,
  vendorId: number
): Promise<PaginationPage<Order> | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${GET_ISSUES}?channel_id=${channelId}&vendor_id=${vendorId}&status=${boxCode}&size=${PAGE_SIZE}&page=${page +
      1}`;
    const result: PaginatedOrdersRes = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { last_page, data, total } = result.data.data;
    return {
      page,
      totalPages: last_page,
      items: data,
      count: total
    };
  } catch (e) {
    message.error(
      "Ocurrió un problema al obtener los pedidos de la bandeja seleccionada"
    );
    return;
  }
};

export const fetchOperatorSalesIssues = async (
  page: number,
  country_id: number,
  status: string, //boxCode
  rangeDates: string[],
  search?: string,
  channel_id?: number,
  vendor_id?: number,
  store_id?: number,
  city_id?: number
): Promise<PaginationPage<Order> | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${GET_ISSUES}?size=${PAGE_SIZE}&page=${page}`;
    const result: PaginatedOrdersRes = await axiosXmart.get(url, {
      params: {
        country_id,
        status,
        channel_id,
        vendor_id,
        search,
        start_date: rangeDates[0],
        end_date: rangeDates[1],
        store_id,
        city_id
      },
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { last_page, data, total } = result.data.data;
    return {
      page,
      totalPages: last_page,
      items: data,
      count: total
    };
  } catch (e) {
    message.error(
      "Ocurrió un problema al obtener los pedidos de la bandeja seleccionada"
    );
    return;
  }
};

export const fetchOrdersByCustomer = async (
  page: number,
  channelId: number,
  vendorId: number,
  uid: string
): Promise<PaginationRes<DetailedOrder> | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${GET_ISSUES}?channel_id=${channelId}&vendor_id=${vendorId}&uid=${uid}&page=${page +
      1}`;
    const result: PaginatedDetailedOrdersRes = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data, last_page } = result.data.data;
    return {
      page,
      totalPages: last_page,
      items: data
    };
  } catch (e) {
    message.error(
      "Ocurrió un problema al obtener el historial de pedidos del cliente"
    );
    return;
  }
};

export const fetchSalesIssuesByQuery = async (
  salesIssuesByQuery: SalesIssuesByQueryConfig
): Promise<PaginationRes<Order> | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const { query, page, channelId, vendorId, boxCode } = salesIssuesByQuery;
    const { startDate, endDate } = salesIssuesByQuery;
    const startDateExists =
      startDate?.trim() !== "" ? `&start_date=${startDate}` : "";
    const endDateExists = endDate?.trim() !== "" ? `&end_date=${endDate}` : "";

    const url = `/${GET_ISSUES}?channel_id=${channelId}&vendor_id=${vendorId}&status=${boxCode}&page=${page}&search=${query}${startDateExists}${endDateExists}`;

    const result: PaginatedOrdersRes = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data, total } = result.data.data;
    return {
      page,
      totalPages: total,
      items: data
    };
  } catch (e) {
    message.error("Ocurrió un problema al buscar las órdenes");
    return;
  }
};

export const fetchModules = async (
  channelId: number,
  vendorId: number
): Promise<Module | null> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${WORKFLOW}?vendorId=${vendorId}&channelId=${channelId}`;
    const result: ModulesRes = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { id, steps } = result.data;
    const stepsWithIssues = steps.map(box => {
      return {
        ...box,
        issuePages: { ...orderPagination }
      };
    });
    const filters = ["START", "PAYMENT_IN_PROGRESS", "RECEIVED"];
    const boxes = stepsWithIssues.filter(
      step => step.code && !filters.includes(step.code)
    );
    const vendor = CONFIG_VENDORS.find(
      configVendor => configVendor.id === vendorId
    );
    const modules: Module = {
      id: vendorId.toString(),
      title: `Estados pedido ${vendor?.name ?? ""}`,
      weight: id,
      boxes,
      type: ORDER_TYPES.ORDERS
    };
    return modules;
  } catch (e) {
    message.error("Ocurrió un problema al obtener el estado de los pedidos");
    return null;
  }
};

export const fetchPaymentMethods = async (
  vendorId: number,
  channelId: number
): Promise<Objectify<PaymentMethod> | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${PAYMENT_METHODS}/${vendorId}/channel/${channelId}`;
    const result: PaymentMethodsRes = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data } = result.data;
    const paymentMethods = data.reduce((acc: any, next: any) => {
      return { ...acc, [next.id]: next };
    }, {});
    return paymentMethods;
  } catch (e) {
    message.error("Ocurrió un problema al obtener los métodos de pago");
    return;
  }
};

export const fetchOrdersMocked = (
  boxId: string,
  page: number
): Promise<PaginationPage<Order>> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ page, totalPages: 5, items: genOrders(PAGE_SIZE), count: 100 });
    }, 1000);
  });
};

export const fetchModulesMocked = (): Promise<Module[]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(genModulesMocked(1));
    }, 1000);
  });
};

export const fetchBoxesMocked = (): Promise<Box[]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const boxes = genBoxes(6, ORDER_TYPES.ORDERS);
      const initedBoxes = boxes.map(box => {
        return { ...box, issuePages: { ...orderPagination } };
      });
      resolve(initedBoxes);
    }, 1000);
  });
};

export const fetchOrder = async (
  orderId: number
): Promise<Order | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${ORDER}/${orderId}`;
    const result = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    message.error("Ocurrió un problema al obtener la orden");
    return;
  }
};

export const saveOrder = async (
  orderReq: SaveOrderReq
): Promise<DetailedOrder> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${ORDER}`;
    const result = await axiosXmart.post(url, orderReq, {
      headers: {
        ...headers,
        uid: orderReq.uid,
        account: orderReq.account,
        Authorization: authorizationToken
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    console.log(e, "ERROR");
    throw new Error("Failed to save order");
  }
};

export const fetchIssueSteps = async (
  issueId: number
): Promise<IssueStep[]> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${ISSUE_STEPS}/${issueId}/history`;
    const result = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data } = result;
    return data;
  } catch (e) {
    throw new Error("Failed to fetch issue steps");
  }
};

export const fetchPaymentsInProgressByCustomer = async (
  channelId: number,
  vendorId: number,
  uid: string
): Promise<OrderInProgress[] | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${PAYMENT_IN_PROGRESS}/channel_id/${channelId}/vendor_id/${vendorId}`;
    const result = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers,
        uid
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    message.error(
      "Ocurrió un problema al obtener pedidos en progreso del cliente"
    );
    return;
  }
};
export const savePaymentRetry = async (
  orderReq: SavePaymentRetry
): Promise<DetailedOrder> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${PAYMENT_RETRY}`;
    const result = await axiosXmart.post(url, orderReq, {
      headers: {
        ...headers,
        uid: orderReq.uid,
        Authorization: authorizationToken
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    throw new Error("Failed to save retry");
  }
};

export const fetchPaymentInscription = async (
  paymentMethodId: number,
  vendorId: number,
  storeId: number,
  uid: string
): Promise<PaymentInscription | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${PAYMENT_INSCRIPTIONS}/list/method/${paymentMethodId}/vendor/${vendorId}/store/${storeId}`;
    const result = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        uid,
        ...headers
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    message.error("Ocurrió un problema al obtener las tarjetas");
    return;
  }
};

export const fetchPaymentCredentials = async (
  paymentMethodId: number,
  storeId: number
): Promise<any> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${PAYMENT_METHOD}/${paymentMethodId}/store/${storeId}/credentials`;
    const result = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    message.error("Ocurrió un problema al obtener las tarjetas");
    return;
  }
};

export const savePaymentInscription = async (
  savePaymentInscription: SavePaymentInscription
): Promise<any> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${ORDER}/kushki/inscription`;
    const result = await axiosXmart.post(url, savePaymentInscription, {
      headers: {
        ...headers,
        uid: savePaymentInscription.uid,
        Authorization: authorizationToken
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    throw new Error("Failed to save payment inscription");
  }
};

export const saveKushkiPay = async (
  saveKushkiPay: SaveKushkiPay
): Promise<any> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${ORDER}/kushki/pay`;
    const result = await axiosXmart.post(url, saveKushkiPay, {
      headers: {
        ...headers,
        uid: saveKushkiPay.uid,
        Authorization: authorizationToken
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    throw new Error("Failed to save kushki pay");
  }
};

export const deletePaymentInscription = async (
  paymentInscriptionId: number,
  uid: string
): Promise<any> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${ORDER}/kushki/inscription/${paymentInscriptionId}`;
    const result = await axiosXmart.delete(url, {
      headers: {
        ...headers,
        uid,
        Authorization: authorizationToken
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    throw new Error("Failed to delete payment inscription");
  }
};

export const cancelCreditCardPayment = async (
  cancelCreditCardPayment: CancelCreditCardPayment
): Promise<any> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${ORDER}/kushki/cancelCreditCardPayment`;
    const result = await axiosXmart.post(url, cancelCreditCardPayment, {
      headers: {
        ...headers,
        uid: cancelCreditCardPayment.uid,
        Authorization: authorizationToken
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    throw new Error("Failed to cancel kushki cancelCreditCardPayment");
  }
};

export const saveKushkiAudit = async (
  saveKushkiAudit: SaveKushkiAudit
): Promise<any> => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${ORDER}/kushki/audit`;
    const result = await axiosXmart.post(url, saveKushkiAudit, {
      headers: {
        ...headers,
        uid: saveKushkiAudit.uid,
        Authorization: authorizationToken
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    throw new Error("Failed to cancel kushki audit");
  }
};

export const repeatOrder = async (
  orderId: number,
  uid: string,
  currentShoppingCartId?: string
): Promise<any> => {
  try {
    const authorizationToken = await getAuthorization();

    let url;

    if (currentShoppingCartId) {
      url = `/${ORDER}/rebuild-cart/${orderId}?action=REPLACE&currentShoppingCartId=${currentShoppingCartId}`;
    } else {
      url = `/${ORDER}/rebuild-cart/${orderId}?action=REPLACE`;
    }

    const result = await axiosXmart.get(url, {
      headers: {
        ...headers,
        uid,
        Authorization: authorizationToken
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    throw new Error("Failed to repeat order");
  }
};

export const changeStateOrder = async (
  issueId: number,
  idTransition: number,
  conditions: any[]
) => {
  try {
    const authorizationToken = await getAuthorization();

    const url = `/${ISSUE_STEPS}/${issueId}`;
    const result = await axiosXmart.put(
      url,
      {
        transition_id: idTransition,
        conditions
      },
      {
        headers: {
          ...headers,
          Authorization: authorizationToken
        }
      }
    );
    const { data } = result;
    return data;
  } catch (e) {
    message.error("Error al cambiar de estado la orden");
  }
};

export const reinjectOrder = async (orderId: number) => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${ORDER}/${orderId}/injectionRetry`;
    const result = await axiosXmart.get(url, {
      headers: {
        ...headers,
        Authorization: authorizationToken
      }
    });
    const { data } = result.data;
    return data;
  } catch (e) {
    message.error("Error al reinyectar la orden");
  }
};
