// Ticket types and interfaces
import { Issue, BaseIssue } from "./issues.types";

export interface Ticket extends Issue {
  ticketId: string;
  scores: Scores | undefined;
  status: TICKET_STATUS;
  priority: string;
  category: string;
  subcategory: string;
}

export interface Scores {
  treatment: number;
  service: number;
}

export enum TICKET_STATUS {
  PENDING = "Pendiente",
  ATTENDING = "En atención",
  IN_PROCESS = "En proceso",
  RESOLVED = "Resuelto",
  CLOSED = "Cerrado"
}

export interface BaseTicket extends BaseIssue {
  status: TICKET_STATUS.PENDING;
  category: string;
  subcategory: string;
}
