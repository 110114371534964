import { AppState, ThunkDispatch } from "redux/store.types";
import { InitWidgetValues } from "./widgets.types.common";
import { WidgetNames } from "./widgets.types";
import { ToggleDrawerConfig } from "components/dashboard/widgets/UseWidget/UseWidget.types";

export const INIT_WIDGET = "INIT_WIDGET";
export const SET_VIEW_VISIBLE = "SET_VIEW_VISIBLE";
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const RESET_WIDGETS = "RESET_WIDGETS";

export const initWidget = (
  widgetName: WidgetNames,
  initialValues: InitWidgetValues
) => (dispatch: ThunkDispatch, getState: () => AppState) => {
  dispatch({ type: INIT_WIDGET, payload: { widgetName, initialValues } });
};

export const setViewVisible = (widgetName: WidgetNames, visible: boolean) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({
    type: SET_VIEW_VISIBLE,
    payload: { widgetName, visible }
  });
};

export const toggleDrawer = <W extends WidgetNames>(
  config: ToggleDrawerConfig<W>
) => (dispatch: ThunkDispatch, getState: () => AppState) => {
  dispatch({ type: TOGGLE_DRAWER, payload: config });
};

export const resetWidgets = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  return dispatch({ type: RESET_WIDGETS });
};
