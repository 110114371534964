import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import { checkAuthStatus } from "../../util/firebase";
import { isLoggedIn } from "../../redux/auth/auth.actions";
import CONSTANTS from "../../config/constants";

const RestrictedRoute = ({ Component, ...rest }: any) => {
  const pathname = useRef(rest.location.pathname);

  useEffect(() => {
    const url = pathname.current;
    if (url === CONSTANTS.ENTRY_PATH) {
      checkAuthStatus();
    } else {
      checkAuthStatus(url);
    }
  }, []);

  return (
    <Route
      component={(props: any) => {
        return isLoggedIn() ||
          props.location.pathname === CONSTANTS.ENTRY_PATH ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: CONSTANTS.ENTRY_PATH,
              state: { from: props.location }
            }}
          />
        );
      }}
      {...rest}
    />
  );
};

const PublicRoutes = ({ history }: any) => {
  const Entry = lazy(() => import("../../pages/common/Entry/Entry"));
  const Signin = lazy(() => import("../../pages/Signin/Signin"));
  const Page404 = lazy(() => import("../../themeContainers/Page/404"));
  const Page500 = lazy(() => import("pages/500/500"));
  const ForgotPassword = lazy(() =>
    import("../../pages/common/ForgotPassword/ForgotPassword")
  );
  const ResetPassword = lazy(() =>
    import("../../pages/common/ResetPassword/ResetPassword")
  );
  const Settings = lazy(() => import("../../pages/Settings/Settings.page"));
  const Dashboard = lazy(() => import("../../pages/Dashboard/Dashboard"));
  //TODO: check why routes jump every now and then probably due to authentication
  return (
    <ConnectedRouter history={history}>
      <Suspense fallback={null}>
        <Switch>
          <Route exact path={"/500"} component={() => <Page500 />} />
          <Route exact path={"/signin"} component={() => <Signin />} />
          <Route
            exact
            path={"/forgotpassword"}
            component={() => <ForgotPassword />}
          />
          <Route path={"/resetpassword"} component={() => <ResetPassword />} />
          {/* //The Entry path is marked as restricted to allow proper redirection */}
          <RestrictedRoute
            exact
            path={CONSTANTS.ENTRY_PATH}
            Component={Entry}
          />
          <RestrictedRoute path={"/settings"} Component={Settings} />
          <RestrictedRoute path="/app" Component={Dashboard} />
          <Route path={"/"} component={() => <Page404 />} />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  );
};

export default PublicRoutes;
