// Auth helper functions and data
import { buildAccount } from "util/testUtils/builders/sales/account.builder";
import { buildSalesProcess } from "util/testUtils/builders/workspace.builder";
import { buildConfig } from "util/testUtils/builders/workspace.builder";
import { buildWorkspace } from "util/testUtils/builders/workspace.builder";
import { buildSalesConfig } from "util/testUtils/builders/workspace.builder";
import { buildChannel } from "util/testUtils/builders/sales/channel.builder";
import { buildVendor } from "util/testUtils/builders/sales/vendor.builder";
import { genVendors } from "util/testUtils/builders/sales/vendor.builder";
import { SalesProcessTypes, SalesProcess } from "types/workspace.types";
import CONSTANTS from "../../config/constants";

// ----------------------- FUNCTIONS --------------------

export const loadChannels = () => {
  const { SALES_ISSUES } = CONSTANTS;
  const { CONFIG_CHANNELS } = SALES_ISSUES;
  const channels = CONFIG_CHANNELS;
  const channelsBuilded = [];

  for (const channel of channels) {
    channelsBuilded.push(buildChannel(channel));
  }
  return channelsBuilded;
};

export const loadVendors = () => {
  const { SALES_ISSUES } = CONSTANTS;
  const { CONFIG_VENDORS } = SALES_ISSUES;
  const vendors = CONFIG_VENDORS;
  const vendorsBuilded = [];

  for (const vendor of vendors) {
    vendorsBuilded.push(buildVendor(vendor));
  }

  return vendorsBuilded;
};

const account = buildAccount({
  accountId: Number(process.env.REACT_APP_XMART_ACCOUNT_ID)
});

const channels = loadChannels();
const vendors = loadVendors();

const deliveryProcess: SalesProcess["processes"] = {
  start: {
    prerequisites: [],
    transitions: {
      next: ["customers:previews"]
    }
  },
  "customers:previews": {
    prerequisites: [],
    transitions: {
      next: ["customers:info"]
    }
  },
  "customers:info": {
    prerequisites: [],
    transitions: {
      next: ["sales:stores"],
      prev: ["customers:previews"],
      history: ["sales:history"]
    }
  },
  "sales:stores": {
    prerequisites: [],
    transitions: {
      next: ["sales:categories"],
      prev: ["customers:info"]
    }
  },
  "sales:history": {
    prerequisites: [],
    transitions: {
      next: ["sales:order"],
      prev: ["customers:info"],
      new: ["sales:stores"]
    }
  },
  "sales:categories": {
    prerequisites: [],
    transitions: {
      next: ["sales:products"],
      prev: ["sales:stores"],
      cart: ["sales:cart"]
    }
  },
  "sales:products": {
    prerequisites: [],
    transitions: {
      prev: ["sales:categories"],
      details: ["sales:product"],
      cart: ["sales:cart"]
    }
  },
  "sales:product": {
    // En este drawer mostramos el detalle del producto seleccionado
    prerequisites: [],
    transitions: {
      next: ["sales:categories"],
      prev: ["sales:products", "sales:cart"]
    }
  },
  "sales:cart": {
    prerequisites: [],
    transitions: {
      next: ["sales:checkout"],
      prev: ["sales:products", "sales:categories"],
      update: ["sales:product"]
    }
  },
  "sales:checkout": {
    prerequisites: [],
    transitions: {
      next: ["sales:order"],
      prev: ["sales:cart"]
    }
  },
  "sales:order": {
    prerequisites: [],
    transitions: {
      prev: ["customers:previews", "sales:history"]
    }
  },
  "sales:checkoutCash": {
    prerequisites: [],
    transitions: {
      next: ["sales:order"],
      prev: ["sales:checkout"]
    }
  },
  "sales:checkoutCreditCard": {
    prerequisites: [],
    transitions: {
      next: ["sales:creditCardList"],
      prev: ["sales:checkout"]
    }
  },
  "sales:checkoutPayLink": {
    prerequisites: [],
    transitions: {
      next: ["sales:order"],
      prev: ["sales:checkout"]
    }
  },
  "sales:creditCardList": {
    prerequisites: [],
    transitions: {
      next: ["sales:order"],
      prev: ["sales:checkoutCreditCard"],
      new: ["sales:newCreditCard"]
    }
  },
  "sales:newCreditCard": {
    prerequisites: [],
    transitions: {
      next: ["sales:order"],
      prev: ["sales:creditCardList"],
      new: ["sales:checkoutCreditCard"]
    }
  }
};

const salesProcesses = [
  buildSalesProcess({
    type: SalesProcessTypes.DELIVERY,
    processes: deliveryProcess,
    additionalInfo: {
      channels,
      vendors
    }
  })
];

const salesConfig = buildSalesConfig({ account, salesProcesses });
const config = buildConfig({ sales: salesConfig });

export const workspace = buildWorkspace({
  friendlyName: "Develop",
  config
});

// ------------------------- BOOKING PROCESS ------------------------------------------

const onSiteProcess: SalesProcess["processes"] = {
  start: {
    prerequisites: [],
    transitions: {
      next: ["customers:previews"]
    }
  },
  "customers:previews": {
    prerequisites: [],
    transitions: {
      next: ["customers:info"]
    }
  },
  "customers:info": {
    prerequisites: [],
    transitions: {
      next: ["booking:modality"],
      prev: ["customers:previews"],
      history: ["sales:history"]
    }
  },
  "sales:history": {
    prerequisites: [],
    transitions: {
      next: ["sales:order"],
      prev: ["customers:info"],
      new: ["booking:modality"]
    }
  },
  "booking:modality": {
    prerequisites: [],
    transitions: {
      next: ["booking:filters"],
      prev: ["customers:info"]
    }
  },
  "booking:filters": {
    prerequisites: [],
    transitions: {
      next: ["booking:calendar"],
      prev: ["booking:modality"]
    }
  },
  "booking:calendar": {
    prerequisites: [],
    transitions: {
      next: ["sales:categories"],
      prev: ["booking:filters"]
    }
  },
  "sales:categories": {
    prerequisites: [],
    transitions: {
      next: ["sales:products"],
      prev: ["booking:calendar"],
      cart: ["sales:cart"]
    }
  },
  "sales:products": {
    prerequisites: [],
    transitions: {
      prev: ["sales:categories"],
      details: ["sales:product"],
      cart: ["sales:cart"]
    }
  },
  "sales:product": {
    prerequisites: [],
    transitions: {
      next: ["sales:categories"],
      prev: ["sales:products"]
    }
  },
  "sales:cart": {
    prerequisites: [],
    transitions: {
      next: ["sales:checkout"],
      prev: ["sales:products", "sales:categories"]
    }
  },
  "sales:checkout": {
    prerequisites: [],
    transitions: {
      next: ["sales:order"],
      prev: ["sales:cart"]
    }
  },
  "sales:order": {
    prerequisites: [],
    transitions: {
      prev: ["customers:previews", "sales:history"]
    }
  },
  "sales:checkoutCash": {
    prerequisites: [],
    transitions: {
      next: ["sales:order"],
      prev: ["sales:checkout"]
    }
  },
  "sales:checkoutCreditCard": {
    prerequisites: [],
    transitions: {
      next: ["sales:order"],
      prev: ["sales:checkout"]
    }
  },
  "sales:creditCardList": {
    prerequisites: [],
    transitions: {
      next: ["sales:order"],
      prev: ["sales:checkoutCreditCard"],
      new: ["sales:newCreditCard"]
    }
  },
  "sales:newCreditCard": {
    prerequisites: [],
    transitions: {
      next: ["sales:order"],
      prev: ["sales:creditCardList"]
    }
  }
};

const secondaryAccount = buildAccount({ accountId: 4 });
const channelApp = buildChannel({ id: 1, friendlyName: "Metrored" });
const vendorApp = [buildVendor({ id: 1 }), ...genVendors()];

const bookingProcess = [
  buildSalesProcess({
    type: SalesProcessTypes.BOOKING,
    processes: onSiteProcess,
    additionalInfo: {
      channels: [channelApp],
      vendors: vendorApp
    }
  }),
  buildSalesProcess({
    type: SalesProcessTypes.BOOKING,
    active: false,
    additionalInfo: {
      channels: [channelApp],
      vendors: vendorApp
    }
  })
];

const bookingConfig = buildSalesConfig({
  account: secondaryAccount,
  salesProcesses: bookingProcess
});
const secondaryConfig = buildConfig({ sales: bookingConfig });

export const bookingWorkspace = buildWorkspace({
  friendlyName: "Develop",
  config: secondaryConfig
});
