import { FormsState as State, FormsActions as Actions } from "./forms.types";
import { SET_FORMS, SET_FORM, FETCH_INITIAL_VALUES } from "./forms.actions";
import { Form } from "../../components/operator/widgets/FormsWidget/FormsWidget.types";

const initialState: State = {
  forms: []
};

const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case SET_FORMS:
      return { ...state, forms: action.payload };
    case SET_FORM:
      return setForm(state, action.payload);
    case FETCH_INITIAL_VALUES:
      return { ...state, initialValuesData: action.payload };
    default:
      return state;
  }
};

const setForm = (state: State, form: Form) => {
  const forms = state.forms.map((item: Form) => {
    if (form.id === item.id) {
      return form;
    }
    return item;
  });
  return { ...state, forms };
};

export default reducer;
