import {
  SET_WORKER_AUTH_DATA,
  LOGIN_ERROR,
  LOGIN_LOADING,
  SET_WORKER_TWILIO_DATA,
  SET_WORKER_ACTIVITY,
  REMOVE_CALL_TOKEN
} from "./auth.actions";

const initialState = {
  // Agent data coming from Twilio
  worker: null,
  // Error happened during login
  loginError: null,
  // Has not finish loging in
  loading: false
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_ERROR:
      return { ...state, loginError: action.payload };
    case LOGIN_LOADING:
      return { ...state, loading: action.payload };
    case SET_WORKER_AUTH_DATA:
      return updateWorker(state, action.payload);
    case SET_WORKER_TWILIO_DATA:
      return updateWorker(state, action.payload);
    case SET_WORKER_ACTIVITY:
      return setWorkerActivity(state, action.payload);
    case REMOVE_CALL_TOKEN:
      return removeToken(state, "tokenCall");
    default:
      return state;
  }
};

const updateWorker = (state: any, payload: any) => {
  let worker = null;
  if (state.worker) {
    worker = { ...state.worker, ...payload };
  } else {
    worker = { ...payload };
  }
  return { ...state, worker };
};

const setWorkerActivity = (state: any, payload: any) => {
  const worker = {
    ...state.worker,
    activitySid: payload.activitySid,
    activityName: payload.activityName,
    available: payload.available
  };
  return { ...state, worker };
};

const removeToken = (state: any, tokenName: string) => {
  if (!state.worker) {
    return state;
  }
  return { ...state, worker: { ...state.worker, [tokenName]: null } };
};
