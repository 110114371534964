import axios from "axios";
import FileDownload from "js-file-download";
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";

import axiosDefault from "../../util/axios";
import { store } from "../../redux/store";
import { message } from "antd";
import { getAuthorization } from "../../redux/auth/auth.actions";
import {
  TransferChatTaskPayload,
  TransferCallTaskPayload,
  DeleteMemberFromChannelPayload
} from "./track.types";

const ContactHistory = new ApolloClient({
  uri: process.env.REACT_APP_HISTORY
});

/**
 * Appends a new message to media channel (whatapp, sms, mensseger)
 * @param message
 */
export const sendMessageToMediaChannel = async (message: any) => {
  const messageData = message;
  const worker = store.getState().Auth.worker;
  const activeReservation = store.getState().Track.activeReservation();
  const attributes: any = activeReservation.task.attributes;
  const from = attributes.source;
  const authorizationToken = await getAuthorization();
  const { data } = await axiosDefault.post(
    "/api/twilio/message",
    {
      to: messageData.to,
      from,
      body: messageData.body,
      location: messageData.location,
      mediaUrl: messageData.mediaUrl,
      attributes: messageData.attributes
    },
    {
      headers: {
        Authorization: authorizationToken
      }
    }
  );
  if (data) {
    const author = `${worker.name} ${worker.lastName}`;
    addMessageToRemoteConversation({
      ...message,
      attributes: { ...messageData.attributes, author }
    });
  }
};

/**
 * Add a new message to twilio channel
 * @param message
 */
export const addMessageToRemoteConversation = async (messageData: any) => {
  try {
    const authorizationToken = await getAuthorization();
    await axiosDefault.post(
      "/api/twilio/channel/messages",
      {
        channelSid: messageData.channelSid,
        from: messageData.from,
        body: messageData.body,
        attributes: messageData.attributes
      },
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
  } catch (e) {
    console.log(e.message);
  }
};

/**
 * Get the messages of a channel
 * @param channelSid
 */
export const fetchMessagesByChannel = async (channelSid: string) => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get(
      `/api/twilio/channel/messages?channelSid=${channelSid}`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    if (data.error) {
      throw new Error(data.error.message);
    }
    return data;
  } catch (e) {
    console.error(e.message);
    return [];
  }
};

export const download = async (mediaUrl: string) => {
  try {
    await axios
      .get(mediaUrl, {
        headers: {
          "Content-Disposition":
            "attachment;filename=fc70027979f59400fd0796e961785e5e.png",
          "Content-Type": "application/octet-stream",
          "Access-Control-Allow-Origin": "localhost:3000",
          // 'Access-Control-Allow-Credentials': 'false',
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Access-Control-Request-Headers": "*",
          "Access-Control-Allow-Headers":
            "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
        }
      })
      .then(response => {
        FileDownload(response.data, "fc70027979f59400fd0796e961785e5e.png");
      });
  } catch (e) {
    console.log("ERROR al descargar el archivo", e);
  }
};

export const updateContactIdHistoricalRegister = (
  oldContactId: string,
  newContactId: string
) => {
  const MUTATION_QUERY = gql`
      mutation{
      updateAttribute(
        property: "contactId",
        oldValue:"${oldContactId}",
        newValue:"${newContactId}",
      ){
        status
      }
    }
  `;

  return ContactHistory.mutate({
    mutation: MUTATION_QUERY,
    fetchPolicy: "no-cache"
  })
    .then(res => {
      return true;
    })
    .catch(err => {
      return false;
    });
};

export const makeCall = async (phone: string) => {
  try {
    const authorizationToken = await getAuthorization();
    const worker = store.getState().Auth.worker;
    if (worker.activityName !== "Available") {
      message.error("El operador debe estar habilitado");
      return null;
    }

    await axiosDefault.post(
      "/api/twilio/call",
      {
        phone: phone,
        workerSid: worker.sid
      },
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
  } catch (e) {
    console.log("ERROR al realizar la llamada", e);
  }
};

export const createCallTask = async (phone: string, callSid: string) => {
  try {
    const authorizationToken = await getAuthorization();
    const worker = store.getState().Auth.worker;
    if (worker.activityName !== "Available") {
      message.error("El operador debe estar habilitado");
      return null;
    }

    await axiosDefault.post(
      "api/twilio/call/taskOut",
      {
        phone: phone,
        workerSid: worker.sid,
        callSid
      },
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
  } catch (e) {
    console.log("ERROR al realizar la llamada", e);
  }
};

export const makeChatOut = async (
  to: string,
  from: string,
  typeMediaChannel: string
) => {
  try {
    const authorizationToken = await getAuthorization();
    const worker = store.getState().Auth.worker;
    if (worker.activityName !== "Available") {
      message.error("El operador debe estar habilitado");
      return null;
    }

    await axiosDefault.post(
      "/api/twilio/flow/outChat",
      {
        to: to,
        from: from,
        typeMedia: typeMediaChannel,
        workerSid: worker.sid
      },
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
  } catch (e) {
    if (e.response?.status === 409) {
      message.warn("Canal se encuentra ocupado", 6);
    } else {
      console.log(e.response?.data?.message);
    }
  }
};

export const getCallInfo = async (callSid: string) => {
  try {
    const authorizationToken = await getAuthorization();
    const call = await axiosDefault.get(`/api/twilio/call?callSid=${callSid}`, {
      headers: {
        Authorization: authorizationToken
      }
    });
    return call.data;
  } catch (e) {
    console.log("ERROR al obtener datos de la llamada", e);
  }
};

// Cancel a call before the call is answer by the callee
export const cancelCall = async (callSid: string, taskSid: string) => {
  try {
    const authorizationToken = await getAuthorization();
    await axiosDefault.delete(
      `/api/twilio/call?callSid=${callSid}&taskSid=${taskSid}`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
  } catch (e) {
    //ignore
  }
};

// Transfer a call task to another worker
export const transferCallTask = async (payload: TransferCallTaskPayload) => {
  const { callSid, workerSid, phone, channelSid } = payload;

  try {
    const authorizationToken = await getAuthorization();
    return await axiosDefault.post(
      `/api/twilio/call/refer`,
      {
        callSid,
        to: workerSid,
        type: "INTERNAL",
        from: phone,
        channelSid
      },
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
  } catch (e) {
    //ignore
  }
};

// Transfer a chat task to another worker
export const transferChatTask = async (payload: TransferChatTaskPayload) => {
  const { workerSid, from, channelSid, mediaChannel, source } = payload;

  try {
    const authorizationToken = await getAuthorization();
    return await axiosDefault.post(
      `/api/twilio/task/transfer`,
      {
        to: workerSid,
        from,
        channelSid,
        mediaChannel,
        source
      },
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
  } catch (e) {
    //ignore
  }
};

// Transfer a chat task to another worker
export const deleteMemberFromChannel = async (
  payload: DeleteMemberFromChannelPayload
) => {
  const { channelSid, memberSid } = payload;

  try {
    const authorizationToken = await getAuthorization();
    return await axiosDefault.delete(`/api/twilio/member`, {
      data: {
        channelSid,
        memberSid
      },
      headers: {
        Authorization: authorizationToken
      }
    });
  } catch (e) {
    //ignore
  }
};

// Transfer a chat task to another worker
export const rejectWorkerReservations = async (workerSid: string) => {
  try {
    const authorizationToken = await getAuthorization();
    return await axiosDefault.post(
      `/api/twilio/reservation/rejectAll`,
      {
        workerSid
      },
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
  } catch (e) {
    message.error("Error al rechazar las reservaciones", 8);
  }
};

export const getUsersAndTasks = async (): Promise<any> => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get(
      `/api/twilio/report/tasksByWorker`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    return data;
  } catch (e) {
    message.error("Error al consultar las tareas por agente");
  }
};

export const getDayData = async (workerId: string): Promise<any> => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get(
      `/api/twilio/report/summaryTotalsTask?workerSid=${workerId}`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    return data;
  } catch (e) {
    message.error("Error al consultar los datos del día");
  }
};
