// Service Issue reducer helper methods
import { IssuesState } from "./issues.types";
import { Module, Box, Issue, IssuePages } from "types/issues.types";

export const getSelectedModule = (state: IssuesState): Module | undefined => {
  return state.modules?.find(module => {
    return module.id === state.selectedModuleId;
  });
};

export const getSelectedBox = (state: IssuesState): Box | undefined => {
  const module = getSelectedModule(state);
  return module?.boxes?.find(box => {
    return box.id === state.selectedBoxId;
  });
};

// find and return a module
export const getModule = (
  state: IssuesState,
  moduleId: string
): { module: Module; moduleIndex: number; modules: Module[] } => {
  if (!state.modules) {
    throw new Error(
      `Modules should be initialized before attempting to save a box`
    );
  }
  const index = state.modules?.findIndex(module => module.id === moduleId);
  if (index === -1) {
    throw new Error(`Module id ${moduleId} does not exist on Modules`);
  }
  const modules = [...state.modules];
  const newModule = { ...modules[index] };
  return { module: newModule, moduleIndex: index, modules };
};

// find and return a box
export const getBox = (
  state: IssuesState,
  moduleId: string,
  boxId: string
): {
  box: Box;
  boxIndex: number;
  boxes: Box[];
  module: Module;
  moduleIndex: number;
  modules: Module[];
} => {
  const { module, moduleIndex, modules } = getModule(state, moduleId);
  const index = module.boxes?.findIndex(box => box.id === boxId);
  if (typeof index === "undefined" || index === -1 || !module) {
    throw new Error(
      `Box id ${moduleId} does not exist on Boxes of module ${moduleId}`
    );
  }
  if (!module.boxes) {
    throw new Error("To get a box you must initialize boxes first");
  }
  const boxes = [...module.boxes];
  const newBox = { ...boxes[index] };
  return { box: newBox, boxIndex: index, boxes, module, moduleIndex, modules };
};

// find and return a issue page
export const getIssuesPage = (
  state: IssuesState,
  moduleId: string,
  boxId: string,
  page: number
): {
  issuePage: Issue[] | undefined;
  issuePages: IssuePages;
  box: Box;
  boxIndex: number;
  boxes: Box[];
  module: Module;
  moduleIndex: number;
  modules: Module[];
} => {
  const getBoxRes = getBox(state, moduleId, boxId);
  const box = getBoxRes.box;
  const pages = Object.entries(box.issuePages);
  const oldPage = pages.find(issuePage => {
    return issuePage[0] === page.toString();
  });
  return {
    issuePage: oldPage ? oldPage[1] : undefined,
    issuePages: box.issuePages,
    ...getBoxRes
  };
};

export const insertInModule = (
  state: IssuesState,
  moduleId: string,
  payload: Partial<Module>
): { modules: Module[]; module: Module; moduleIndex: number } => {
  const { module, moduleIndex, modules } = getModule(state, moduleId);
  const newModule = { ...module, ...payload };
  const newModules = [...modules!];
  newModules[moduleIndex] = newModule;
  return { modules: newModules, module: newModule, moduleIndex };
};

export const insertInBox = (
  state: IssuesState,
  moduleId: string,
  boxId: string,
  payload: Partial<Box>
): { boxes: Box[]; box: Box; boxIndex: number } => {
  const { box, boxIndex, boxes } = getBox(state, moduleId, boxId);
  const newBox = { ...box, ...payload };
  const newBoxes = [...boxes];
  newBoxes[boxIndex] = newBox;
  return { boxes: newBoxes, box: newBox, boxIndex };
};

export const insertInIssues = (
  state: IssuesState,
  moduleId: string,
  boxId: string,
  page: number,
  newIssues: Issue[]
): { issuePages: IssuePages } => {
  const { issuePages } = getIssuesPage(state, moduleId, boxId, page);
  const newIssuePages = { ...issuePages };
  newIssuePages[page] = newIssues;
  return { issuePages: newIssuePages };
};
