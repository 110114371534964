// Orders builder functions
import { genNumericId } from "../common.builder";
import { genNumber, genPrices, fillNumber, genId } from "../common.builder";
import { chooseRandomEnum, getRandomDate } from "../common.builder";
import { Order } from "types/orders.types";
import { OrderShoppingCart } from "types/orders.types";
import { PaymentMethodsObject } from "types/orders.types";
import { ORDER_TYPES } from "types/orders.types";
import { buildAddress } from "../address/address.builder";
import { AllowedPaymentMethods } from "../../../../types/orders.types";
export const buildOrder = (overrides: Partial<Order> = {}): Order => {
  return {
    id: genNumericId(),
    seqVal: genId(),
    shippingAddress: buildAddress(),
    shoppingCart: buildOrderShoppingCart(),
    orderDate: getRandomDate,
    orderTime: getRandomDate,
    ...overrides
  };
};

export const buildOrderShoppingCart = (
  overrides: Partial<OrderShoppingCart> = {}
): OrderShoppingCart => {
  return {
    billTotal: genPrices(),
    paidPaymentMethods: genPaymentMethods(),
    rejectedPaymentMethods: genPaymentMethods(),
    ...overrides
  };
};

export const buildPaymentMethod = (
  overrides: Partial<PaymentMethodsObject> = {}
): PaymentMethodsObject => {
  return {
    payment_method_id: genNumericId(),
    payment_method_name: chooseRandomEnum(AllowedPaymentMethods),
    ...overrides
  };
};

export const genPaymentMethods = (
  quantity?: number
): PaymentMethodsObject[] => {
  const num = quantity ?? Math.floor(Math.random()) + 1;

  const PaymentMethods = [];
  for (let i = 0; i < num; i++) {
    const PaymentMethod = buildPaymentMethod();
    PaymentMethods.push(PaymentMethod);
  }
  return PaymentMethods;
};

export const genOrdersMocked = (quantity?: number): Order[] => {
  const num = quantity ?? Math.floor(Math.random() * 9) + 1;

  const orders = [];
  for (let i = 0; i < num; i++) {
    const order = buildOrder();
    orders.push(order);
  }
  return orders;
};

const chooseBuilder = (type: ORDER_TYPES, overrides: Partial<Order>) => {
  switch (type) {
    case ORDER_TYPES.ISSUE:
      return buildOrder(overrides);
    case ORDER_TYPES.TICKET:
      return buildOrder(overrides);
    case ORDER_TYPES.ORDERS:
      return buildOrder(overrides);
    default:
      throw new Error(`Type ${type} is not a supported issue type`);
  }
};

export const genOrders = <T extends Order>(
  quantity?: number,
  type: ORDER_TYPES = ORDER_TYPES.ISSUE,
  overrides: Partial<T> = {}
): T[] => {
  const num = quantity ?? Math.floor(Math.random() * 29) + 1;
  const Issues = [];
  const maxIssueId = 300;
  let currentPointer = 0;
  for (let i = 0; i < num; i++) {
    const loopMax = maxIssueId - num + i;
    const loopMin =
      currentPointer + 1 <= loopMax ? currentPointer + 1 : loopMax;
    currentPointer = genNumber({ min: loopMin, max: loopMax });
    const newIssueId = fillNumber(String(currentPointer), maxIssueId);
    const Issue = chooseBuilder(type, { issueId: newIssueId, ...overrides });
    Issues.push(Issue);
  }
  return Issues as T[];
};
