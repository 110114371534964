import React, { useEffect } from "react";
import "firebase/auth";
import { Provider } from "react-redux";
import { message, notification } from "antd";
import faker from "faker";
import moment from "moment";
import "moment/locale/es";

import { store, history } from "redux/store";
import PublicRoutes from "./router";
import { ThemeProvider } from "styled-components";
import themes from "themeSettings/themes";
import { themeConfig } from "themeSettings";
import DashAppHolder from "./AppStyle";
import { ErrorBoundary as ErrorBoundryBugsnag } from "util/bugsnag";
import CONSTANTS from "config/constants";
import "config/firebase";
import NetworkDetector from "util/hocs/NetworkDetector/NetworkDetector";

const App: React.FC<any> = props => {
  const env = process.env.REACT_APP_ENV as string;
  const includedEnv = CONSTANTS.INCLUDED_LOGGER_ENVS.includes(env);

  useEffect(() => {
    moment.locale("es");
    faker.locale = "es";
    message.config({
      top: 50,
      duration: 2,
      maxCount: 4
    });
    notification.config({
      top: 50,
      duration: 0
    });
  }, []);

  const app = (
    <ThemeProvider theme={themes[themeConfig.theme]}>
      <DashAppHolder>
        <Provider store={store}>
          <PublicRoutes history={history} />
        </Provider>
      </DashAppHolder>
    </ThemeProvider>
  );

  return includedEnv ? <ErrorBoundryBugsnag>{app}</ErrorBoundryBugsnag> : app;
};

export default NetworkDetector(App);
