import { css } from "styled-components";

const animations = css`
  @keyframes slide-in-top {
    0% {
      transform: translateY(
        calc(-1 * var(--sizes-inbox-callReservation-height))
      );
    }

    100% {
      transform: translateY(0%);
    }
  }

  @keyframes grow-x {
    0% {
      transform: scale(0, 1);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes grow-x-pointer {
    0% {
      left: 0%;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }

  @keyframes hide-location-menu {
    0% {
      height: 44rem;
      width: 25rem;
    }
    100% {
      height: 7.4rem;
      width: 3.8rem;
    }
  }

  @keyframes hide-location-menu-inputs {
    0% {
      transform: translate(0);
    }
    100% {
      transform: translate(25%, -0.8rem);
    }
  }

  @keyframes hide-location-menu-body {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    70% {
      opacity: 0;
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes move-x {
    0% {
      transform: rotateZ(-30deg);
    }
    100% {
      transform: rotateZ(-30deg) translateX(2500%);
    }
  }

  @keyframes move-x2 {
    0% {
      transform: rotateZ(-30deg);
    }
    100% {
      transform: rotateZ(30deg) translateX(2000%);
    }
  }

  @keyframes move-x3 {
    0% {
      transform: rotateZ(-30deg);
    }
    100% {
      transform: rotateZ(30deg) translateX(1500%);
    }
  }

  @keyframes move-x4 {
    0% {
      transform: rotateZ(-30deg) translateX(-150%);
    }
    100% {
      transform: rotateZ(30deg) translateX(1200%);
    }
  }

  @keyframes shine {
    0% {
      transform: rotateZ(-30deg) translateX(-150%);
    }
    100% {
      transform: rotateZ(30deg) translateX(900%);
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    750% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default animations;
