// Product services
import { Product, ProductDetails } from "types/sales/product.types";
import { buildProduct } from "util/testUtils/builders/sales/product.builder";
import { Category } from "types/sales/category.types";
import CONSTANTS from "config/constants";
import { PaginationRes } from "types/common.types";
import { buildProductDetails } from "util/testUtils/builders/sales/productDetails.builder";
import { CategoryByStoreRes } from "./product.service.types";
import { message } from "antd";
import { axiosXmart } from "util/axios";
import { getAuthorization } from "redux/auth/auth.actions";

const {
  CATEGORY_BY_STORE,
  PLATFORM,
  CLIENT_VERSION,
  PRODUCTS
} = CONSTANTS.SALES_ISSUES;

const { DEFAULT_PRODUCTS_SIZE } = CONSTANTS;

const headers = {
  Platform: PLATFORM,
  "client-version": CLIENT_VERSION
};

export const fetchRecommendedProduct = (): Promise<Product> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // TODO: fetch recommended product from admin
      const recommendedProduct: Product = buildProduct();
      resolve(recommendedProduct);
    }, 1000);
  });
};

export const fetchProductsByName = async (
  page: number,
  storeId: number,
  channelId: number,
  accountId: number,
  query: string
): Promise<PaginationRes<Product> | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${PRODUCTS}?storeId=${storeId}&channelId=${channelId}&account=${accountId}&query=${query}`;
    const result = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { products, totalPages } = result.data.data;
    return { page, totalPages, items: products };
  } catch (e) {
    message.warn("Ocurrió un error al consultar los productos por nombre");
    return;
  }
};

export const fetchProductsByCategory = async (
  page: number,
  storeId: number,
  channelId: number,
  categoryId: number
): Promise<PaginationRes<Product> | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${PRODUCTS}?categoryId=${categoryId}&storeId=${storeId}&channelId=${channelId}&size=${DEFAULT_PRODUCTS_SIZE}`;
    const result = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { totalPages } = result.data;
    const { products } = result.data.data;
    return { page, totalPages, items: products };
  } catch (e) {
    message.error("Ocurrió un error al consultar los productos por categoría");
    return;
  }
};

export const fetchCategoriesByStore = async (
  page: number,
  accountId: number,
  storeId: number,
  channelId: number,
  query?: string
): Promise<PaginationRes<Category> | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    let url = "";
    if (typeof query === "undefined") {
      url = `/${CATEGORY_BY_STORE}?storeId=${storeId}&channelId=${channelId}`;
    } else {
      url = `/${CATEGORY_BY_STORE}?storeId=${storeId}&channelId=${channelId}&query=${query}`;
    }
    const result: CategoryByStoreRes = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data } = result.data;
    return { page, totalPages: 1, items: data };
  } catch (e) {
    message.error(
      `Ocurrió un error al consultar las categorías de la tienda con id ${storeId}`
    );
    return;
  }
};

export const fetchProductDetails = async (
  accountId: number,
  channelId: number,
  storeId: number,
  productId: number
) => {
  try {
    const authorizationToken = await getAuthorization();
    const url = `/${PRODUCTS}/${productId}?storeId=${storeId}&channelId=${channelId}&accountId=${accountId}`;
    const result = await axiosXmart.get(url, {
      headers: {
        Authorization: authorizationToken,
        ...headers
      }
    });
    const { data } = result.data;
    return data as ProductDetails;
  } catch (e) {
    throw new Error(
      `Failed to fetch product detail by store of storeId ${storeId} and channel of channelId ${channelId}`
    );
  }
};

export const fetchProductDetailsMocked = (
  accountId: number,
  channelId: number,
  storeId: number,
  categoryId: number,
  productId: number
): Promise<ProductDetails> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(buildProductDetails());
    }, 1000);
  });
};
