// A representation of a Task from task queue
export interface QueuedTaskBody {
  id?: number;
  contactName?: string;
  documentType?: string;
  document?: string;
  detailedName?: DetailedName;
  companyName?: string;
  phones?: Array<MediaChannel>;
  emails?: Array<MediaChannel>;
  addresses?: Array<MediaChannel>;
  preferredChannel?: string;
  leadAdditionalInfo?: any;
  lastContactedDate?: any;
  batch?: CampaignBatch;
  phone?: never;
  whatsappId?: string[];
  facebookId?: string[];
}

export enum QueuedTaskDirections {
  OUT = "OUT",
  IN = "IN"
}

export enum QueuedTaskTypes {
  LEADS = "LEADS",
  CONTACT = "CONTACTS",
  ANONYMOUS = "ANONYMOUS",
  SERVICE = "SERVICE",
  COLLECT = "COLLECT",
  SALES = "SALES",
  RESCHEDULED = "RESCHEDULED"
}

export interface QueuedTaskHeader {
  type: QueuedTaskTypes;
  direction: QueuedTaskDirections;
}

export interface QueuedTaskOptions {
  delay?: number;
  priority?: number;
}

export interface QueuedTask {
  taskBody: {
    header: QueuedTaskHeader;
    body: QueuedTaskBody;
  };
  options: QueuedTaskOptions;
}

export interface QueuedTaskReq {
  id: string;
  data: {
    header: QueuedTaskHeader;
    body: QueuedTaskBody;
  };
  options: QueuedTaskOptions;
}

interface MediaChannel {
  key: string;
  value: string;
}

interface CampaignBatch {
  id: number;
  name: string;
  product?: Product;
}

interface Product {
  id: number;
  name: string;
}

interface DetailedName {
  firstName: string;
  middleName: string;
  lastName: string;
  secondLastName: string;
}
