import axios from "axios";
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import moment from "moment";
import { message } from "antd";

import { store } from "../../redux/store";
import CONSTANTS from "../../config/constants";
import axiosDefault from "../../util/axios";
import { getReservationById } from "../../util/track.util";
import { getAuthorization } from "../auth/auth.actions";
import { QueuedTaskReq } from "types/taskQueue.types";
import { makeCall } from "services/track/track";
import { decorate } from "components/global/Softphone/Softphone.helpers";
import { isCallReservation } from "util/reservationAssertions";
import {
  Reservation,
  ReservationTwilio,
  RawReservation
} from "types/reservation.types";
import { Dispatch } from "redux";

export const SET_RESERVATIONS = "SET_RESERVATIONS";
export const ADD_RESERVATION = "ADD_RESERVATION";
export const REMOVE_RESERVATION = "REMOVE_RESERVATION";
export const UPDATE_RESERVATION = "UPDATE_RESERVATION";
export const UPDATE_STATIC_RESERVATION = "UPDATE_STATIC_RESERVATION";
export const SET_ACTIVE_RESERVATION = "SET_ACTIVE_RESERVATION";
export const WORKER_ACTIVITIES = "WORKER_ACTIVITIES";
export const SET_CALL_STATUS = "SET_CALL_STATUS";
export const SET_SCHEDULED_TASK = "SET_SCHEDULED_TASK";
export const SET_SCHEDULED_TASK_AVAILABLE = "SET_SCHEDULED_TASK_AVAILABLE";
export const SET_LOADING_RESERVATIONS = "SET_LOADING_RESERVATIONS";
export const TICK_RINGING_TIMER = "TICK_RINGING_TIMER";
export const RESET_RINGING_TIMER = "RESET_RINGING_TIMER";
export const SET_TRANSFERING = "SET_TRANSFERING";
export const REMOVE_CHANNEL = "REMOVE_CHANNEL";
export const ADD_CHANNEL = "ADD_CHANNEL";
export const SET_CHECK_CALL_RESERVATION = "SET_CHECK_CALL_RESERVATION";

const ContactHistory = new ApolloClient({
  uri: process.env.REACT_APP_HISTORY
});

export const setScheduledTask = (task: QueuedTaskReq) => (dispatch: any) => {
  dispatch({ type: SET_SCHEDULED_TASK, payload: task });
};

export const setScheduledTaskAvailable = (available: boolean) => (
  dispatch: any
) => {
  dispatch({ type: SET_SCHEDULED_TASK_AVAILABLE, payload: available });
};

export const addReservation = (reservation: Reservation) => (dispatch: any) => {
  dispatch({ type: ADD_RESERVATION, payload: reservation });
};

export const removeReservation = (reservationSid: string) => (
  dispatch: any
) => {
  dispatch({ type: REMOVE_RESERVATION, payload: reservationSid });
};

export const updateReservationById = (
  reservationSid: string,
  field: string,
  value: any
) => (dispatch: any, getState: any) => {
  const reservation = getState().Track.reservations.find(
    (res: any) => res.sid === reservationSid
  );
  updateReservation(reservation, field, value);
};

export const updateReservation = (
  reservation: Reservation,
  field: string,
  value: any
) => (dispatch: any) => {
  dispatch({
    type: UPDATE_RESERVATION,
    payload: { reservation, field, value }
  });
};

export const updateStaticReservation = (
  reservation: Reservation,
  field: string,
  value: any
) => (dispatch: any) => {
  dispatch({
    type: UPDATE_STATIC_RESERVATION,
    payload: { reservation, field, value }
  });
};

/**
 * Set reservations list in reducer
 * @param list
 */
export const setReservations = (list: any) => (dispatch: any) => {
  dispatch({ type: SET_RESERVATIONS, payload: list });
};

/**
 * Set the active reservation in reducer
 */
export const setActiveReservation = (reservationSid: string | null) => (
  dispatch: any
) => {
  dispatch({ type: SET_ACTIVE_RESERVATION, payload: reservationSid });
};

/**
 * Update the task's status
 * @param data
 */
export const updateTask = (data: any, reservation: Reservation) => async (
  dispatch: any
) => {
  let success = true;
  if (!reservation || !getReservationById(reservation.sid)) {
    return;
  }
  try {
    const authorizationToken = await getAuthorization();
    await axiosDefault.put(
      `/api/twilio/task/status`,
      {
        taskSid: data.taskSid,
        stare: data.stare
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizationToken
        }
      }
    );
    // message.success("Tarea completada con éxito");
  } catch (e) {
    // ignore
    success = false;
  }
  const task = { ...reservation.task };
  task.assignmentStatus = data.stare;
  dispatch(updateReservation(reservation, "task", task));
  return success;
};

/**
 * Update client's data with graphql
 * @param {*} data
 */
export const closeChannelHistory = (data: any) => {
  const CHAT_HISTORY = gql`mutation {
        createRegister(channelId:"${data.channelId}",type:"${data.type}",contactId:"${data.contactId}",agent:"${data.agent}")
      {
        status
      }
    }`;
  return () => {
    //TODO: manejar bien la promesa
    ContactHistory.mutate({
      mutation: CHAT_HISTORY,
      fetchPolicy: "no-cache"
    })
      .then(() => {})
      .catch(e => console.log(e.message));
  };
};

/**
 * Accept or reject a reservation by a worker
 * @param data
 */
export const acceptOrRejectReservation = (
  reservation: Reservation,
  stare: string,
  workerSid: string
) => async (dispatch: any) => {
  try {
    const authorizationToken = await getAuthorization();
    await axiosDefault.put(
      "/api/twilio/reservation/status",
      {
        workerSid: workerSid,
        reservationSid: reservation.sid,
        stare: stare
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizationToken
        }
      }
    );

    const accepted = CONSTANTS.RESERVATION_STATUS.ACCEPTED;
    if (stare === accepted) {
      const channel = { ...reservation.channel };
      const collectionMessages = [...channel.messages];
      const collection: any = collectionMessages.filter(
        (message: any) => "sid" in message
      );
      dispatch(updateMessageAttributes(channel.sid, collection));

      const reservationUpdated: any = { ...reservation, unread: 0 };
      dispatch(
        updateReservation(reservationUpdated, "reservationStatus", accepted)
      );
      dispatch(setActiveReservation(reservationUpdated.sid));
      message.success("Tarea aceptada");
    } else {
      message.success("Tarea rechazada");
    }
    return true;
  } catch (error) {
    console.log(error);
    // message.error("Error al actualizar el estado de la tarea");
    return false;
  }
};

/**
 *
 * @param resourceUrl
 */
export const getTaskByWorker = async (resourceUrl: any) => {
  try {
    //Get reservation
    const response1: any = await axios.get(
      `${resourceUrl}/Reservations?PageSize=1000&Page=0&ReservationStatus=accepted`,
      {
        auth: {
          username: `${process.env.REACT_APP_TRACK_ACCOUNT_SID}`,
          password: `${process.env.REACT_APP_TRACK_ACCOUNT_PASSWORD}`
        }
      }
    );

    const response2: any = await axios.get(
      `${resourceUrl}/Reservations?PageSize=1000&Page=0&ReservationStatus=pending`,
      {
        auth: {
          username: `${process.env.REACT_APP_TRACK_ACCOUNT_SID}`,
          password: `${process.env.REACT_APP_TRACK_ACCOUNT_PASSWORD}`
        }
      }
    );

    if (response1 || response2) {
      const reservations = [
        ...response1.data.reservations,
        ...response2.data.reservations
      ];

      //Get task info
      const promises = reservations.map(
        async (reservation: ReservationTwilio): Promise<RawReservation> => {
          const response: any = await axios.get(`${reservation.links.task}`, {
            auth: {
              username: `${process.env.REACT_APP_TRACK_ACCOUNT_SID}`,
              password: `${process.env.REACT_APP_TRACK_ACCOUNT_PASSWORD}`
            }
          });
          const task = { ...response.data };
          let attributes = task.attributes;
          if (typeof task.attributes === "string") {
            attributes = JSON.parse(attributes);
          }
          task.assignmentStatus = !task.assignmentStatus
            ? task.assignment_status
            : task.assignmentStatus;
          task.attributes = attributes;
          reservation.task = task;

          return reservation;
        }
      );

      const rawReservations: RawReservation[] = await Promise.all(promises);
      return rawReservations.filter(reservation => {
        return reservation.task.assignmentStatus !== "completed";
      });
    }
  } catch (e) {
    console.log(e.message);
    return [];
  }
};

/**
 * Get all posibles activities a worker could have
 */
export const getWorkerActivities = () => async (
  dispatch: any,
  getState: Function
) => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get("/api/twilio/activities", {
      headers: {
        Authorization: authorizationToken
      }
    });
    if (data) {
      const activities = data;
      const worker = await getState().Auth.worker;

      // If current worker activity is offline, we change it to available
      if (worker.activityName === CONSTANTS.OFFLINE_ACTIVITY_NAME) {
        const availableActivity = activities.find(
          (activity: any) =>
            activity.friendlyName === CONSTANTS.AVAILABLE_ACTIVITY_NAME
        );
        const body = {
          workerSid: worker?.sid,
          activitySid: availableActivity?.sid
        };
        await updateWorkerStatus(body);
      }
      dispatch({ type: WORKER_ACTIVITIES, payload: activities });
      return;
    }
    throw new Error("No activities were found");
  } catch (e) {
    console.log("Falló la busqueda de las actividades");
    message.error("Ocurrio un error, refresque la página por favor");
  }
};

export const updateWorkerStatus = async (body: any) => {
  const authorizationToken = await getAuthorization();
  const worker = store.getState().Auth.worker;
  if (!body.activitySid) {
    return;
  }
  try {
    const { data } = await axiosDefault.put(
      `/api/twilio/worker/status`,
      {
        workerSid: body.workerSid ?? worker.sid,
        activitySid: body.activitySid
      },
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    if (!data.res || data.res.activitySid !== body.activitySid) {
      //TODO: reactivar
      // message.warning("Tienes tareas asignadas pendientes", 8);
      return false;
    }
    message.info("Estado actualizado exitosamente");
    return true;
  } catch (e) {
    // TODO: check if delete or not
    // message.error("Su estado no pudo ser actualizado, inténtelo de nuevo", 8);
    return false;
  }
};

export const addMessageToLocalConversation = (
  reservationSid: string,
  message: any
) => (dispatch: any, getState: any) => {
  const aux = message;
  const reservation = getReservationById(reservationSid);
  if (!reservation) {
    // console.log("problem", reservationSid);
    return;
  }
  const dateCreated = moment(message.timestamp).toISOString();
  const newMessage = {
    from: aux.from ?? aux.author,
    dateCreated,
    type: "text",
    typeChannel: reservation.channel.mediaChannel,
    ...message,
    body: message.body
  };
  const messages = [...reservation.channel.messages, newMessage];
  const channel = { ...reservation.channel, messages };
  dispatch(updateReservation(reservation, "channel", channel));
  if (reservation.sid === getState().Track.activeReservation()?.sid) {
    dispatch(setActiveReservation(reservation.sid));
  }
};

export const updateMessageAttributes = (
  channelSid: string,
  messages: any[]
) => async (dispatch: any) => {
  const authorizationToken = await getAuthorization();
  const { data } = await axiosDefault.put(
    "/api/twilio/message",
    {
      channelSid,
      messages
    },
    {
      headers: {
        Authorization: authorizationToken
      }
    }
  );
  return data;
};

export const setCallStatus = (
  field?: string,
  options: any = {},
  keep = false
) => (dispatch: any, getState: any) => {
  const oldState = getState().Track.call;
  // Reset call and add new options
  let call = {
    callingIn: false,
    callingOut: false,
    inCall: false,
    wrapping: false,
    options: { ...oldState.options, ...options }
  };

  // Only override options
  if (keep) {
    dispatch({
      type: SET_CALL_STATUS,
      payload: { ...oldState, options: { ...oldState.options, ...options } }
    });
    return;
  }
  // Set to true only the given field, the rest is false
  if (field) {
    call = { ...call, [field]: true };
  }
  dispatch({ type: SET_CALL_STATUS, payload: call });
};

export const makeCallOutsideSoftphone = (phone: string) => async (
  dispatch: any,
  getState: any
) => {
  const callRes = getState().Track.reservations.find((res: any) =>
    isCallReservation(res)
  );
  if (callRes) {
    message.warn("Para crear una nueva llamada, termine la anterior", 5);
    return;
  }
  await makeCall(phone);
  const decorated = decorate(phone);
  dispatch(
    setCallStatus(undefined, {
      phone: decorated,
      lastPhone: decorated,
      softphoneClose: false,
      softphoneOpen: true
    })
  );
};

export const setLoadingReservations = (count: number) => (dispatch: any) => {
  dispatch({ type: SET_LOADING_RESERVATIONS, payload: count });
};

export const increaseLoadingReservations = () => (
  dispatch: any,
  getState: any
) => {
  const lastCount = getState().Track.loadingReservations;
  const newCount = lastCount + 1;
  dispatch({ type: SET_LOADING_RESERVATIONS, payload: newCount });
};

export const decreaseLoadingReservations = () => (
  dispatch: any,
  getState: any
) => {
  const lastCount = getState().Track.loadingReservations;
  const newCount = lastCount > 0 ? lastCount - 1 : 0;
  dispatch({ type: SET_LOADING_RESERVATIONS, payload: newCount });
};

export const tickRingingTimer = (reset = false) => (dispatch: any) => {
  if (reset) {
    dispatch({ type: RESET_RINGING_TIMER });
    return;
  }
  dispatch({ type: TICK_RINGING_TIMER });
};

export const setTransfering = (value: boolean) => (dispatch: Dispatch<any>) => {
  dispatch({ type: SET_TRANSFERING, payload: value });
};

export const checkCallReservation = (value: boolean) => (
  dispatch: Dispatch<any>
) => {
  dispatch({ type: SET_CHECK_CALL_RESERVATION, payload: value });
};

export const removeChannel = (channelSid: string) => (
  dispatch: Dispatch<any>
) => {
  dispatch({ type: REMOVE_CHANNEL, payload: channelSid });
};

export const addChannel = (channelSid: string) => (dispatch: Dispatch<any>) => {
  dispatch({ type: ADD_CHANNEL, payload: channelSid });
};
