import { WidgetsState as State } from "./widgets.types";
import { WidgetsActions as Actions } from "./widgets.types";
import { RESET_WIDGETS, INIT_WIDGET, TOGGLE_DRAWER } from "./widgets.actions";
import { SET_VIEW_VISIBLE } from "./widgets.actions";
import { initWidget, resetWidgets } from "./widget.reducer.helpers";
import { setViewVisible, toggleDrawer } from "./widget.reducer.helpers";
import { contactsInitialData } from "./contacts/widgets.contacts.helpers";
import { commentsInitialData } from "./comments/widgets.comments.helpers";
import { formsInitialData } from "./forms/widgets.forms.helpers";
import { leadsInitialData } from "./leads/widgets.leads.helpers";
import { customersInitialData } from "./customers/widgets.customers.helpers";
import { salesInitialData } from "./sales/widgets.sales.helpers";

const initialState: State = {
  path: [],
  contacts: contactsInitialData,
  comments: commentsInitialData,
  forms: formsInitialData,
  leads: leadsInitialData,
  customers: customersInitialData,
  sales: salesInitialData
};

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case INIT_WIDGET:
      return initWidget(state, action.payload);
    case SET_VIEW_VISIBLE:
      return setViewVisible(state, action.payload);
    case TOGGLE_DRAWER:
      return toggleDrawer(state, action.payload);
    case RESET_WIDGETS:
      return resetWidgets(state);
    default:
      return state;
  }
};

export default reducer;
