// Issues services

import {
  Box,
  Module,
  Issue,
  ISSUE_TYPES,
  IssuePages
} from "types/issues.types";
import {
  genModules,
  genBoxes
} from "util/testUtils/builders/issues/module.builder";
import { genIssues } from "util/testUtils/builders/issues/issues.builder";
import { genTickets } from "util/testUtils/builders/issues/tickets.builder";
import axiosDefault from "util/axios";
import { getAuthorization } from "redux/auth/auth.actions";
import { FetchBoxesService } from "./issues.service.types";

export const fetchModules = (): Promise<Module[]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(genModules(6));
    }, 1000);
  });
};

export const fetchBoxes = async (type: string): Promise<Box[]> => {
  try {
    console.log(type);

    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get<FetchBoxesService[]>(
      "/api/tickets/filter",
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );

    return data.map(box => {
      const { id, count, description } = box;
      const issuePages: IssuePages = {};
      for (let i = 0; i < count; i++) {
        issuePages[i] = undefined;
      }
      return {
        ...box,
        id: id.toString(),
        description: !description ? undefined : description,
        issuePages
      };
    });
  } catch (error) {
    throw new Error("Failed to fetch the boxes");
  }
};

export const fetchIssues = async (
  moduleId: string,
  boxId: string,
  page: number,
  size: number,
  //DELETE ME LATER
  type: ISSUE_TYPES,
  email: string,
  uid: string,
  orderBy: string
): Promise<Issue[]> => {
  try {
    const authorizationToken = await getAuthorization();

    const pagination = {
      page: page + 1,
      size
    };
    const sort = { predicate: "id", type: "ASC" };
    const filter = {
      externalId: moduleId,
      assigneeMail: email
    };
    const { data } = await axiosDefault.get(
      `/api/tickets/ticketsByAssignee/${uid}`,
      {
        params: {
          filterId: boxId,
          pagination,
          sort,
          filter
        },
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    console.log("data", data);
  } catch (error) {
    console.log(error);
  }
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      switch (type) {
        case ISSUE_TYPES.ISSUE:
          return resolve(genIssues(size));
        case ISSUE_TYPES.TICKET:
          return resolve(genTickets(size));
        case ISSUE_TYPES.BOOKING:
          return resolve(genTickets(size));
        default:
          throw new Error(`Unsupported generator of type ${type}`);
      }
    }, 1000);
  });
};

export const fetchTicketCategories = async (): Promise<string[]> => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = "/api/tickets/category";
    const { data } = await axiosDefault.get(`${msRoute}`, {
      headers: {
        Authorization: authorizationToken
      }
    });
    return data;
  } catch (e) {
    throw new Error("Failed to fetch the categories");
  }
};

export const fetchBoxesMocked = (): Promise<Box[]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const boxes = genBoxes();
      const initedBoxes = boxes.map(box => {
        const { count } = box;
        const issuePages: IssuePages = {};
        for (let i = 0; i < count; i++) {
          issuePages[i] = undefined;
        }
        return { ...box, issuePages };
      });
      resolve(initedBoxes);
    }, 1000);
  });
};
