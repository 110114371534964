// Customers widget helper functions and data
import { widgetInitialData } from "../widgets.helpers";
import { CustomersDrawers } from "./widgets.customers.types";

const drawers: CustomersDrawers = {
  previews: {
    visible: false
  },
  new: {
    visible: false
  },
  newExternal: {
    visible: false
  },
  edit: {
    visible: false
  },
  info: {
    visible: false
  },
  newAddress: {
    visible: false
  },
  newExternalAddress: {
    visible: false
  },
  externalAddressList: {
    visible: false
  },
  editAddress: {
    visible: false
  },
  newBilling: {
    visible: false
  },
  newExternalBilling: {
    visible: false
  },
  externalBillingList: {
    visible: false
  },
  editBilling: {
    visible: false
  }
};

export const customersInitialData = { ...widgetInitialData, drawers };
