// Account builder functions
import { Account } from "types/sales/Account.types";
import { genName, genNumericId } from "../common.builder";

export const buildAccount = (overrides: Partial<Account> = {}): Account => {
  return {
    accountId: genNumericId(),
    name: genName(),
    ...overrides
  };
};
