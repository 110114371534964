// shoppingCart builder functions
import { ShoppingCart } from "types/sales/shoppingCart.types";

export const buildInitialShoppingCart = (
  overrides: Partial<ShoppingCart> = {}
): ShoppingCart => {
  return {
    id: "",
    latitude: -1,
    longitude: -1,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    channelId: -1,
    stores: {},
    billTotal: {},
    ...overrides
  };
};
