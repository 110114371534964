// Issues types and interfaces
import { Ticket } from "./ticket.types";

export interface Module {
  id: string;
  // Title of the module
  title: string;
  // Attribute used to order the modules
  weight: number;
  // Issue boxes in a module
  boxes?: Box[];
  // Type of Issue
  type: ISSUE_TYPES;
}

export interface Box {
  id: string;
  // Box title
  name: string;
  // total number of issues
  count: number;
  // pages of issues currently available
  issuePages: IssuePages;
  // box description
  description?: string;
}

export interface IssuePages {
  [key: number]: Issue[] | undefined;
}

export enum ViewTypes {
  ROWS = "ROWS",
  LIST = "LIST",
  KANBAN = "KANBAN",
  CALENDAR = "CALENDAR"
}

export interface Issue {
  id: string;
  issueId: string;
  read: boolean;
  responsible?: Responsible;
  author: Agent;
  subject: string;
  flowStatus: ISSUE_FLOW_STATUS;
  flowStatusUpdatedAt: string;
  entries: Entry[];
  createdAt: string;
  updatedAt: string;
}

export interface BaseIssue extends Omit<Issue, "responsible" | "issueId"> {
  read: false;
  flowStatus: ISSUE_FLOW_STATUS.OPENED;
  entries: [];
}

export interface Agent {
  id: string;
  name: string;
  lastName: string;
  avatar?: string;
}

export interface Responsible {
  id: string;
  area: string;
  agent: Agent;
}

export enum ISSUE_FLOW_STATUS {
  OPENED = "Abierto",
  RESOLVED = "Resuelto",
  CLOSED = "Cerrado"
}

export interface Entry {
  id: string;
  read: boolean;
  createdAt: string;
  area: string;
  note: Note;
  attachments: Attachment[];
  activities: Activity[];
  author: Agent;
}

export interface Attachment {
  id: string;
  name: string;
  uri: string;
  extension: Extensions;
}

export enum ImageExtensions {
  SVG = "svg",
  PNG = "png",
  JPEG = "jpeg",
  JPG = "jpg"
}

export enum FileExtensions {
  PDF = "pdf",
  EXCEL = "xls",
  WORD = "docsx"
}

export type Extensions = typeof ImageExtensions & typeof FileExtensions;

export interface Note {
  id: string;
  private: boolean;
  text: string;
}

export interface Activity {
  id: string;
  title: string;
  color: string;
  [key: string]: string;
}

export enum ISSUE_TYPES {
  ISSUE = "issues",
  TICKET = "tickets",
  INCIDENT = "incidentes",
  ACTIVITIES = "actividades",
  LEADS = "leads",
  DATABASES = "bases",
  BOOKING = "agendamientos",
  ORDERS = "ordenes"
}

export type IssueType = Issue | Ticket;

export interface IssuesBaseView {
  type: ViewTypes;
  issues: (IssueType | undefined)[] | undefined;
}

export interface IssuesBaseViewContainer extends IssuesBaseView {}
