// Issue builder functions
import faker from "faker";

import {
  Issue,
  ISSUE_FLOW_STATUS,
  Note,
  Activity,
  Attachment,
  FileExtensions,
  ImageExtensions,
  Entry,
  Responsible,
  Agent,
  ISSUE_TYPES,
  BaseIssue
} from "types/issues.types";
import {
  genId,
  genNumber,
  fillNumber,
  chooseRandomEnum,
  genParagraph,
  genBiasBoolean,
  genTitle,
  chooseRandom,
  genFirstName,
  genLastName,
  genAvatar,
  getBoolean
} from "../common.builder";
import { buildTicket } from "./tickets.builder";

const genIssueId = (max: number) => {
  const num = String(genNumber(max));
  return fillNumber(num, max);
};

const genIssueFlowStatus = (): ISSUE_FLOW_STATUS => {
  return chooseRandomEnum(ISSUE_FLOW_STATUS);
};

const genStatusUpdateDate = () => {
  return faker.date.recent(3).toISOString();
};

const genCommentCreationDate = () => {
  return faker.date.recent(2).toISOString();
};

export const genCategories = (quantity?: number): string[] => {
  const num = quantity ?? Math.floor(Math.random() * 9 + 1);
  const categories = [];
  for (let i = 0; i < num; i++) {
    const category = faker.company.companyName();
    categories.push(category);
  }
  return categories;
};

export const buildNote = (overrides: Partial<Note> = {}): Note => {
  return {
    id: genId(),
    text: genParagraph(),
    private: false,
    ...overrides
  };
};

const buildFormField = () => {
  const filled = genBiasBoolean(0.9);
  const words = genNumber({ min: 1, max: 3 });
  const lines = genNumber({ min: 1, max: 2 });
  const key = faker.random.words(words);
  const value = genParagraph(lines);
  return {
    [key]: filled ? value : ""
  };
};

const genFormFields = (quantity?: number) => {
  const num = quantity ?? Math.floor(Math.random() * 5 + 1);
  let formFields = {};
  for (let i = 0; i < num; i++) {
    formFields = { ...formFields, ...buildFormField() };
  }
  return formFields;
};

export const buildActivity = (overrides: Partial<Activity> = {}): Activity => {
  const colors = ["#F65058", "#F2C94C", "#5F53C9"];
  return {
    id: genId(),
    title: genTitle(),
    color: chooseRandom(colors),
    ...overrides,
    ...genFormFields()
  };
};

export const genActivities = (quantity?: number): Activity[] => {
  const defaultActivity = buildActivity({
    title: "Issue creado",
    color: "#F2C94C"
  });
  const withoutActivities = genBiasBoolean(0.4);
  if (withoutActivities) {
    return [defaultActivity];
  }
  const num = quantity ?? Math.floor(Math.random() * 5);
  const activities = [defaultActivity];
  for (let i = 0; i < num; i++) {
    activities.push(buildActivity());
  }
  return activities;
};

export const buildAttachement = (): Attachment => {
  const file = genBiasBoolean(0.2);
  const ext = chooseRandomEnum(file ? FileExtensions : ImageExtensions);
  const randomWords = genNumber({ min: 1, max: 4 });
  return {
    id: genId(),
    extension: ext,
    name: `${faker.lorem.slug(randomWords)}.${ext}`,
    uri: faker.image.avatar()
  };
};

export const genAttachments = (quantity?: number): Attachment[] => {
  const withoutAttachments = genBiasBoolean(0.6);
  if (withoutAttachments) {
    return [];
  }
  const num = quantity ?? Math.floor(Math.random() * 5);
  const attachments = [];
  for (let i = 0; i < num; i++) {
    attachments.push(buildAttachement());
  }
  return attachments;
};

export const buildEntry = (overrides: Partial<Entry> = {}): Entry => {
  return {
    id: genId(),
    read: genBiasBoolean(0.3),
    author: buildAgent(),
    area: faker.commerce.department(),
    createdAt: genCommentCreationDate(),
    note: buildNote(),
    activities: genActivities(),
    attachments: genAttachments(),
    ...overrides
  };
};

export const genEntries = (quantity?: number): Entry[] => {
  const num = quantity ?? Math.floor(Math.random() * 9) + 1;
  const entries = [];
  for (let i = 0; i < num; i++) {
    entries.push(buildEntry());
  }
  return entries;
};

const genResponsible = (): Responsible => {
  return {
    id: genId(),
    area: faker.commerce.department(),
    agent: buildAgent()
  };
};

export const buildAgent = (overrides: Partial<Agent> = {}): Agent => {
  const withAvatar = genBiasBoolean(0.7);
  return {
    id: genId(),
    name: genFirstName(),
    lastName: genLastName(),
    avatar: withAvatar ? genAvatar() : undefined,
    ...overrides
  };
};

export const genIssueSubject = () => {
  const company = faker.commerce.product();
  const adjective = faker.company.catchPhraseAdjective();
  return `${company} ${adjective}`;
};

export const buildIssue = (overrides: Partial<Issue> = {}): Issue => {
  return {
    id: genId(),
    issueId: genIssueId(300),
    read: getBoolean(),
    author: buildAgent(),
    responsible: genResponsible(),
    subject: genIssueSubject(),
    flowStatus: genIssueFlowStatus(),
    flowStatusUpdatedAt: genStatusUpdateDate(),
    entries: genEntries(),
    createdAt: faker.date.recent(300).toISOString(),
    updatedAt: faker.date.recent(100).toISOString(),
    ...overrides
  };
};

const chooseBuilder = (type: ISSUE_TYPES, overrides: Partial<Issue>) => {
  switch (type) {
    case ISSUE_TYPES.ISSUE:
      return buildIssue(overrides);
    case ISSUE_TYPES.TICKET:
      return buildTicket(overrides);
    default:
      throw new Error(`Type ${type} is not a supported issue type`);
  }
};

export const genIssues = <T extends Issue>(
  quantity?: number,
  type: ISSUE_TYPES = ISSUE_TYPES.ISSUE,
  overrides: Partial<T> = {}
): T[] => {
  const num = quantity ?? Math.floor(Math.random() * 29) + 1;
  const Issues = [];
  const maxIssueId = 300;
  let currentPointer = 0;
  for (let i = 0; i < num; i++) {
    const loopMax = maxIssueId - num + i;
    const loopMin =
      currentPointer + 1 <= loopMax ? currentPointer + 1 : loopMax;
    currentPointer = genNumber({ min: loopMin, max: loopMax });
    const newIssueId = fillNumber(String(currentPointer), maxIssueId);
    const Issue = chooseBuilder(type, { issueId: newIssueId, ...overrides });
    Issues.push(Issue);
  }
  return Issues as T[];
};

export const buildBaseIssue = (
  overrides: Partial<BaseIssue> = {}
): BaseIssue => {
  return {
    ...buildIssue(),
    read: false,
    flowStatus: ISSUE_FLOW_STATUS.OPENED,
    entries: [],
    ...overrides
  };
};
