import { AppState, ThunkDispatch } from "redux/store.types";
import {
  fetchCustomersMocked,
  fetchExternalCustomers
} from "services/customer/customer.service";
import { addNewCustomer } from "services/customer/customer.service";
import { updateCustomer } from "services/customer/customer.service";
import { fetchCustomers } from "services/customer/customer.service";
import { addNewAddress } from "services/customer/customer.service";
import { updateAddress } from "services/customer/customer.service";
import { fetchAddress } from "services/customer/customer.service";
import { fetchBillingInformation } from "services/customer/customer.service";
import { addNewBillingInformation } from "services/customer/customer.service";
import { updateBillingInformation } from "services/customer/customer.service";
import { MapData } from "redux/chat/chat.types";
import { Address } from "types/address.types";
import { BillingInformationReq } from "types/billing.types";
import { NewCustomer, Customer, ExternalCustomer } from "types/customer.types";

export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_SELECTED_CUSTOMER_ID = "SET_SELECTED_CUSTOMER_ID";
export const SET_EXTERNAL_CUSTOMERS = "SET_EXTERNAL_CUSTOMERS";
export const SET_SELECTED_EXTERNAL_CUSTOMER_ID =
  "SET_SELECTED_EXTERNAL_CUSTOMER_ID";
export const SET_NEW_CUSTOMER = "SET_NEW_CUSTOMER";
export const SET_EDIT_CUSTOMER = "SET_EDIT_CUSTOMER";
export const SET_UPDATE_CUSTOMER = "SET_UPDATE_CUSTOMER";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_SELECTED_ADDRESS_ID = "SET_SELECTED_ADDRESS_ID";
export const SET_NEW_ADDRESS = "SET_NEW_ADDRESS";
export const SET_UPDATE_ADDRESS = "SET_UPDATE_ADDRESS";
export const SET_SELECTED_BILLING_INFORMATION_ID =
  "SET_SELECTED_BILLING_INFORMATION_ID";
export const SET_NEW_BILLING_INFORMATION = "SET_NEW_BILLING_INFORMATION";
export const SET_UPDATE_BILLING_INFORMATION = "SET_UPDATE_BILLING_INFORMATION";
export const SET_BILLING_INFORMATION = "SET_BILLING_INFORMATION";
export const SET_LOCATION = "SET_LOCATION";
export const SET_MAP_VISIBLE = "SET_MAP_VISIBLE";
export const RESET_CUSTOMER = "RESET_CUSTOMER";
export const RESET_EXTERNAL_CUSTOMER = "RESET_EXTERNAL_CUSTOMER";
export const SET_EXTERNAL_CUSTOMER = "SET_EXTERNAL_CUSTOMER";
export const SET_EXTERNAL_ADDRESSES = "SET_EXTERNAL_ADDRESSES";
export const SET_EXTERNAL_BILLINGINFOS = "SET_EXTERNAL_BILLINGINFOS";
export const SET_EXTERNAL_BILLINGINFO = "SET_EXTERNAL_BILLINGINFO";
export const SET_EXTERNAL_ADDRESS = "SET_EXTERNAL_ADDRESS";

export const setCustomersMocked = (page: number) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const paginatedCustomers = await fetchCustomersMocked(page);
  dispatch({
    type: SET_CUSTOMERS,
    payload: paginatedCustomers
  });
};

export const setCustomerId = (customerId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch(resetCustomer());
  dispatch({ type: SET_SELECTED_CUSTOMER_ID, payload: customerId });
};

export const setExternalCustomer = (customer: ExternalCustomer) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch(resetExternalCustomer());
  dispatch({ type: SET_EXTERNAL_CUSTOMER, payload: customer });
};

export const resetCustomer = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  return dispatch({ type: RESET_CUSTOMER });
};

export const resetExternalCustomer = () => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  return dispatch({ type: RESET_EXTERNAL_CUSTOMER });
};

export const setSelectedAddressId = (addressId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({
    type: SET_SELECTED_ADDRESS_ID,
    payload: addressId
  });
};

export const setNewCustomer = (customer: NewCustomer) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const newValues = await addNewCustomer(customer);
  if (!newValues) {
    return false;
  }
  const newCustomer = {
    ...newValues,
    documents: {
      "doc-1": {
        document: newValues.document,
        documentType: newValues.documentType
      }
    }
  };
  dispatch({ type: SET_NEW_CUSTOMER, payload: newCustomer });
  dispatch(setCustomerId(newCustomer.uid));
  return true;
};

export const setUpdateCustomer = (customer: NewCustomer) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const updatedValues = await updateCustomer(customer);
  if (!updatedValues) {
    return false;
  }
  const { document, documentType } = updatedValues;
  const updatedCustomer: Customer = {
    ...updatedValues,
    documents: {
      "doc-1": {
        document,
        documentType
      }
    },
    reasonDisabled: ""
  };
  if (!document || !documentType) {
    delete updatedCustomer.documents;
  }

  dispatch({ type: SET_UPDATE_CUSTOMER, payload: updatedCustomer });
  return true;
};

export const setCustomers = (
  page: number,
  query: string,
  findBy?: string
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const paginatedCustomers = await fetchCustomers(query, page, findBy);
  if (!paginatedCustomers) {
    return false;
  }
  dispatch({
    type: SET_CUSTOMERS,
    payload: paginatedCustomers
  });
  if (paginatedCustomers?.items.length === 0) {
    return false;
  }
  return true;
};

export const setExternalCustomers = (
  page: number,
  query: string | undefined
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  if (!query) {
    return false;
  }
  const paginatedCustomers = await fetchExternalCustomers(query, page);
  if (!paginatedCustomers) {
    return false;
  }
  dispatch({
    type: SET_EXTERNAL_CUSTOMERS,
    payload: paginatedCustomers
  });
  return true;
};

export const setNewAddress = (address: Address) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const newAddress = await addNewAddress(address);
  if (!newAddress) {
    return false;
  }
  dispatch({ type: SET_NEW_ADDRESS, payload: newAddress });
  dispatch(setSelectedAddressId(newAddress.id));
  return true;
};

export const setUpdateAddress = (uid: string, address: Address) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const updatedAddress = await updateAddress(uid, address);
  if (!updatedAddress) {
    return false;
  }
  dispatch({ type: SET_UPDATE_ADDRESS, payload: updatedAddress });
  return true;
};

export const setAddressByCustomer = (page: number, uid: string) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  const addressByCustomer = await fetchAddress(page, uid);
  if (!addressByCustomer) {
    return false;
  }
  dispatch({ type: SET_ADDRESS, payload: addressByCustomer });
  return true;
};

export const setBillingInformationByCustomer = (
  page: number,
  uid: string
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const billing = await fetchBillingInformation(page, uid);
  if (!billing) {
    return false;
  }
  dispatch({ type: SET_BILLING_INFORMATION, payload: billing });
  return true;
};

export const setNewBillingInformation = (
  billing: BillingInformationReq
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const newBilling = await addNewBillingInformation(billing);
  if (!newBilling) {
    return false;
  }
  dispatch({ type: SET_NEW_BILLING_INFORMATION, payload: newBilling });
  dispatch(setSelectedBillingInformationId(newBilling.id));
  return true;
};

export const setUpdateBillingInformation = (
  billingId: string,
  billing: BillingInformationReq
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const updatedBilling = await updateBillingInformation(billingId, billing);
  if (!updatedBilling) {
    return false;
  }
  dispatch({ type: SET_UPDATE_BILLING_INFORMATION, payload: updatedBilling });
  return true;
};

export const setSelectedBillingInformationId = (
  billingId: string | undefined
) => (dispatch: ThunkDispatch, getState: () => AppState) => {
  dispatch({
    type: SET_SELECTED_BILLING_INFORMATION_ID,
    payload: billingId
  });
};

export const setMapVisible = (opened?: boolean) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_MAP_VISIBLE, payload: opened });
};

export const setLocation = (map: Partial<MapData>) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: SET_LOCATION, payload: map });
};
