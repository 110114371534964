// Sales reducer helper functions and data
import { SalesState } from "./sales.types";
import { Store } from "types/sales/stores.types";
import { Category } from "types/sales/category.types";
import { FindProductByIdConfig } from "./sales.reducer.helpers.types";
import { FindProductByCategoryConfig } from "./sales.reducer.helpers.types";
import { Product } from "types/sales/product.types";
import { getPaginationItems, getInitialPaginationData } from "util/pagination";
import { Pagination } from "types/common.types";
import { PaymentMethod } from "types/orders.types";
import { buildInitialShoppingCart } from "util/testUtils/builders/sales/shoppingCart.builder";
import { Channel } from "types/sales/channel.types";
import { Vendor } from "types/sales/vendor.types";

export const findStoreById = (
  state: SalesState,
  storeId: number
): Store | undefined => {
  const stores = getPaginationItems(state.stores.items);
  const store = stores.find(store => store.storeId === storeId);
  if (!store) {
    return undefined;
  }
  return { ...store };
};

export const findCategoryById = (
  state: SalesState,
  categoryId: number
): Category | undefined => {
  const category = Object.values(state.categories).find(
    category => category.id === categoryId
  );
  if (!category) {
    return undefined;
  }
  return { ...category };
};

export const findPaymentMethodById = (
  state: SalesState,
  paymentMethodId: number
): PaymentMethod | undefined | {} => {
  const { paymentMethods } = state;
  const paymentMethod = paymentMethods
    ? paymentMethods[paymentMethodId]
    : undefined;
  return { ...paymentMethod };
};

export const findProductsByCategory = (
  state: SalesState,
  config: FindProductByCategoryConfig
): Pagination<Product> | undefined => {
  const { channelId, categoryId } = config;
  const channelKey = Object.keys(state.products).find(
    channelKey => channelKey === channelId?.toString()
  );
  if (!channelKey) {
    return undefined;
  }
  const categoryKey = Object.keys(state.products[channelKey]).find(
    categoryKey => categoryKey === categoryId?.toString()
  );
  if (!categoryKey) {
    return undefined;
  }
  const products = state.products[channelKey][categoryKey];
  if (!products) {
    return undefined;
  }
  return { ...products };
};

// TODO: First look in products details, if it doesnt exist search in products
export const findProductById = (
  state: SalesState,
  config: FindProductByIdConfig
): Product | undefined => {
  const { productId, channelId, categoryId } = config;
  const paginatedProducts = findProductsByCategory(state, {
    channelId,
    categoryId
  });
  if (!paginatedProducts) {
    return undefined;
  }
  const products = getPaginationItems(paginatedProducts.items);
  const product = products.find(product => product.productId === productId);
  if (!product) {
    return undefined;
  }
  return { ...product };
};

export const findChannelById = (
  state: SalesState,
  channelId: number | undefined
): Channel | undefined => {
  if (!state.channels || typeof channelId === "undefined") {
    return;
  }
  const channel = Object.values(state.channels).find(
    channel => channel.id === channelId
  );
  if (!channel) {
    return undefined;
  }
  return channel;
};

export const findVendorById = (
  state: SalesState,
  vendorId: number | undefined
): Vendor | undefined => {
  if (!state.vendors || typeof vendorId === "undefined") {
    return;
  }
  const vendor = Object.values(state.vendors).find(
    vendor => vendor.id === vendorId
  );
  if (!vendor) {
    return undefined;
  }
  return vendor;
};

export const stateAfterOrder: Partial<SalesState> = {
  stores: getInitialPaginationData(),
  selectedStoreId: undefined,
  selectedCategoryId: undefined,
  shoppingCart: buildInitialShoppingCart(),
  recommendedProduct: undefined,
  selectedCartItemId: undefined,
  selectedProductId: undefined,
  selectedPaymentMethodId: undefined,
  shippingCost: undefined
};

export const stateInitOrder: Partial<SalesState> = {
  selectedStoreId: undefined,
  selectedCategoryId: undefined,
  shoppingCart: buildInitialShoppingCart(),
  recommendedProduct: undefined,
  selectedCartItemId: undefined,
  selectedProductId: undefined,
  selectedPaymentMethodId: undefined,
  shippingCost: undefined
};

export const salesStateLogout: SalesState = {
  account: undefined,
  channels: undefined,
  selectedChannel: findChannelById,
  vendors: undefined,
  salesProcess: undefined,
  selectedChannelId: undefined,
  selectedVendorId: undefined,
  selectedVendor: findVendorById,
  stores: getInitialPaginationData(),
  selectedStoreId: undefined,
  selectedStore: findStoreById,
  categories: getInitialPaginationData(),
  selectedCategoryId: undefined,
  selectedCategory: findCategoryById,
  products: {},
  selectedProductId: undefined,
  selectedProduct: findProductById,
  productsDetails: {},
  shoppingCart: buildInitialShoppingCart(),
  recommendedProduct: undefined,
  paymentMethods: undefined,
  selectedPaymentMethodId: undefined,
  selectedPaymentMethod: findPaymentMethodById,
  selectedCartItemId: undefined,
  shippingCost: undefined,
  lastOrder: undefined,
  paymentInscriptions: undefined
};
